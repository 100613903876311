import HotTable from '@handsontable//react';
import Button from '@/components/UI/Button';
import SpreadSheet from '@/components/UI/SpreadSheet';
import type { CustomGridSettings } from '@/components/UI/SpreadSheet/SpreadSheet.model';
import { spreadSheetValidationTooltip } from '@/components/UI/SpreadSheet/SpreadSheet.utils';
import { renderMetadataValue, successToast } from '@/helpers';
import { _isNotEmpty, _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { MetadataField, MetadataPostItem } from '@/model/Metadata.model';
import type { BulkSamplesFormData, SampleMeasurement, SampleMeasurementKey } from '@/model/Sample.model';
import type { Study } from '@/model/Study.model';
import { useRef, useState } from 'react';
import { useRequest } from '@/support/Hooks/request';
import { formatColumnSettingsDataValue } from '@/support/hot';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { generateColumns, generateSampleData } from './BulkCreateSamples.utils';
import { BrowserRouter, Link } from 'react-router-dom';
import { web } from '@/support/route.ts';

interface ViewBulkSampleDetailsProps {
  studyId: Study['id'];
  subjects: Array<Animal>;
  closeModal: () => void;
  handleCallback: (finished: boolean, viewSamples: boolean) => void;
  sampleDetails: BulkSamplesFormData;
  metadata?: Array<MetadataField>;
}

const ViewBulkSampleDetails: React.FC<ViewBulkSampleDetailsProps> = ({
  studyId,
  subjects,
  sampleDetails,
  metadata = [],
  closeModal,
  handleCallback,
}) => {
  const [{ colHeaders, columns }] = useState(generateColumns(sampleDetails, metadata));
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  const [data] = useState(generateSampleData(subjects, metadata, sampleDetails));
  const ref = useRef<HotTable>();

  const settings = {
    className: 'htMiddle',
    colHeaders: colHeaders,
    columnSorting: false,
    columns: columns,
    contextMenu: false,
    height: 500,
    hiddenColumns: { copyPasteEnabled: true },
    manualColumnResize: true,
    rowHeaders: true,
    rowHeights: 50,
    fixedColumnsLeft: 3,
  };

  const {
    sendRequest: createSamples,
    requestSending: creatingSamples,
    requestError,
  } = useRequest({
    route: 'samples.bulk.create',
    method: 'post',
    params: { studyId },
  });

  const formatMetadata = (meta: Record<string, string>) =>
    Object.entries(meta).reduce<Array<MetadataPostItem>>((acc, [key, value]) => {
      const glossaryDetails = metadata.find(({ title }) => formatColumnSettingsDataValue(title) === key);
      let formattedValue: string | string[];
      if (_notNil(glossaryDetails) && _isNotEmpty(value)) {
        if (glossaryDetails.field_type === 'date') {
          formattedValue = value;
        } else if (glossaryDetails.field_type === 'multi_select') {
          formattedValue = (value as string).split(',');
        } else {
          formattedValue = renderMetadataValue({
            value,
            field_type: glossaryDetails.field_type,
          }).toString();
        }

        acc.push({
          glossary_id: glossaryDetails.id,
          value: formattedValue,
        });
      }
      return acc;
    }, []);

  const mapFormDataForPost = () =>
    data.map(({ subject_id, subjectName, cageName, date, comments, meta, details }) => {
      const formattedMeta = formatMetadata(meta ?? {});
      return {
        subject_id,
        date,
        comments,
        glossary_id: sampleDetails.sample_type.id,
        details: Object.entries(details).reduce<Array<SampleMeasurement>>((acc, [key, value]) => {
          if (_notNil(value)) {
            acc.push({
              key: key as SampleMeasurementKey,
              value,
              unit: sampleDetails?.details?.[key as SampleMeasurementKey]?.unit ?? '',
            });
          }
          return acc;
        }, []),
        ...(_isNotEmpty(formattedMeta) ? { metadata: formattedMeta } : {}),
      };
    });

  const handleSave = async (finished: boolean) => {
    await createSamples({ samples: mapFormDataForPost() });
    if (!requestError) {
      successToast(
        <div>
          Samples collected from {data.length} animal{Number(data.length) !== 1 ? 's. ' : '. '}
          <BrowserRouter>
            <Link
              to={web('studies.samples', { id: studyId })}
              className="link"
              onClick={() => handleCallback(finished, true)}
            >
              View Samples
            </Link>
          </BrowserRouter>
        </div>
      );
    }
    handleCallback(finished, false);
  };

  return (
    <ModalContainer size="wide" className="bg-white">
      <div className="flex flex-column pa3">
        <ModalHeader title="Samples details" closeModal={closeModal} className="w-100" />
        <p className="lh-copy f6">
          Review and amend samples and their associated data.
          <a
            href="https://help.benchling.com/hc/en-us/articles/20134411794701-Collect-samples-in-bulk"
            target="_blank"
            rel="noopener noreferrer"
            className="link blue ml1"
          >
            Read more
          </a>
        </p>
      </div>
      <div data-testid="sample-details-container" className="ow-spreadsheet-styles mr0">
        <SpreadSheet
          data={data}
          // HoT is missing types
          settings={settings as CustomGridSettings}
          metadata={metadata}
          innerRef={ref}
          maxRows={subjects.length}
          addCol={false}
          setInvalid={setIsInvalid}
        />
      </div>
      <div className="flex g3 items-center w-100 pa3">
        <ModalActions
          submitBtnText="Save and finish"
          submitButtonProps={{
            className: 'ml3',
            disabled: creatingSamples || isInvalid,
            tooltip: isInvalid ? spreadSheetValidationTooltip : undefined,
          }}
          onSubmit={() => handleSave(true)}
        />
        <Button
          disabled={creatingSamples || isInvalid}
          tooltip={isInvalid ? spreadSheetValidationTooltip : undefined}
          soft
          onClick={() => handleSave(false)}
          testKey="bulk-sample-save-button"
        >
          Save and collect another
        </Button>
      </div>
    </ModalContainer>
  );
};

export default ViewBulkSampleDetails;
