import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import {
  addAdditionalProperties,
  classificationTypes,
  getQueryParams,
  TreatmentFromForm,
} from '@/components/Studies/Treatments/Treatment.utils';
import { TreatmentsTable } from '@/components/Studies/Treatments/TreatmentsTable';
import { errorToast, successToast } from '@/helpers';
import { _notNil } from '@/littledash';
import type { MetadataField } from '@/model/Metadata.model';
import type { Study, StudyApiId } from '@/model/Study.model';
import type { ClassificationType, Treatment, TreatmentApiId, TreatmentCreateV1 } from '@/model/Treatment.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { useFetchCollection } from '@/support/Hooks/fetch';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import { modalAction } from '@/utils/modal';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import InVivoError from '@/model/InVivoError.ts';

import cn from 'classnames';
import Loading from '@/components/Loading';
import useMountedState from '@/support/Hooks/fetch/useMountedState.ts';

interface TreatmentsProps {
  study?: Study;
  type: ClassificationType;
}

const tabMetaType: Record<ClassificationType, string> = {
  treatment: 'treatment_meta',
  disease_induction: 'disease_meta',
} as const as Record<ClassificationType, string>;

export const Treatments: FC<TreatmentsProps> = ({ study, type }) => {
  const dispatch = useDispatch();
  const { openModal, closeModal } = modalAction(dispatch);
  const [addingTreatment, setAddingTreatment] = useState<boolean>(false);
  const [updatingTreatment, setUpdatingTreatment] = useState<boolean>(false);
  const [apiError] = useState<Error | undefined>();
  const isMounted = useMountedState();

  const { collection: groups, collectionLoading: groupsLoading } = useFetchCollection<TreatmentGroup>({
    collectionType: 'studyGroups',
    params: { id: study?.id },
    queryParams: { perPage: -1 },
  });

  const { collection: metadata, collectionLoading: metaLoading } = useFetchCollection<MetadataField>({
    collectionType: 'metadataGlossaries',
    queryParams: {
      filter_type: tabMetaType[type],
    },
  });

  const { invoke: createTreatmentRequest } = useApiHook({
    endpoint: 'POST /api/v1/studies/{studyApiId}/treatments',
    invokeOnInit: false,
    options: { onError: { toast: false, capture: false, throw: true } },
  });
  const { invoke: updateTreatmentRequest } = useApiHook({
    endpoint: 'PATCH /api/v1/studies/{studyApiId}/treatments/{treatmentApiId}',
    invokeOnInit: false,
    options: { onError: { toast: false, capture: false, throw: true } },
  });

  if (groupsLoading || metaLoading) {
    return (
      <div className="mv4 ui-card pa4">
        <Loading />
      </div>
    );
  }

  const saveTreatment = async (newTreatment: TreatmentCreateV1) => {
    if (isMounted()) {
      setAddingTreatment(true);
    }
    try {
      await createTreatmentRequest({ path: { studyApiId: (study?.api_id ?? '') as StudyApiId }, body: newTreatment });
      successToast(`Successfully created ${classificationTypes[type].key}`);
    } catch (err) {
      const ivError = new InVivoError(`Could not create new ${classificationTypes[type].key}`, {
        slug: 'treatments',
        cause: err,
      });
      ExceptionHandler.captureException(ivError);
      errorToast(`Could not create ${classificationTypes[type].key}`);
      return Promise.reject(ivError).finally(() => {
        if (isMounted()) {
          dispatch({ type: 'set-submitting', value: false });
        }
      });
    }
    if (isMounted()) {
      setAddingTreatment(false);
      closeModal();
    }
  };

  const updateTreatment = async (treatmentData: TreatmentCreateV1, treatmentApiId: TreatmentApiId) => {
    try {
      if (isMounted()) {
        setUpdatingTreatment(true);
      }
      await updateTreatmentRequest({
        body: treatmentData,
        path: { studyApiId: (study?.api_id ?? '') as StudyApiId, treatmentApiId: treatmentApiId },
      });
      successToast(`Successfully updated ${classificationTypes[type].key}`);
      if (isMounted()) {
        closeModal();
      }
    } catch (err) {
      const ivError = new InVivoError('Could not update treatment', { slug: 'treatments', cause: err });
      ExceptionHandler.captureException(ivError);
      errorToast('Could not update treatment');
      return Promise.reject(ivError).finally(() => {
        if (isMounted()) {
          dispatch({ type: 'set-submitting', value: false });
        }
      });
    }
    if (isMounted()) {
      setUpdatingTreatment(false);
    }
  };

  const onSubmit = async (formItems: TreatmentFromForm, edit: boolean, treatmentApiId: TreatmentApiId) => {
    const updatedTreatment = addAdditionalProperties(formItems, metadata);
    const treatmentCreateObject = updatedTreatment as TreatmentCreateV1;

    type = formItems.treatment_classification ?? 'treatment';

    if (edit) {
      updatedTreatment.treatment_classification = type;
      await updateTreatment(updatedTreatment, treatmentApiId);
    } else {
      treatmentCreateObject.treatment_classification = type;
      await saveTreatment(treatmentCreateObject);
    }
  };

  const handleUpdateTreatments = (_: React.MouseEvent<Element, MouseEvent>, treatment?: Treatment): Promise<boolean> =>
    new Promise<boolean>((resolve) => {
      openModal('ADD_TREATMENTS_FORM', {
        treatment: _notNil(treatment) ? treatment : null,
        metadata,
        onSubmit: (formItems: TreatmentFromForm, edit: boolean, treatmentApiId: TreatmentApiId) =>
          onSubmit(formItems, edit, treatmentApiId)
            .then(() => resolve(true))
            .catch(() => resolve(false)),
        onCancel: () => {
          closeModal();
          resolve(false);
        },
        classificationType: type,
      });
    });

  return (
    <>
      {apiError ? (
        <ApiErrorBanner className="mb4" errorType={ApiErrorType.SUBMIT} error={apiError} />
      ) : (
        <div className={cn('mv4', { ui__disabled: addingTreatment || updatingTreatment })}>
          <TreatmentsTable
            study={study}
            metadata={metadata}
            handleAddOrUpdateTreatmentsClick={handleUpdateTreatments}
            groups={groups}
            type={type}
            queryParams={getQueryParams(type)}
          />
        </div>
      )}
    </>
  );
};
