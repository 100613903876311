export const generateCages = (study_id: number | null = null, num = 0) => {
  const result: any[] = [];
  for (let index = 0; index < num; index++) {
    result[index] = {
      ...result[index],
      study_id,
    };
  }
  return result;
};
