import './Label.scss';

export enum LabelType {
  Info = 'info',
  Mild = 'success',
  Moderate = 'warning',
  Severe = 'critical',
  Dark = 'dark',
}

const types: Record<LabelType, string> = {
  info: 'blue b--blue',
  success: 'green b--green',
  warning: 'yellow b--yellow',
  critical: 'red b--red',
  dark: 'white b--near-black',
};

export interface LabelProps {
  text: string;
  tooltip?: string;
  type: LabelType;
  className?: string;
}

const Label = ({ text, tooltip, type, className }: LabelProps) => (
  <div
    className={`ui__label ui__label__${type} ${types[type]} ${className} ba dib f6 pa1 lh-title br2`}
    data-tooltip-id="global-tooltip-id"
    data-tooltip-content={tooltip}
    data-testid="label-container"
  >
    {text}
  </div>
);

export default Label;
