import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { web as webRoute } from '@/support/route.ts';
import SubHeader from '@/components/UI/SubHeader';
import { Study } from '@/model/Study.model.ts';
import Header from '@/components/UI/Header';
import { AnimalApiId } from '@/model/Animal.model.ts';
import { useApiHook } from '@/support/Hooks/api/useApiHook.ts';
import { _isNil } from '@/littledash.ts';
import { ActiveVariablesResponseV1 } from '@/model/Preset.model.ts';

interface PresetMappingActiveVariablesProps {
  fromStudy: Study;
  toStudy: Study;
  animalApiIds: Array<AnimalApiId>;
}

interface PresetMappingProps extends PresetMappingActiveVariablesProps {
  activeVariables: ActiveVariablesResponseV1;
}

const PresetHeader: FC<{ study: Study; label: string }> = ({ study, label }) => (
  <div className="flex flex-column ph5 pv4 w-40 f6">
    <p>{label}</p>
    <p className="near-black">{study.settings?.title ?? ''}</p>
  </div>
);

export const PresetMappingActiveVariables: FC = () => {
  const location = useLocation<PresetMappingActiveVariablesProps>();
  const { fromStudy, toStudy, animalApiIds } = location.state;
  const { response: activeVariablesResponse, loading: activeVariablesLoading } = useApiHook({
    endpoint: 'POST /api/v1/studies/{studyApiId}/active-variables',
    path: { studyApiId: fromStudy.api_id },
    body: { to_study_api_id: toStudy.api_id, animal_api_ids: animalApiIds },
    invokeOnInit: true,
  });

  if (activeVariablesLoading || _isNil(activeVariablesResponse)) {
    return null;
  }

  const activeVariables = activeVariablesResponse.body as ActiveVariablesResponseV1;

  return (
    <PresetMapping
      fromStudy={fromStudy}
      toStudy={toStudy}
      activeVariables={activeVariables}
      animalApiIds={animalApiIds}
    />
  );
};

const PresetMapping: FC<PresetMappingProps> = ({ fromStudy, toStudy, activeVariables, animalApiIds }) => (
  <>
    <SubHeader linkToText="Animals" link={webRoute('studies.animals', { id: fromStudy.id })} />
    <div className="pv2 ph4">
      <Header mainHeaderText="Move to another study" />
      <p className="f5 mt1">Map columns</p>
      <div className="ui-card mt4 w-100">
        <div className="flex bb b--moon-gray">
          <PresetHeader study={fromStudy} label="Preset 1 - From" />
          <PresetHeader study={toStudy} label="Preset 2 - To" />
        </div>
      </div>
    </div>
  </>
);
