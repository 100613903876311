import { classificationTypes, generateTreatmentColumnsStep } from '@/components/Studies/Treatments/Treatment.utils';
import { StepFormAction } from '@/components/UI/StepForm/StepForm.model';
import APITable from '@/components/UI/Table/Reusable/APITable';
import { MetadataField } from '@/model/Metadata.model.ts';
import { State } from '@/model/State.model';
import { useLegacyApiHook } from '@/support/Hooks/api/useLegacyApiHook.ts';
import { Dispatch, FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CreateBulkDosingState } from '../CreateDosing.utils';
import { RouteService } from '@/support/RouteService.ts';

const onFetchTableData = () => {};

interface SelectTreatmentProps {
  state: CreateBulkDosingState;
  dispatch: Dispatch<StepFormAction<CreateBulkDosingState>>;
}

export const SelectTreatment: FC<SelectTreatmentProps> = ({ dispatch, state }) => {
  const [columns, setColumns] = useState([]);
  const settings = useSelector((state: State) => state.ui.settings);

  const { study, treatmentClassification } = state;
  const { api_id } = study;

  const { response: treatmentMetadataResponse, loading: treatmentMetadataLoading } = useLegacyApiHook({
    method: 'GET',
    apiRoute: 'meta-glossary.show',
    query: { filter_type: classificationTypes[treatmentClassification].metadata },
    options: { onError: { toast: false, slug: 'treatment-metadata-load' } },
  });

  useEffect(() => {
    dispatch({
      type: 'updateArray',
      id: 'selectedTreatments',
      data: [],
    });
  }, []);

  useEffect(() => {
    if (!treatmentMetadataLoading) {
      const treatmentMetadata = (treatmentMetadataResponse?.body as { data: Array<MetadataField> })?.data ?? [];
      const updatedColumns: any = generateTreatmentColumnsStep(
        settings.tables.treatments.columns,
        treatmentMetadata ?? []
      );

      setColumns(updatedColumns);
      dispatch({ type: 'stepReady' });
    }
  }, [treatmentMetadataLoading]);

  const onSelectRow = (rows: Array<any>) => {
    const mapped = (rows ?? []).map(({ original }: any) => original);
    dispatch({
      type: 'updateArray',
      id: 'selectedTreatments',
      data: mapped,
    });
  };

  return (
    <div className="pt4">
      <APITable
        apiInfo={{
          type: 'internalApi',
          route: RouteService.api({
            endpoint: 'GET /api/v1/studies/{studyApiId}/treatments',
            path: { studyApiId: api_id },
          }).url,
        }}
        columns={columns}
        includeParam="metadata,study_groups"
        queryParams={{ classification: treatmentClassification }}
        defaultSortBy={{ id: 'treatment_name', desc: false }}
        searchPlaceholderText="Search"
        reduxTableName={'treatments'}
        showMetricChangeFrom={true}
        onSelectRow={onSelectRow}
        pageSizes={[50, 100, 200, 500, 1000]}
        defaultPageSize={1000}
        onFetchTableData={onFetchTableData}
        testIdPrefix="treatments"
        loadingClassName="pv3"
        maxHeight={450}
      />
    </div>
  );
};
