import Label from '@/components/UI/Label';
import { _isNil } from '@/littledash.ts';
import { LabelType } from '@/components/UI/Label/Label.tsx';

type scoreType = keyof typeof LabelType;

const ObservationScore = ({ type = '', score = '' as scoreType, options = { min: null, max: null } }) => {
  return type === 'numeric' ? (
    <Label
      type={LabelType.Info}
      text={score}
      tooltip={_isNil(options.min) ? undefined : `Scale: ${options.min}-${options.max}`}
    />
  ) : (
    <Label type={LabelType?.[score]} text={score} />
  );
};

export default ObservationScore;
