import Lookup from '@/components/UI/Lookup';
import { _isEmpty, _isNil, _isNotEmpty, _notNil } from '@/littledash';
import type { ClassificationType, TreatmentType } from '@/model/Treatment.model';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { useAbortController } from '@/support/Hooks/fetch/useAbortController';
import useMountedState from '@/support/Hooks/fetch/useMountedState';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { FC, useState } from 'react';
import { classificationTypes } from '@/components/Studies/Treatments/Treatment.utils.tsx';

export interface TreatmentLookupProps {
  value?: TreatmentType;
  onChange: (treatmentType?: TreatmentType) => void;
  onBlur: () => void;
  isDisabled?: boolean;
  classificationType: ClassificationType;
}

export interface OptionType {
  label: string;
  value: TreatmentType | undefined;
}

export const TreatmentLookup: FC<TreatmentLookupProps> = ({
  value,
  onChange,
  onBlur,
  isDisabled = false,
  classificationType,
}) => {
  const isMounted = useMountedState();
  const { newAbortController } = useAbortController();
  const [isLoading, setLoading] = useState(true);
  const [isComplete, setComplete] = useState(false);

  const { invoke: getTreatmentTypes } = useApiHook({
    endpoint: 'GET /api/v1/treatment-types',
    invokeOnInit: false,
  });

  const loadTokenOptions = async ({ inputValue, token }: { inputValue: string; token: string }) => {
    try {
      setLoading(true);
      setComplete(false);

      const query: Record<string, string> = {};

      if (_isNotEmpty(token)) {
        query.nextToken = token;
      }

      const trimmedQuery = (inputValue ?? '').trim();
      if (_isNotEmpty(trimmedQuery)) {
        query.searchBy = trimmedQuery;
      }

      const response = await getTreatmentTypes({ query });

      if (response.type === 'success') {
        const treatments = response.body.treatments ?? [];
        const nextToken = response.body.nextToken;

        const options: Array<OptionType> = treatments.map(
          (treatment) =>
            ({
              label: treatment.name,
              value: treatment,
            }) as OptionType
        );

        if (isComplete) {
          setLoading(false);
        }
        return {
          options,
          nextToken,
        };
      }
    } catch (error) {
      setLoading(false);
    }
    return { options: [], nextToken: null };
  };

  const loadOptions = async ({ inputValue, page }: { inputValue: string; page: string }) => {
    try {
      setLoading(true);
      const url = apiRoute('team.glossary.treatments');
      const queryParams = new URLSearchParams({ page });

      const trimmedQuery = (inputValue ?? '').trim();
      if (_isNotEmpty(trimmedQuery)) {
        queryParams.set('query', trimmedQuery);
      }

      const {
        data: {
          data,
          links: { next },
        },
      } = await Http.get(`${url}?${queryParams.toString()}`, {
        signal: newAbortController().signal,
      });

      if (isMounted()) {
        setLoading(false);
      }

      const hasMore = _notNil(next);
      const options: Array<OptionType> = data.map((treatment: TreatmentType) => ({
        label: treatment.name,
        value: treatment,
      }));
      let nextPage = page;
      if (hasMore) {
        const nextUrl = new URL(next);
        nextPage = nextUrl.searchParams.get('page') ?? '';
      } else {
        setLoading(false);
        setComplete(true);
      }

      return {
        options,
        hasMore,
        additional: { page: nextPage },
      };
    } catch (error) {
      if (isMounted()) {
        setLoading(false);
      }
    }
    return { options: [], hasMore: false, additional: { page } };
  };

  return (
    <Lookup
      name={classificationTypes[classificationType].key}
      defaultValue={_isNil(value) ? null : { label: value.name, value }}
      loadOptions={loadOptions}
      loadTokenOptions={loadTokenOptions}
      tokenPagination={true}
      defaultPagination={true}
      handleSelect={(option: OptionType) => {
        return onChange?.(option?.value);
      }}
      onBlur={onBlur}
      disabled={isDisabled}
      additional={{ page: 1, perPage: 10 }}
      isLoading={isLoading}
      placeholder={`Select ${classificationTypes[classificationType].key}...`}
      noOptionsMessage={({ inputValue = '' }) =>
        !isComplete || isLoading ? 'Loading...' : _isEmpty(inputValue) ? 'No Entries' : `No Entries match ${inputValue}`
      }
    />
  );
};
