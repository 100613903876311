export const buildTags = (tags: string[]): number[] => {
  const processedTags = tags.reduce((uniqueValues: Set<number>, tag: string) => {
    tag.split(',').reduce((_, item) => {
      const trimmedItem = item.trim();
      if (trimmedItem !== '') {
        const numberValue = Number(trimmedItem);
        if (Number.isFinite(numberValue)) {
          uniqueValues.add(numberValue);
        }
      }
      return uniqueValues;
    }, uniqueValues);

    return uniqueValues;
  }, new Set<number>());

  return Array.from(processedTags);
};
