import type React from 'react';
import './Radio.scss';

interface RadioProps {
  value: string;
  index?: number;
  id: string;
  key?: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  className?: string;
  style?: object;
  selected?: string;
  label?: string;
  sublabel?: string;
  disabled?: boolean;
  tooltip?: string;
  dataTestId?: string;
}

const Radio: React.FC<RadioProps> = (props) => (
  <div
    className={`ui__form__radio ${props.className} ${props.disabled ? 'ui__disabled' : ''}`}
    style={props.style}
    data-test-component="Radio"
    data-test-element="container"
    data-test-key={props.value}
    data-testid={props?.dataTestId}
  >
    <input type="radio" {...props} data-test-component="Radio" data-test-element="input" />
    <div className="ui__form__radio__custom"></div>
    {props.label && (
      <label htmlFor={props.id} className="dib relative pl4 lh-copy mb0" data-test-element="label">
        <span style={{ whiteSpace: 'nowrap' }}>{props.label}</span>
        {props.sublabel && <p className="f6 lh-copy ma0 dark-gray">{props.sublabel}</p>}
      </label>
    )}
  </div>
);

export default Radio;
