import { infoToast } from '@/helpers.tsx';
import { _notNil, getJwtPayload, jwtValid, resetSessionCorrelationId } from '@/littledash.ts';
import { RouteService } from '@/support/RouteService.ts';
import { ApplicationEvents } from '@/utils/ApplicationEvents.ts';
import { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const ExpiredJwtDetector: FC = () => {
  const [authenticated, updateAuthenticated] = useState(() => jwtValid());
  const history = useHistory();

  const checkJWT = useCallback(() => {
    const jwtPayload = getJwtPayload();
    if (_notNil(jwtPayload)) {
      if (jwtPayload.exp * 1000 < Date.now()) {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('team_id');
        resetSessionCorrelationId();
        history.push({
          pathname: RouteService.web({ route: 'login' }).route,
          state: { from: { pathname: window.location.pathname } },
        });
        infoToast(
          <div>
            <h3 className="white">Session expired</h3>
            <p>Please log in again</p>
          </div>
        );
      }
    }
  }, [history]);

  useEffect(() => {
    const abortController = new AbortController();
    document.addEventListener(
      'visibilitychange',
      () => {
        if (document.visibilityState === 'visible') {
          checkJWT();
        }
      },
      { signal: abortController.signal, passive: true }
    );
    window.addEventListener('online', () => checkJWT(), { signal: abortController.signal, passive: true });
    ApplicationEvents.onAuthenticated(() => updateAuthenticated(true), abortController.signal);

    return () => abortController.abort();
  }, [checkJWT, updateAuthenticated]);

  useEffect(() => {
    if (authenticated) {
      const exp = getJwtPayload()?.exp;
      if (Number.isFinite(exp)) {
        const timeout = (exp as number) * 1000 - Date.now() + 30000;
        if (timeout > 0) {
          const timeoutId = setTimeout(() => checkJWT(), timeout);
          return () => {
            clearTimeout(timeoutId);
          };
        } else {
          checkJWT();
        }
      }
    }
  }, [authenticated, checkJWT]);

  return null;
};
