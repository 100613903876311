// @ts-nocheck: converted from JS

import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import ObservationScore from '@/components/UI/ObservationScore';
import UserAvatar from '@/components/UI/UserAvatar';
import { ModalContainer, ModalHeader } from '@/utils/modal';
import { newLineText } from '@/helpers.tsx';
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel } from '@/components/UI/Accordion';
import { _isNotEmpty } from '@/littledash.ts';

const ViewObservation = ({ observation, closeModal }) => {
  const { created_at: createdBy } = observation;
  return (
    <ModalContainer size="narrow">
      <ModalHeader
        title={
          <div>
            {observation.title}{' '}
            {observation.value && (
              <div className="ml1 dib">
                <ObservationScore type={observation.type} score={observation.value} />
              </div>
            )}
          </div>
        }
        closeModal={closeModal}
        className="pa3 bb b--moon-gray w-100"
      />
      {_isNotEmpty(observation?.grading_info) && (
        <div className="pa3">
          <Accordion>
            <AccordionItem>
              <AccordionHeader className="flex flex-wrap justify-between items-top">
                <span className="fw5 near-black lh-title f5">Grading Information</span>
              </AccordionHeader>
              <AccordionPanel>
                <div className="ph2">
                  <label htmlFor="text">{newLineText(observation.grading_info)}</label>
                </div>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </div>
      )}
      <div className={`flex ph2 pt3 pb4 ${observation?.text ? 'items-top' : 'items-center'}`}>
        <div className="ph2">
          <UserAvatar user={createdBy} tooltip={createdBy.name ?? createdBy.email} />
        </div>
        <div className="ph2">
          <span className="f6 lh-title dib">
            <DateTimeRenderer value={observation.observed_at} />
          </span>
          {observation.text && (
            <div className="pa3 br3 br--top--left bg-moon-gray mt2 mw6">
              <p className="f6 ma0 line-copy near-black">{observation.text}</p>
            </div>
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

export default ViewObservation;
