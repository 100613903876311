import { Columns } from '@/components/UI/Table/TableComponent.model.ts';
import { Animal, PreviousStudy } from '@/model/Animal.model.ts';
import { DateTimeRenderer, DistanceToNow } from '@/components/UI/DateRenderers/DateRenderers.tsx';
import { FC, useMemo } from 'react';
import Table from '@/components/UI/Table';
import { PickRequired } from '@/model/Common.model.ts';
import Link from '@/components/UI/Link';
import { RouteService } from '@/support/RouteService.ts';
import { _notNil } from '@/littledash.ts';
import UserAvatar from '@/components/UI/UserAvatar';

const generateColumns = (hasStudyCode: boolean): Columns<PreviousStudy> => {
  return [
    {
      Header: 'Study name',
      id: 'study_name',
      accessor: 'study.name',
      Cell: ({ row: { original } }) =>
        _notNil(original.study.access) ? (
          <Link
            className="link blue"
            to={RouteService.web({ route: 'studies.show', path: { id: original.study.id } }).route}
          >
            {original.study.name}
          </Link>
        ) : (
          original.study.name
        ),
    },
    ...(hasStudyCode
      ? ([
          {
            Header: 'Study code',
            id: 'study_code',
            accessor: 'study.code',
            Cell: ({ row: { original } }) => original?.study?.code ?? '-',
          },
        ] as Columns<PreviousStudy>)
      : []),
    {
      Header: 'Removed from study',
      id: 'moved_at',
      accessor: 'moved.at',
      Cell: ({ row: { original } }) => (
        <>
          <DateTimeRenderer value={original.moved.at} />
          <span className="ml2 dark-gray f8">
            (<DistanceToNow value={original.moved.at} />)
          </span>
        </>
      ),
    },
    {
      Header: 'Removed by',
      id: 'moved_by',
      accessor: 'moved.by',
      Cell: ({ row: { original } }) => <UserAvatar user={original.moved.by} tooltip={original.moved.by.name} />,
    },
  ];
};

export const PreviousStudies: FC<{ animal: PickRequired<Animal, 'study' | 'previous_studies'> }> = ({ animal }) => {
  const { columns, previousStudies } = useMemo(() => {
    const previousStudies: Array<PreviousStudy> = animal?.previous_studies ?? [];
    const hasStudyCode = previousStudies.some((study) => _notNil(study.study.code));
    return { previousStudies, columns: generateColumns(hasStudyCode) };
  }, [animal]);
  if (previousStudies.length === 0) {
    return null;
  }

  return (
    <>
      <div className="lh-title f4 mt3 mb3">Previous studies</div>
      <div className="ui-card mb3">
        <div className="overflow-table">
          <Table columns={columns} data={previousStudies} />
        </div>
      </div>
    </>
  );
};
