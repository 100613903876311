import NoDataCard from '@/components/NoDataCard';
import { classificationTypes, handleIconClick } from '@/components/Studies/Treatments/Treatment.utils';
import Icon from '@/components/UI/Icon';
import Table from '@/components/UI/Table';
import type { Column } from '@/components/UI/Table/TableComponent.model';
import { fetchMetadataColumns, formatNumber } from '@/helpers';
import { _isEmpty, _isNil, _isNotEmpty } from '@/littledash';
import type { MetadataField } from '@/model/Metadata.model';
import type { ClassificationType, Treatment, TreatmentField } from '@/model/Treatment.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import { startCase } from 'lodash';
import React, { useMemo } from 'react';

const valueWithUnit = (value?: TreatmentField) => {
  if (_isNil(value) || _isNil(value?.default_value)) {
    return '-';
  }
  return `${formatNumber(value?.default_value, true, 3)} ${value?.unit.name ?? ''}`.trim();
};

interface TreatmentsTableProps {
  treatments: Array<Treatment>;
  metadata?: Array<MetadataField>;
  groups: Array<TreatmentGroup>;
  handleAddOrUpdateTreatmentsClick: (_: unknown, treatment?: Treatment) => void;
  selectedRows: Record<number, boolean>;
  selectedRowsChange: (selectedRows: Record<number, boolean>) => void;
  classificationType: ClassificationType;
}

export const TreatmentsTable: React.FC<TreatmentsTableProps> = ({
  treatments,
  metadata,
  groups,
  handleAddOrUpdateTreatmentsClick,
  selectedRows,
  selectedRowsChange,
  classificationType,
}) => {
  // Disease tab should only show diseases, treatments tab should show both treatments & pre-treatments
  const filteredTreatments = useMemo(() => {
    return treatments.filter((treatment) =>
      classificationType === 'disease_induction'
        ? treatment.treatment_classification === 'disease_induction'
        : treatment.treatment_classification !== 'disease_induction'
    );
  }, [treatments, classificationType]);

  const defaultColumns: Array<Column<Treatment>> = [
    {
      id: 'name',
      Header: 'Name',
      width: 300,
      Cell: ({ row: { original } }) => (
        <div className="flex items-center pointer">
          {original.treatment_type?.benchling_link && (
            <Icon
              className="blue dib mr1"
              onClick={() => {
                handleIconClick(original);
              }}
              style={{ minWidth: '40px', paddingTop: '3px' }}
              tooltip="Click to view in new tab"
              icon="jeffy_p_circle"
              width="24"
              height="24"
              viewBox="0 0 20 20"
            />
          )}
          <a className="link" onClick={() => handleAddOrUpdateTreatmentsClick('', original)}>
            {original?.treatment_type?.name ?? '-'}{' '}
            {(original?._duplicate ?? false) && <span className="ml2 dark-gray">(duplicate)</span>}
          </a>
        </div>
      ),
    },
  ];

  const groupsColumn = {
    id: 'groups',
    Header: 'Groups',
    accessor: (treatment: Treatment) => treatment?.study_groups?.map((group) => group.name)?.join(', ') ?? '-',
  };

  const additionalColumns = useMemo(() => {
    const newAdditionalColumnMap = new Map(
      filteredTreatments.flatMap((treatment) =>
        treatment.fields.map((field) => [
          field.name,
          {
            id: field.name,
            Header: startCase(field.name),
            accessor: (row: Treatment) => valueWithUnit(row.fields.find((x) => x.name === field.name)),
          },
        ])
      )
    );
    return [...newAdditionalColumnMap.keys()]?.map((columnId) => newAdditionalColumnMap.get(columnId));
  }, [filteredTreatments]);

  const metaColumns = _isNotEmpty(metadata) ? fetchMetadataColumns({ metadata, showAllColumns: true }) : [];

  const columns = [...defaultColumns, ...additionalColumns, groupsColumn, ...metaColumns] as Array<Column<Treatment>>;

  const classificationPlural = classificationTypes[classificationType].plural;
  const classificationKey = classificationTypes[classificationType].key;

  return _isEmpty(filteredTreatments) ? (
    <NoDataCard
      title={`This study has no ${classificationPlural}`}
      text={`${_isEmpty(groups) ? 'Please add at least one group' : `A preview of your ${classificationPlural} will appear here`}`}
      onLinkClick={_isNotEmpty(groups) ? handleAddOrUpdateTreatmentsClick : undefined}
      btnTxt={`Add a ${classificationKey}`}
      openModal={true}
      dark
      className="w-100 pa4"
    />
  ) : (
    <Table
      className="w-100 bt"
      columns={columns}
      data={filteredTreatments}
      selectedRows={selectedRows}
      setSelectedRows={selectedRowsChange}
    />
  );
};
