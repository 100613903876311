// @ts-nocheck: converted from JS

import { Observations } from '@/components/Modals/SetupWorkflow/Forms/Observations';
import { Samples } from '@/components/Modals/SetupWorkflow/Forms/Samples';
import { DragHintBanner } from '@/components/UI/Banner/Reusable';
import Button from '@/components/UI/Button';
import { _isEmpty } from '@/littledash';
import { FC, useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { RiArrowDropRightFill } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import CheckDrawer from './CheckDrawer';
import { Dosing, General, Measurements } from './Forms';
import { assembleInitialWorkflow } from './SetupWorkflow.utils';
import { Study } from '@/model/Study.model.ts';
import { WorkflowState } from '@/components/Modals/SetupWorkflow/SetupWorkflow.test.tsx';
import { Dispatch } from 'redux';
import { ShowStateAction } from '@/components/Workflow/Show/Workflow.model.ts';

const SetupWorkflow: FC<{
  study: Study;
  state: WorkflowState;
  dispatch: Dispatch<ShowStateAction>;
  handleCallback: () => number;
}> = ({ study, state, dispatch, handleCallback }) => {
  const { workflow } = state;
  const [workflowState, setWorkflowState] = useState([...workflow]);

  const formMethods = useForm({
    defaultValues: {
      workflowSetup: {
        idToSearch: state.idToSearch ?? 'name',
        idToDisplay: state.idToDisplay ?? 'name',
        measured_at: state.measured_at ?? {},
        showGroups: state.showGroups ?? false,
        dosingData: state.dosingData ?? [],
        playSound: state.playSound ?? true,
        workflow: assembleInitialWorkflow(study.settings.calculations, workflow, {
          measurements: state.measurements,
          samples: state.samples,
          observations: state.observations,
          dosing: state.dosing,
        }),
        startTab: state.startTab ?? 'measurements',
      },
    },
    shouldUnregister: false,
  });

  const { handleSubmit, control } = formMethods;
  const treatmentDataFieldArray = useFieldArray({
    control,
    name: 'workflowSetup.dosingData',
    keyName: 'fieldId',
  });

  const listItems = getListItems(workflowState, setWorkflowState, treatmentDataFieldArray, study);
  const [selectedFlow, setSelectedFlow] = useState(1);
  const storeDispatch = useDispatch();

  useState(() => {
    if (_isEmpty(state.workflow)) {
      setWorkflowState(assembleInitialWorkflow(study.settings.calculations));
    }
  }, [workflow]);
  const submit = ({ workflowSetup }) => {
    dispatch({
      type: 'update',
      data: workflowSetup,
    });
    handleCallback();
  };

  const { Component: Panel, ...panelProps } = listItems.find((item) => item.id === selectedFlow);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(submit)} data-testid="workflow-modal-container">
        <div className="center mv3 bg-white br2 shadow-4" style={{ maxWidth: '900px' }}>
          <div className="pa3 bb b--moon-gray">
            <h3 className="basier-med normal f4 lh-title near-black">Workflow setup</h3>
          </div>
          <div className="w-100 flex flex-wrap ui__workflow__expand">
            <LeftSidePanel listItems={listItems} selectedFlow={selectedFlow} setSelectedFlow={setSelectedFlow} />
            <div className="bg-white flex flex-column justify-apart h-100 w-75">
              <Panel {...panelProps} />
            </div>
          </div>
          <div className="pa3 bt b--moon-gray">
            <Button testId="workflow-setup-submit" submit>
              Continue
            </Button>
            <Button
              plain
              className="ml3"
              onClick={() =>
                storeDispatch({
                  type: 'CLOSE_MODAL',
                })
              }
            >
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const LeftSidePanel = ({ listItems, selectedFlow, setSelectedFlow }) => {
  const inActiveMenuItemClasses = 'hover-blue hover-bg-moon-gray near-black';
  const activeMenuItemClasses = 'bg-near-white blue';

  return (
    <div className="br b--moon-gray w-25 h-100">
      <div className="mt3">
        {listItems.map((item, index) => (
          <div key={index}>
            <a
              onClick={() => setSelectedFlow(item.id)}
              className={`pointer flex justify-between items-center pl3 pr2 pv2 mh2 br1
                  ${item.id === selectedFlow ? activeMenuItemClasses : inActiveMenuItemClasses}
                `}
              data-testid={`${item.type}-setup-nav-link`}
            >
              <span className="f6 lh-title">{item.name}</span>
              <RiArrowDropRightFill size={22} />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

const StandardPanel = ({ workflowData, workflowState, setWorkflowState, index }) => (
  <>
    {workflowData.canReorder && (
      <div className="pa3">
        <DragHintBanner />
      </div>
    )}
    <CheckDrawer checks={workflowState} setWorkflowState={setWorkflowState} workflowData={workflowData} index={index} />
  </>
);

const RenderPanel = ({ type, workflow, workflowState, setWorkflowState, study, index }) => {
  switch (type) {
    case 'measurements':
      return <Measurements />;
    case 'samples':
      return <Samples />;
    case 'observations':
      return <Observations />;
    case 'dosing':
      return <Dosing studyApiId={study?.api_id} />;
    default:
      return (
        <StandardPanel
          workflowData={workflow}
          workflowState={workflowState}
          setWorkflowState={setWorkflowState}
          index={index}
        />
      );
  }
};

const getListItems = (workflowState, setWorkflowState, treatmentDataFieldArray, study) => {
  const results = [
    {
      id: 1,
      name: 'General',
      type: 'general',
      Component: General,
    },
  ];

  if (workflowState) {
    workflowState.forEach((w, i) => {
      results.push({
        id: i + 2,
        name: w.displayName,
        type: w.name,
        workflow: w,
        setWorkflowState,
        index: i,
        treatmentDataFieldArray,
        workflowState,
        studyId: study.id,
        study,
        Component: RenderPanel,
      });
    });
  }

  return results;
};

export default SetupWorkflow;
