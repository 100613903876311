import { components } from '@/generated/internal-api/openapi-types';
import { _isNotEmpty, _notNil } from '@/littledash';
import { ResponseType } from '@/support/ApiService';
import { useCallback, useEffect, useMemo, useState } from 'react';

type CollectionMeta = components['schemas']['CollectionMeta.schema'];

type PaginatedApiResponse<T> = {
  type: ResponseType;
  body?: {
    data: Array<T>;
    meta: CollectionMeta;
  };
};
type UseApiPaginationProps<T> = {
  response?: PaginatedApiResponse<T>;
};
type UseApiPaginationOutput<T> = {
  pages: Array<Array<T>>;
  nextPage: number;
  hasNextPage: boolean;
  reset: () => void;
};
function _isPageEmpty(pages: Array<Array<unknown>>, pageIndex: number): boolean {
  if (pages?.[pageIndex] && _isNotEmpty(pages[pageIndex])) {
    return false;
  }
  return true;
}

export const useApiPagination = <T>({ response }: UseApiPaginationProps<T>): UseApiPaginationOutput<T> => {
  const [pages, setPages] = useState<Array<Array<T>>>([]);
  const [nextPage, setNextPage] = useState(0);

  useEffect(() => {
    const pagination = response?.body?.meta;
    const newPages = response?.body?.data;
    if (
      _notNil(pagination) &&
      _notNil(pagination?.current_page) &&
      _notNil(pagination?.last_page) &&
      _isPageEmpty(pages, Number(pagination?.current_page) - 1) &&
      Array.isArray(newPages) &&
      newPages.length > 0
    ) {
      setPages([...pages, newPages]);
      setNextPage(pagination.current_page >= pagination.last_page ? pagination.last_page : pagination.current_page + 1);
    }
  }, [response]);

  const hasNextPage = useMemo(() => {
    const pagination = (response?.body as { meta?: Required<CollectionMeta> })?.meta;
    if (_notNil(pagination)) {
      return pagination.last_page > pagination.current_page;
    }
    return false;
  }, [response]);

  const reset = useCallback(() => {
    setPages([]);
    setNextPage(0);
  }, [setPages, setNextPage]);

  if (response?.type !== ResponseType.Success) {
    return {
      pages: [],
      nextPage: 0,
      hasNextPage: false,
      reset,
    };
  }

  return {
    pages,
    nextPage,
    hasNextPage,
    reset,
  };
};
