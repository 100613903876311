import ApiErrorBanner from '@/components/ApiErrorBanner';
import { ApiErrorType } from '@/components/ApiErrorBanner/ApiErrorBanner';
import Loading from '@/components/Loading';
import NoDataCard from '@/components/NoDataCard';
import { GroupsPanels } from '@/components/Studies/Groups/GroupsPanels';
import { GroupsTabs } from '@/components/Studies/Groups/GroupsTabs';
import { Tabs } from '@/components/UI/Tabs';
import { _isEmpty, _isNil, _isNotEmpty, _notNil } from '@/littledash';
import InVivoError from '@/model/InVivoError.ts';
import type { Study } from '@/model/Study.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { useQueryParams } from '@/support/Hooks/useQueryParams/useQueryParams';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import { useEffect, useReducer } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import type { GraphPanel, GraphTreatentGroup } from './Graph.model';
import { getParamsFromState, initialState, reducer } from './Groups.utils';
import './Groups.scss';

const activePanelQueryParameter = 'active';
const getActivePanel = (params: URLSearchParams): GraphPanel => {
  switch (params.get(activePanelQueryParameter)) {
    case 'daily':
      return 'daily';
    case 'spider':
      return 'spider';
    case 'analysis':
      return 'analysis';
    case 'survival':
      return 'survival';
    default:
      return 'grouped';
  }
};

const Groups = () => {
  const { id: studyId } = useParams<{ id: string }>();
  const queryParams = useQueryParams();
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState({ activePanel: getActivePanel(queryParams) }));
  const { entity: study, entityLoading: studyLoading } = useFetchEntity<Study>({
    entityType: 'study',
    params: { id: studyId },
    queryParams: { include: 'users' },
  });
  const { error, groups, changeOption, activeMetric, metricsLoading, activePanel } = state;
  useEffect(() => {
    const activeFromQueryParams = getActivePanel(queryParams);
    if (activePanel !== activeFromQueryParams) {
      const updatedQueryParams = new URLSearchParams(queryParams.toString());
      updatedQueryParams.set(activePanelQueryParameter, activePanel);
      history.push({ search: updatedQueryParams.toString() });
    }
  }, [activePanel, queryParams]);
  const fetchMetrics = async () => {
    const query = getParamsFromState(state);
    dispatch({ type: 'setMetricsLoading', data: true });
    return Http.get<{ data: Array<TreatmentGroup>; survival_start: string; survival_end: string }>(
      apiRoute('studies.groups.metrics.list', {
        studyId,
        query,
      })
    )
      .then(({ data: { data, survival_start: start, survival_end: end } }) => {
        if (_notNil(data)) {
          dispatch({
            type: 'setMetrics',
            data: data.filter((metric: TreatmentGroup) => metric.animals),
          });
        }
        dispatch({
          type: 'setSurvivalDates',
          data: { start, end },
        });
        dispatch({ type: 'setMetricsLoading', data: false });
      })
      .catch((error) => {
        dispatch({ type: 'setApiError', data: error });
        ExceptionHandler.captureException(
          new InVivoError('Could not fetch study group metrics', {
            cause: error,
            slug: 'group-metrics',
          })
        );
        dispatch({ type: 'setMetricsLoading', data: false });
      });
  };

  const fetchGroups = () => {
    dispatch({ type: 'setMetricsLoading', data: true });
    Http.get<{ data: Array<GraphTreatentGroup> }>(apiRoute('studies.groups.list', { id: studyId }, { perPage: -1 }))
      .then(({ data: { data } }) => {
        data.forEach((group) => (group.checked = true));
        dispatch({
          type: 'setGroups',
          data,
        });
        dispatch({ type: 'setMetricsLoading', data: false });
      })
      .catch((error) => {
        dispatch({ type: 'setApiError', data: error });
        ExceptionHandler.captureException(
          new InVivoError('Could not fetch study groups', {
            cause: error,
            slug: 'groups-fetch',
          })
        );
        dispatch({ type: 'setMetricsLoading', data: false });
      });
  };

  useEffect(() => {
    if (_isNotEmpty(groups) && _notNil(activeMetric)) {
      fetchMetrics();
    }
  }, [activeMetric, changeOption, groups]);

  useEffect(() => {
    if (study) {
      dispatch({
        type: 'setActiveMetric',
        data: study.settings.calculations[0],
      });
      fetchGroups();
    }
  }, [study]);

  const loadingSpinner = (
    <div className="w-100 h-100">
      <Loading txt="Loading graphs" />
    </div>
  );

  if (studyLoading) {
    return loadingSpinner;
  }
  if (_notNil(study)) {
    if (_isEmpty(study?.settings.calculations) || _isNil(activeMetric)) {
      return (
        <div className="flex items-center justify-center h-100">
          <NoDataCard
            title="No preset has been selected for this study."
            text="You can create a preset in your study settings."
            link={webRoute('studies.settings', { id: studyId })}
            btnTxt="Study settings"
          />
        </div>
      );
    }
    if (_isEmpty(groups)) {
      return metricsLoading ? (
        loadingSpinner
      ) : (
        <div className="flex items-center justify-center h-100">
          <NoDataCard
            title="There are no study groups in this study"
            link={webRoute('studies.studyGroups', {
              id: studyId,
            })}
            btnTxt="Create treatment groups"
          />
        </div>
      );
    }

    return (
      <>
        {error && <ApiErrorBanner className="mb3" errorType={ApiErrorType.FETCH} error={error} />}
        <div className={`pv3 ${metricsLoading ? 'pointer-events-none' : ''}`}>
          <Tabs>
            <div className="ph4 bb b--moon-gray">
              <GroupsTabs
                active={state.activePanel}
                onChange={(data) => dispatch({ type: 'change-panel', data })}
                hasAnalysis={(study.settings.analysis_config?.config ?? []).length > 0}
              />
            </div>
            <div className="bg-white">
              <GroupsPanels study={study} state={state} dispatch={dispatch} fetchMetrics={fetchMetrics} />
            </div>
          </Tabs>
        </div>
      </>
    );
  }
};

export default Groups;
