import Loading from '@/components/Loading';
import NoData from '@/components/NoData';
import Configuration from '@/components/Studies/StudyGroups/Configuration';
import { Treatments } from '@/components/Studies/StudyGroups/Treatments/Treatments.tsx';
import Header from '@/components/UI/Header';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { _notNil } from '@/littledash';
import { Study } from '@/model/Study.model';
import * as Auth from '@/support/auth';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Groups from './Groups';
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { getTabIndex, Tab as GroupTab } from '@/components/Studies/StudyGroups/StudyGroups.utils';
import { State } from '@/model/State.model.ts';

export const StudyGroups = () => {
  const selectDiseaseInduction = createSelector(
    (state: State) => state.team?.features?.disease_induction ?? false,
    (disease_induction) => ({ disease_induction })
  );
  const { disease_induction } = useSelector(selectDiseaseInduction);

  const { id: studyId } = useParams<{ id: string }>();
  const { entity: study, entityLoading: studyLoading } = useFetchEntity<Study>({
    entityType: 'study',
    params: { id: studyId },
    includes: 'study_groups,users',
  });

  const isComplete = _notNil(study?.archived_at);
  const canEdit =
    (!isComplete &&
      study &&
      (Auth.isWriteUserForStudy(study, false, false) || Auth.isAuthor(study) || Auth.isOwner(study))) ??
    false;

  const tabs: Array<GroupTab> = [
    {
      id: 'groups',
      name: 'Groups',
      element: <Groups studyApiId={study?.api_id} canEdit={canEdit} />,
    },
  ];

  if (disease_induction) {
    tabs.push({
      id: 'diseases',
      name: 'Diseases',
      element: <Treatments study={study} type={'disease_induction'} />,
    });
  }

  tabs.push({
    id: 'treatments',
    name: 'Treatments',
    element: <Treatments study={study} type={'treatment'} />,
  });

  if (canEdit && _notNil(study?.api_id)) {
    tabs.push({
      id: 'configuration',
      name: 'Configuration',
      element: <Configuration canEdit={canEdit} studyApiId={study?.api_id} />,
    });
  }

  const { search, pathname } = useLocation();
  const history = useHistory();
  const [tabState, setTabState] = useState(getTabIndex(search, tabs));

  useEffect(() => {
    const newTabState = getTabIndex(search, tabs);
    setTabState((prev) => (newTabState !== prev ? newTabState : prev));
  }, [search]);
  useEffect(() => {
    history.push({ pathname, search: `?tab=${tabs[tabState].id}` });
  }, [tabState]);

  if (studyLoading) {
    return (
      <div className="w-100 h-100">
        <Loading />
      </div>
    );
  }

  return (
    <>
      {_notNil(study) &&
      (Auth.isStudyUser(study) || Auth.isOwner(study) || Auth.isAuthor(study) || Auth.isCurrentTeamOwner()) ? (
        <div className="pv3 ph4">
          <Header mainHeaderText="Treatment groups" />
          <Tabs outerState={[tabState, setTabState]}>
            <div className="mt3 pt3 bb b--moon-gray">
              {tabs.map(({ id, name }) => (
                <Tab className="f4" key={`${id}_tab`} testId={`${name}_tab`}>
                  {name}
                </Tab>
              ))}
            </div>
            {tabs.map(({ id, element }) => (
              <Panel key={`${id}_panel`}>{element}</Panel>
            ))}
          </Tabs>
        </div>
      ) : (
        <div className="w-100 h-100 flex items-center justify-center">
          <NoData
            title="You aren't authorized"
            text="Only team members with Read access have access to use Treatment groups. The study author can update your permissions to give you access."
            link={`/studies/${studyId}/animals/`}
            btnTxt="Return to animals"
          />
        </div>
      )}
    </>
  );
};
