import AnimalsTable from '@/components/Animals/AnimalsTable/AnimalsTable';
import Header from '@/components/UI/Header';
import { FetchProvider } from '@/support/Hooks/fetch';
import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form@latest';
import Button from '@/components/UI/Button';
import { AnimalApiId } from '@/model/Animal.model.ts';
import { useCreateDataTableFromTemplateContext } from '@/components/Studies/Templates/CreateDataTable/CreateDataTableFromTemplateProvider.tsx';

interface SelectedRowProp {
  original: { api_id: AnimalApiId };
}

export const SelectAnimals: FC = () => {
  const { onSubmit, onCancel, submitButtonText, cancelButtonText, study, state } =
    useCreateDataTableFromTemplateContext();

  const formMethods = useForm({
    defaultValues: {
      animals: state.select_animals.animals,
    },
  });

  const [animals, setAnimals] = useState<AnimalApiId[]>([]);

  const handleSelection = (selectedFlatRows: Array<SelectedRowProp>) => {
    setAnimals(selectedFlatRows.map((row) => row.original.api_id));
  };

  const handleAnimalsSubmit = () => {
    onSubmit({ step: 'select_animals', selectAnimalsData: { animals: animals } });
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(handleAnimalsSubmit)}
          data-test-component="SelectAnimals"
          data-test-element="form"
        >
          <div className="ph4 pv3">
            <Header mainHeaderText="Choose animals" />
          </div>
          <div className="ph4" data-test-component="SelectAnimals" data-test-element="api-table-container">
            {/* @ts-expect-error - Type error for entity in FetchProvider */}
            <FetchProvider entity={study}>
              {/* @ts-expect-error - Type error for enableBulkActions */}
              <AnimalsTable enableBulkActions={false} onSelectRow={handleSelection} tableMaxHeight={550} />
            </FetchProvider>
          </div>
          <div className="pa3 b--moon-gray">
            <Button
              submit
              testKey="createDataTableFromTemplateStepFormSubmit"
              testId="select-animals-submit"
              disabled={animals.length < 1}
            >
              {submitButtonText}
            </Button>
            <Button plain className="ml2" onClick={onCancel}>
              {cancelButtonText}
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
