import Loading from '@/components/Loading';
import Radio from '@/components/UI/FormElements/Radio/Radio';
import SuccessTick from '@/components/UI/SuccessTick';
import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import InVivoError from '@/model/InVivoError.ts';
import type { StudyApiId } from '@/model/Study.model';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import ApiErrorBanner from '@/components/ApiErrorBanner';

export const enum ImportTypes {
  animalId = 'animal_id',
  orderId = 'order_id',
  projectId = 'project_id',
}
interface ImportAnimalsProps {
  onComplete: () => void;
  studyApiId: StudyApiId;
}
const ImportAnimals: React.FC<ImportAnimalsProps> = ({ studyApiId, onComplete }) => {
  const dispatch = useDispatch();
  const [importBy, setImportBy] = useState<ImportTypes>(ImportTypes.animalId);
  const [value, setValue] = useState('');
  const [messages, setMessages] = useState<Array<string>>([]);
  const [errors, setErrors] = useState<Array<string>>([]);

  const { loading: importLoading, invoke: invokeImport } = useApiHook({
    endpoint: 'POST /api/v1/studies/{studyApiId}/external-colony/import',
    invokeOnInit: false,
    path: { studyApiId },
  });

  const closeModal = () => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  const onSubmit = async () => {
    setMessages([]);
    setErrors([]);

    try {
      let response;
      switch (importBy) {
        case ImportTypes.animalId:
          response = await invokeImport({ body: { animal_id: value }, path: { studyApiId } });
          break;
        case ImportTypes.orderId:
          response = await invokeImport({ body: { order_id: value }, path: { studyApiId } });
          break;
        case ImportTypes.projectId:
          response = await invokeImport({ body: { project_id: value }, path: { studyApiId } });
          break;
      }

      const body = response.body;
      if (_notNil(body) && body.success) {
        const messages = [`${body.animals} animals`, `${body.cages} cages`];
        setMessages(messages);
      } else if (_notNil(body) && body.errors) {
        setErrors(body.errors);
      }

      onComplete();
    } catch (error) {
      setMessages([]);
      ExceptionHandler.captureException(
        new InVivoError('Could not import from external colony', {
          cause: error,
          slug: 'import-external-animals',
        })
      );
    }
  };

  return (
    <ModalContainer size="small">
      <ModalHeader title="Import from tick@lab" closeModal={closeModal} className="pa3 b--moon-gray bg-white" />
      {importLoading ? (
        <Loading txt="Importing from tick@lab" />
      ) : (
        <div className="pb3 ph3">
          <Radio
            id={ImportTypes.animalId}
            key={ImportTypes.animalId}
            checked={importBy === ImportTypes.animalId}
            onChange={() => setImportBy(ImportTypes.animalId)}
            name="importBy"
            label="Animal number"
            value={ImportTypes.animalId}
            className="mb2"
          />
          <Radio
            id={ImportTypes.orderId}
            key={ImportTypes.orderId}
            checked={importBy === ImportTypes.orderId}
            onChange={() => setImportBy(ImportTypes.orderId)}
            name="importBy"
            label="Order number"
            value={ImportTypes.orderId}
            className="mb2"
          />
          <Radio
            id={ImportTypes.projectId}
            key={ImportTypes.projectId}
            checked={importBy === ImportTypes.projectId}
            onChange={() => setImportBy(ImportTypes.projectId)}
            name="importBy"
            label="Project number"
            value={ImportTypes.projectId}
            className="mb3"
          />
          <input type="text" placeholder="Number" onChange={onChangeValue} value={value} autoFocus={true} />
          {_isNotEmpty(messages) && (
            <div className="center mw6 ui-card">
              <div className="pa3 bg-white">
                <h3 className="f4 mb3 flex flex-row items-center">
                  <SuccessTick className="mr2" />
                  <span>Import complete</span>
                </h3>
                <p className="f5 pb1">We imported the following entities from tick@lab</p>
                <ul>
                  {messages.map((message, index) => (
                    <li key={index} className="f5">
                      &#8226; {message}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {_isNotEmpty(errors) && (
            <div className="w-100 h-100 flex items-center justify-center">
              <ApiErrorBanner
                className="mb4 mw6"
                title="There was an error importing from tick@lab"
                text={errors.map((error) => `• ${error}`).join('\n')}
                error={errors}
              />
            </div>
          )}
        </div>
      )}
      <ModalActions
        onSubmit={onSubmit}
        submitButtonProps={{ disabled: _isEmpty(value) }}
        submitBtnText="Import"
        className="pa3 bt"
        onCancel={closeModal}
      />
    </ModalContainer>
  );
};

export default ImportAnimals;
