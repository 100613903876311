import Loading from '@/components/Loading';
import DoseList from '@/components/Workflow/Show/Views/Dosing/DoseList';
import type { Animal } from '@/model/Animal.model';
import type { Study } from '@/model/Study.model';
import { FC } from 'react';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { _isNil } from '@/littledash';
import cn from 'classnames';
import { DosageV1 } from '@/model/Dose.model';

interface DosingAnimalViewProps {
  study: Study;
  animal: Animal;
}

const Dosing: FC<DosingAnimalViewProps> = ({ study, animal }) => {
  const {
    response: dosagesResponse,
    loading: dosagesLoading,
    invoke: reloadDosages,
  } = useApiHook({
    endpoint: 'GET /api/v1/animals/{animalId}/dosages',
    path: { animalId: Number(animal.id) },
    query: { sort: 'dosed_at', order: 'desc' },
  });

  const dosages: Array<DosageV1> | null = dosagesResponse?.body?.data ?? null;

  if (_isNil(dosages)) {
    return <Loading txt="Fetching dosages..." />;
  }

  return (
    <div className={cn('ui-card mw7', { ui__disabled: dosagesLoading })}>
      <DoseList study={study} subject={animal} dosages={dosages} handleCallback={() => reloadDosages()} />
    </div>
  );
};

export default Dosing;
