import { Controller } from 'react-hook-form@latest';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form@latest';
import DatePickerNative from '@/components/UI/DatePickerNative';
import { differenceInDays, format } from 'date-fns';
import {
  MeasurementMapping,
  ReferenceDateColumn,
  ReferenceDateColumnGroup,
} from '@/components/Studies/Templates/CreateDataTable/CreateDataTableFromTemplate.model.tsx';
import { _notNil } from '@/littledash.ts';

export interface ReferenceDateColumnGroupProps {
  referenceDateColumnGroup: ReferenceDateColumnGroup;
  mappedMeasurements: MeasurementMapping;
  index: number;
  studyStartDate: string;
}

const ColumnTypeLabels: Record<string, string> = {
  measurement_output: 'Measurement',
  score: 'Observation score',
  comments: 'Observation comments',
};

export const ReferenceDateColumnGroupInput: FC<ReferenceDateColumnGroupProps> = ({
  referenceDateColumnGroup,
  mappedMeasurements,
  index,
  studyStartDate,
}) => {
  const { control, getValues, setError, clearErrors } = useFormContext();
  return (
    <div className="pt3 bb">
      {referenceDateColumnGroup.referenceDateColumns.map(
        (referenceDateColumn: ReferenceDateColumn, innerIndex: number) => (
          <Controller
            key={referenceDateColumn.columnApiId}
            name={`referenceDateColumnGroups.${index}.referenceDateColumns.${innerIndex}.date`}
            control={control}
            render={({ field: { value, onChange }, fieldState }) => (
              <div className={`flex items-center pt1 pb1`}>
                <div className="w-20">
                  <label>
                    {_notNil(mappedMeasurements.get(referenceDateColumn.columnApiId))
                      ? mappedMeasurements.get(referenceDateColumn.columnApiId)?.target_label
                      : referenceDateColumnGroup.columnName}
                  </label>
                  <p className="f6">{ColumnTypeLabels[referenceDateColumnGroup.type]}</p>
                </div>
                <div className="w-40 flex">
                  <DatePickerNative
                    value={value ?? ''}
                    onChange={(selectedDate) => {
                      if (referenceDateColumnGroup.type === 'measurement_output') {
                        onChange(selectedDate);

                        const referenceDateColumns: ReferenceDateColumn[] = getValues(
                          `referenceDateColumnGroups.${index}.referenceDateColumns`
                        );
                        const dateCounts = new Map<string, number>();
                        referenceDateColumns.forEach((col) => {
                          if (!col.date) return;
                          dateCounts.set(col.date, (dateCounts.get(col.date) || 0) + 1);
                        });

                        referenceDateColumns.forEach((col, i) => {
                          const colPath = `referenceDateColumnGroups.${index}.referenceDateColumns.${i}.date`;
                          if (col.date && dateCounts.get(col.date)! > 1) {
                            setError(colPath, { message: 'Duplicate date on measurement' });
                          } else {
                            clearErrors(colPath);
                          }
                        });
                      } else {
                        onChange(selectedDate);
                      }
                    }}
                    testId={`referenceDateColumnGroups.${index}.referenceDateColumns.${innerIndex}.date`}
                  />
                  {fieldState.error && (
                    <p className="red mt2 f6 pl2" data-test-element="error-message">
                      {fieldState.error.message}
                    </p>
                  )}
                </div>
                <div className="w-20">
                  <label
                    data-testid={`referenceDateColumnGroups.${index}.referenceDateColumns.${innerIndex}.date-day-label`}
                  >
                    Day {differenceInDays(referenceDateColumn.date, studyStartDate) + 1}
                  </label>
                  <p className="f6">{format(referenceDateColumn.date, 'EEEE')}</p>
                </div>
              </div>
            )}
          />
        )
      )}
    </div>
  );
};
