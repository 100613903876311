import { AppDebug } from '@/components/AppDebug/AppDebug.tsx';
import Approvals from '@/components/Approvals';
import ApprovalDetails from '@/components/Approvals/ApprovalDetails';
import ColonyManageCages from '@/components/Colony/Cages/Manage';
import BulkAnimalEdit from '@/components/Colony/Edit/BulkAnimals';
// #Team study settings
import TeamApprovals from '@/components/Glossary/Sections/Approvals';

// #Terminology Sections
import Metadata from '@/components/Glossary/Sections/Metadata';
import Observations from '@/components/Glossary/Sections/Observations';
import { PresetBuilder } from '@/components/Glossary/Sections/Presets/Builder/PresetBuilder.tsx';
import { Presets } from '@/components/Glossary/Sections/Presets/Presets.tsx';
import TeamRequests from '@/components/Glossary/Sections/Requests';
import SampleTypes from '@/components/Glossary/Sections/SampleTypes';
import SpeciesStrains from '@/components/Glossary/Sections/SpeciesStrains';
import DeceasedReasons from '@/components/Glossary/Sections/TerminationReasons';
import Treatments from '@/components/Glossary/Sections/Treatments';
import Maintenance from '@/components/Maintenance';
import StudyNav from '@/components/Navigation/StudyNav';
import TasksNav from '@/components/Navigation/TasksNav';
import TeamNav from '@/components/Navigation/TeamNav';
import RegisterDevices from '@/components/RegisterDevices';
import Requests from '@/components/Requests';
import RequestDetails from '@/components/Requests/RequestDetails';
import BulkSampleEdit from '@/components/Samples/EditSamples/BulkSampleEdit';
import SamplesTable from '@/components/Samples/SamplesTable';
import Report from '@/components/Studies/Randomization/Report';
import { StudySchedule } from '@/components/Studies/Show/StudySchedule';
import { StudyGroups } from '@/components/Studies/StudyGroups/StudyGroups.tsx';
import { FeatureFlagConfig } from '@/components/Team/AdminConsole/FeatureFlagConfig';
import { TickatlabConfig } from '@/components/Team/AdminConsole/TickatlabConfig.tsx';
import ApiKeys from '@/components/Team/ApiKeys';
import CageCards from '@/components/Team/CageCards';
import TreatmentGroupSetup from '@/components/Team/Groups';
// #Integrations
import SSO from '@/components/Team/SingleSignOn';
import Forms from '@/components/Team/Studies/Forms';
import FormEdit from '@/components/Team/Studies/Forms/FormEdit';
import { UnSupportedClientVersion } from '@/components/UnSupportedClientVersion/UnSupportedClientVersion.tsx';
import { isCancelled, isNotApproved } from '@/helpers';
import Account from '@/pages/account';
import Animals from '@/pages/animals';
import Attachments from '@/pages/attachments/attachments';
import Cages from '@/pages/cages/cages';
import ColonyAddAnimals from '@/pages/colony/addAnimals';
import ColonyAddToStudy from '@/pages/colony/addToStudy';
import ColonyBulkAnimals from '@/pages/colony/bulkAnimals';
import ColonyBulkCages from '@/pages/colony/bulkCages';
import Colony from '@/pages/colony/colony';
import ColonyCreation from '@/pages/colony/creation';
import Profile from '@/pages/colony/profile';
import createAccount from '@/pages/create-account';
import Dashboard from '@/pages/dashboard';
import DataTablesCreate from '@/pages/dataTables/Create';
import DisplayDatatable from '@/pages/dataTables/Display';
import ForgotPassword from '@/pages/forgot-password';
import Graphs from '@/pages/groups/groups';
import ImportMeasurements from '@/pages/import/ImportMeasurements';
import { Login } from '@/pages/login/Login.tsx';
import StudyObservations from '@/pages/observations/observations';
import PasswordReset from '@/pages/reset-password';
import StudyApproval from '@/pages/studies/approval';
import Export from '@/pages/studies/export';
import CreateStudy from '@/pages/studies/new';
import Randomization from '@/pages/studies/randomization';
import Recaging from '@/pages/studies/recaging';
import Create from '@/pages/studies/requestCreate';
import Edit from '@/pages/studies/requestEdit';
import Settings from '@/pages/studies/settings';
import ShowStudy from '@/pages/studies/show';
import ShowSubjects from '@/pages/subjects/show';
import Tasks from '@/pages/tasks';
import TaskTemplatesCreate from '@/pages/tasks/new';
import TaskTemplates from '@/pages/tasks/templates';

// # Users
import ManageTeam from '@/pages/team/manage';
import StudyCodes from '@/pages/team/study-codes/StudyCode.routes';
import {
  onlySupportUsers,
  onlyTeamOwners,
  resetReduxSettings,
  withGlossary,
  withSpacing,
  withStudy,
  withStudyV2,
} from './routeHOCs';
import { ListDosing } from '@/components/Studies/Dosing/List/ListDosing';
import { CreateDosing } from '@/components/Studies/Dosing/Create/CreateDosing';
import { WorkflowV2 } from '@/components/Workflow/Show/WorkflowTemplateSetup';
import { IntegrationConfigV2 } from '@/components/Team/AdminConsole/IntegrationConfigV2.tsx';
import DataTables from '@/components/Studies/DataTables/DataTables.tsx';
import CreateDataTableFromTemplate from '@/components/Studies/Templates/CreateDataTable/CreateDataTableFromTemplate.tsx';
import { PresetMappingActiveVariables } from '@/components/Modals/MoveToStudy/PresetMapping/PresetMapping.tsx';

export const notLoggedInRoutes = [
  {
    path: '/maintenance',
    component: Maintenance,
    name: 'Maintenance',
    exact: true,
  },
  {
    path: '/login',
    component: Login,
    private: false,
    exact: true,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    private: false,
    exact: true,
  },
  {
    path: '/reset-password',
    component: PasswordReset,
    private: false,
    exact: true,
  },
  {
    path: '/debug',
    component: AppDebug,
    private: false,
    exact: true,
  },
  {
    path: '/unsupported-client-version',
    component: UnSupportedClientVersion,
    private: false,
    exact: true,
  },
];

export const loggedInRoutes = [
  {
    path: '/',
    component: resetReduxSettings(Dashboard),
    private: true,
    exact: true,
  },
  {
    path: '/dashboard',
    component: resetReduxSettings(Dashboard),
    private: true,
    exact: true,
  },
  {
    path: '/samples',
    component: SamplesTable,
    private: true,
    exact: true,
  },
  {
    path: '/tasks',
    private: true,
    exact: true,
    navigation: TasksNav,
    canCollapseNav: true,
    component: resetReduxSettings(Tasks),
  },
  {
    path: '/tasks/templates',
    private: true,
    exact: true,
    navigation: TasksNav,
    canCollapseNav: true,
    component: TaskTemplates,
  },
  {
    path: '/tasks/templates/new',
    private: true,
    exact: true,
    navigation: TasksNav,
    canCollapseNav: true,
    component: TaskTemplatesCreate,
  },
  {
    path: '/tasks/templates/:template',
    private: true,
    exact: true,
    navigation: TasksNav,
    canCollapseNav: true,
    component: TaskTemplatesCreate,
  },
  {
    path: '/colony',
    private: true,
    exact: true,
    component: Colony,
    name: 'Colony',
  },
  {
    path: '/colony/add',
    private: true,
    exact: true,
    component: ColonyCreation,
    name: 'ColonyCreation',
  },
  {
    path: '/colony/edit/cages',
    private: true,
    exact: true,
    component: ColonyBulkCages,
    name: 'ColonyBulkCages',
  },
  {
    path: '/colony/edit/animals',
    private: true,
    exact: true,
    component: ColonyBulkAnimals,
    name: 'ColonyBulkAnimals',
  },
  {
    path: '/colony/:id/add-animals',
    private: true,
    exact: true,
    component: ColonyAddAnimals,
    name: 'ColonyEdit',
  },
  {
    path: '/colony/profile/:subjectId',
    private: true,
    exact: true,
    component: Profile,
  },
  {
    path: '/colony/add/study/:studyId',
    private: true,
    exact: true,
    component: ColonyAddToStudy,
    name: 'ColonyAddToStudy',
  },
  {
    path: '/colony/cages/manage',
    private: true,
    exact: true,
    component: ColonyManageCages,
    name: 'ColonyManage',
  },
  {
    path: '/approvals',
    private: true,
    exact: true,
    component: Approvals,
    name: 'Approvals',
  },
  {
    path: '/approvals/:approvalRequestId/:tab?',
    private: true,
    exact: true,
    component: ApprovalDetails,
    name: 'ApprovalDetails',
  },
  {
    path: '/requests',
    private: true,
    exact: true,
    component: Requests,
    name: 'Requests',
  },
  {
    path: '/requests/:requestId/:tab?',
    private: true,
    exact: true,
    component: RequestDetails,
    name: 'RequestDetails',
  },
  {
    path: '/studies/new',
    private: true,
    exact: true,
    component: resetReduxSettings(CreateStudy),
  },
  {
    path: '/studies/:id',
    private: true,
    exact: true,
    component: ShowStudy,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/schedule',
    private: true,
    exact: true,
    component: StudySchedule,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/recaging',
    private: true,
    exact: true,
    component: Recaging,
    name: 'Studies/Recaging',
  },
  {
    path: '/studies/:id/animals',
    private: true,
    exact: true,
    component: Animals,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/animals/edit',
    private: true,
    exact: true,
    component: BulkAnimalEdit,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/observations',
    private: true,
    exact: true,
    component: StudyObservations,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/settings',
    private: true,
    exact: true,
    component: Settings,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/randomization',
    private: true,
    exact: true,
    component: withStudyV2(Randomization, {
      includes: 'project',
      restrictedAccessFns: [isNotApproved, isCancelled],
    }),
    navigation: StudyNav,
    canCollapseNav: true,
  },

  {
    path: '/studies/:id/randomization/:reportApiId',
    private: true,
    exact: true,
    component: Report,
    navigation: StudyNav,
  },
  {
    path: '/studies/:id/import',
    private: true,
    exact: true,
    component: ImportMeasurements,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/datatables',
    private: true,
    exact: true,
    component: DataTables,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/datatables/create',
    private: true,
    exact: true,
    component: DataTablesCreate,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/templates/datatables/create',
    private: true,
    exact: true,
    component: CreateDataTableFromTemplate,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/move-animals',
    private: true,
    exact: true,
    component: PresetMappingActiveVariables,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/dosing',
    private: true,
    exact: true,
    component: ListDosing,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/dosing/create/:classification',
    private: true,
    exact: true,
    component: CreateDosing,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/attachments',
    private: true,
    exact: true,
    component: Attachments,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/samples',
    private: true,
    exact: true,
    component: SamplesTable,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/samples/edit',
    private: true,
    exact: true,
    component: BulkSampleEdit,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/export',
    private: true,
    exact: true,
    component: Export,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/workflow',
    private: true,
    exact: true,
    component: WorkflowV2,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/cages',
    private: true,
    exact: true,
    component: Cages,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/study-groups/:tab?',
    private: true,
    exact: true,
    component: StudyGroups,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/graphs',
    private: true,
    exact: true,
    component: Graphs,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/animals/:subjectId',
    private: true,
    exact: true,
    component: ShowSubjects,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/approvals/:approvalRequestId/:tab?',
    private: true,
    exact: true,
    component: withStudy(StudyApproval),
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/requests',
    private: true,
    exact: true,
    component: Create,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/requests/:requestId/:tab?',
    private: true,
    exact: true,
    component: withStudy(Edit),
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/studies/:id/datatables/:dataTableId',
    private: true,
    exact: true,
    component: DisplayDatatable,
    navigation: StudyNav,
    canCollapseNav: true,
  },
  {
    path: '/team',
    private: true,
    exact: true,
    component: resetReduxSettings(onlyTeamOwners(ManageTeam)),
    navigation: TeamNav,
  },
  {
    path: '/team/glossary/metadata',
    private: true,
    exact: false,
    component: onlyTeamOwners(withSpacing(Metadata)),
    navigation: TeamNav,
  },
  {
    path: '/team/glossary/observations',
    private: true,
    exact: false,
    component: onlyTeamOwners(withSpacing(withGlossary(Observations))),
    navigation: TeamNav,
  },
  {
    path: '/team/glossary/samples',
    private: true,
    exact: false,
    component: onlyTeamOwners(withSpacing(withGlossary(SampleTypes, 'samples'))),
    navigation: TeamNav,
  },
  {
    path: '/team/glossary/deceased',
    private: true,
    exact: false,
    component: onlyTeamOwners(withSpacing(withGlossary(DeceasedReasons, 'euthanize_reasons'))),
    navigation: TeamNav,
  },
  {
    path: '/team/glossary/species-and-strains',
    private: true,
    exact: true,
    component: onlyTeamOwners(withSpacing(SpeciesStrains)),
    navigation: TeamNav,
  },
  {
    path: '/team/glossary/treatments',
    private: true,
    exact: true,
    component: onlyTeamOwners(withSpacing(Treatments)),
    navigation: TeamNav,
  },
  {
    path: '/team/glossary/presets',
    private: true,
    exact: true,
    component: onlyTeamOwners(withSpacing(Presets)),
    navigation: TeamNav,
  },
  {
    path: '/team/glossary/presets/:presetApiId/:presetAction',
    private: true,
    exact: true,
    component: onlyTeamOwners(PresetBuilder),
    navigation: TeamNav,
  },
  {
    path: '/team/glossary/presets/:presetAction',
    private: true,
    exact: true,
    component: onlyTeamOwners(PresetBuilder),
    navigation: TeamNav,
  },
  {
    path: '/team/studies/approvals',
    private: true,
    exact: false,
    component: onlyTeamOwners(withSpacing(TeamApprovals)),
    navigation: TeamNav,
  },
  {
    path: '/team/studies/requests',
    private: true,
    exact: false,
    component: onlyTeamOwners(TeamRequests),
    navigation: TeamNav,
  },
  {
    path: '/team/studies/cage-cards',
    private: true,
    exact: false,
    component: onlyTeamOwners(withSpacing(CageCards)),
    navigation: TeamNav,
  },
  {
    path: '/team/studies/devices',
    private: true,
    exact: false,
    component: onlyTeamOwners(withSpacing(RegisterDevices)),
    navigation: TeamNav,
  },
  {
    path: '/team/studies/groups',
    private: true,
    exact: false,
    component: onlyTeamOwners(withSpacing(TreatmentGroupSetup)),
    navigation: TeamNav,
  },
  {
    path: '/team/studies/study-codes',
    private: true,
    exact: false,
    component: onlyTeamOwners(withSpacing(StudyCodes)),
    navigation: TeamNav,
  },
  {
    path: '/team/studies/forms',
    private: true,
    exact: true,
    component: onlyTeamOwners(withSpacing(Forms)),
    navigation: TeamNav,
  },
  {
    path: '/team/studies/forms/:formId',
    private: true,
    exact: true,
    component: onlyTeamOwners(FormEdit),
    navigation: TeamNav,
  },
  {
    path: '/team/integrations/sso',
    private: true,
    exact: false,
    component: onlyTeamOwners(withSpacing(SSO)),
    navigation: TeamNav,
  },
  {
    path: '/team/integrations/api',
    private: true,
    exact: true,
    component: onlyTeamOwners(withSpacing(ApiKeys)),
    navigation: TeamNav,
  },
  {
    path: '/account',
    private: true,
    exact: true,
    component: resetReduxSettings(Account),
  },
  {
    path: '/create-account',
    component: createAccount,
    private: true,
    exact: true,
  },
  {
    path: '/team/admin-console/feature-flags',
    private: true,
    exact: false,
    component: onlySupportUsers(withSpacing(FeatureFlagConfig)),
    navigation: TeamNav,
  },
  {
    path: '/team/admin-console/sync',
    private: true,
    exact: false,
    component: onlySupportUsers(withSpacing(IntegrationConfigV2)),
    navigation: TeamNav,
  },
  {
    path: '/team/admin-console/tickatlab',
    private: true,
    exact: false,
    component: onlySupportUsers(withSpacing(TickatlabConfig)),
    navigation: TeamNav,
  },
];
