import { _notNil } from '@/littledash.ts';
import { ReactElement } from 'react';

export type Tab = {
  id: string;
  name: string;
  element: ReactElement;
};

export const getTabId = (search: string): string | null => {
  return new URLSearchParams(search).get('tab');
};

export const getTabIndex = (search: string, tabs: Array<Tab>) => {
  const tabId = getTabId(search);
  if (_notNil(tabId)) {
    const tabIndex = tabs.findIndex((tab) => tab.id === tabId);
    return tabIndex >= 0 && tabIndex <= tabs.length ? tabIndex : 0;
  }
  return 0;
};
