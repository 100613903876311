// @ts-nocheck: converted from JS

import Radio from '@/components/UI/FormElements/Radio';
import Label from '@/components/UI/Label';
import { LabelType } from '@/components/UI/Label/Label.tsx';
import RemoveButton from '@/components/UI/RemoveButton';
import SearchSelect from '@/components/UI/SearchSelect';
import ReactSelect from '@/components/UI/Select';
import { missingNameUseEmail, trunc } from '@/helpers';
import type { ID } from '@/model/Common.model';
import type { StudyUser } from '@/model/User.model';
import { UserPermissions } from '@/referenceData/study/user';
import { ErrorMessage } from '@hookform/error-message';
import { useEffect, useMemo, useState } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Controller, ControllerRenderProps, useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { isRegisteringOnBehalf } from './Team.utils';
import { isCurrentTeamOwnerWithSettingsAccess } from '@/support/auth';

interface TeamProps {
  users: Array<StudyUser>;
  owner?: ID;
  author?: StudyUser;
  dispatch: Function; // eslint-disable-line @typescript-eslint/no-unsafe-function-type
}

const Team = ({ users = [], owner, author, dispatch }: TeamProps) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { currentUser, team } = useSelector(({ user: { currentUser }, team: { team } }) => ({
    currentUser,
    team,
  }));
  if (!author) {
    author = currentUser;
  }

  const [registeringOnBehalfActive, setRegisteringOnBehalfActive] = useState(isRegisteringOnBehalf(owner));

  useEffect(() => {
    if (users) {
      setSelectedUsers(users.map((user) => Number(user.id)));
    }
    dispatch({ type: 'stepReady' });
  }, []);

  const { control, register, setValue, errors } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    keyName: 'keyID',
    name: 'users',
  });

  const teamUsers = (team?.users ?? [])
    .filter((user) => user.pivot.status === 'active' && Number(user.id) !== Number(author.id))
    .map(missingNameUseEmail);

  const sections = useMemo(
    () => [
      {
        name: 'All users',
        items: teamUsers || [],
      },
    ],
    [team, teamUsers]
  );

  const handleUserSelect = ({ type, value }) => {
    const userById = (user) => Number(user.id) === Number(value);
    const selectedUser = teamUsers.find(userById);
    if (type === 'ADDED' && selectedUser) {
      const { id, api_id, name, email } = selectedUser;
      append({
        id,
        api_id,
        name,
        email,
        access: 'write',
      });
    } else {
      const indexToRemove = fields.findIndex(userById);
      remove(indexToRemove);
    }
  };

  const handleClearSelection = () => {
    remove();
    setSelectedUsers([]);
  };

  const handleSelectAll = () => {
    remove();
    setSelectedUsers(teamUsers.map((user) => user.id));
    teamUsers.map(({ id, api_id, name, email }) => {
      return append({
        id,
        api_id,
        name,
        email,
        access: 'write',
      });
    });
  };

  const handleRemoveUser = (index) => {
    remove(index);
    setSelectedUsers(selectedUsers.filter((_, i) => i !== index));
  };

  const getDefaultOwner = (value) => {
    const findOwner = teamUsers.find((user) => Number(user.id) === Number(value));
    if (findOwner) {
      return {
        label: findOwner.name,
        value: findOwner.id,
      };
    }
    return '';
  };

  const doesUserExistInFields = (id) => !!fields.find((u) => Number(u.id) === Number(id));

  const accessLevels = Object.keys(UserPermissions).map((key) => ({
    name: UserPermissions[key],
    value: key,
  }));

  const isAuthor = currentUser.id === author.id;
  const isAdminWithSettingsAccess = isCurrentTeamOwnerWithSettingsAccess();
  const authorOwnerLabel =
    isAdminWithSettingsAccess && !isAuthor ? 'Author is the owner of this study' : "I'm the owner of this study";

  return (
    <div className="flex flex-wrap justify-between mw7" data-testid="team-settings__container">
      <div className="w-50 pr3">
        <div className="ui-card">
          <SearchSelect
            selected={selectedUsers}
            setSelected={setSelectedUsers}
            onSelect={handleUserSelect}
            onClearSelection={handleClearSelection}
            onSelectAll={handleSelectAll}
            sections={sections}
            sort
            sortAccessor="name"
          />
        </div>
      </div>
      <div className="w-50 pl3">
        <div className="ui-card overflow-auto h-100" style={{ maxHeight: '508px' }}>
          {author && (
            <div className="pa3" data-testid="team-author-section">
              <div className="flex items-center justify-between pb3">
                <div>
                  <h4 className="near-black f6">{author.name}</h4>
                  <p className="f6">{author.email}</p>
                </div>
                <div>
                  <Label text="Author" type={LabelType.Info} />
                </div>
              </div>
              <div>
                <div className={!isAuthor && !isAdminWithSettingsAccess ? 'ui__disabled' : ''}>
                  <Radio
                    name="setOwner"
                    id="registeringForMe"
                    checked={!registeringOnBehalfActive}
                    onChange={() => {
                      setRegisteringOnBehalfActive(false);
                      setValue('owner', '');
                    }}
                    label={authorOwnerLabel}
                    className="mb2"
                  />
                  <Radio
                    name="setOwner"
                    id="registeringForSomeoneElse"
                    checked={registeringOnBehalfActive}
                    onChange={() => setRegisteringOnBehalfActive(true)}
                    label="I'm registering it for someone else"
                    className="mb2"
                  />
                </div>
                {registeringOnBehalfActive && (
                  <Controller
                    name="owner"
                    control={control}
                    rules={{
                      validate: {
                        ownerCantExistInTeam: (value) =>
                          !doesUserExistInFields(value) || "The owner can't be a team member",
                      },
                    }}
                    render={({ value, onChange }: ControllerRenderProps) => {
                      const canEditOwner = isAuthor || isAdminWithSettingsAccess;
                      return (
                        <>
                          <label>Owner:</label>
                          <ReactSelect
                            className={'db ml4 mt3'}
                            disabled={!canEditOwner}
                            options={teamUsers.map((user) => ({
                              label: user.name,
                              value: user.id,
                              isDisabled: doesUserExistInFields(user.id),
                            }))}
                            defaultValue={getDefaultOwner(value)}
                            onChange={({ value }) => onChange(value)}
                          />

                          <ErrorMessage
                            errors={errors}
                            name="owner"
                            render={({ message }) => <p className="f6 red db pt2">{message}</p>}
                          />
                        </>
                      );
                    }}
                  />
                )}
              </div>
            </div>
          )}
          {fields.map((user, i) => (
            <div
              key={user.keyID}
              className="flex items-center justify-between pa3 bt b--moon-gray hide-child"
              data-testid={`team-user-${user.id}-section`}
            >
              <div>
                {user.name && <h4 className="near-black f6">{user.name}</h4>}
                {user.email && (
                  <p
                    className="f6"
                    data-tooltip-content={user.email.length > 30 ? user.email : ''}
                    data-tooltip-id="global-tooltip-id"
                  >
                    {trunc(user.email, 30)}
                  </p>
                )}
                <input ref={register()} type="hidden" name={`users.${i}.id`} value={user.id} />
                <input ref={register()} type="hidden" name={`users.${i}.name`} value={user.name} />
                <input ref={register()} type="hidden" name={`users.${i}.email`} value={user.email} />
                <div className="flex mt2">
                  {accessLevels.map((access) => (
                    <Controller
                      key={access.value}
                      name={`users.${i}.access`}
                      control={control}
                      className="dib"
                      defaultValue={user.access}
                      render={({ onChange, value, name }: ControllerRenderProps) => (
                        <Radio
                          name={name}
                          id={`${i}_${access.value}`}
                          defaultChecked={value === access.value}
                          onChange={onChange}
                          value={access.value}
                          label={access.name}
                          className="mr3"
                        />
                      )}
                    />
                  ))}
                </div>
              </div>
              <RemoveButton className="child" onClick={() => handleRemoveUser(i)} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
