// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import UserAvatar from '@/components/UI/UserAvatar';
import { errorToast, hasOwnProperty, returnUserFromStudy } from '@/helpers';
import { _isNotEmpty, _notNil } from '@/littledash';
import { ID } from '@/model/Common.model';
import { Observation } from '@/model/Observation.model';
import { Study } from '@/model/Study.model';
import * as Auth from '@/support/auth';
import Http from '@/support/http';
import { api as apiRoutes } from '@/support/route';
import { modalAction } from '@/utils/modal';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import ObservationScore from '@/components/UI/ObservationScore';

interface ObservationsListProps {
  study: Study;
  observations: Array<Observation>;
  handleCallback: (reason_for_change: string) => void;
}

const ObservationsList: FC<ObservationsListProps> = ({ study, observations, handleCallback }) => {
  const [apiError, setApiError] = useState<Error | false>(false);
  const dispatch = useDispatch();
  const { openModal, closeModal } = modalAction(dispatch);

  const confirmDelete = (id: ID, reason_for_change?: string) => {
    return Http.delete(apiRoutes('observations.destroy', { id }), {
      data: _notNil(reason_for_change) ? { reason_for_change } : {},
    })
      .then(() => {
        if (handleCallback) {
          handleCallback('Successfully deleted observation');
          closeModal();
        } else {
          closeModal();
        }
      })
      .catch((error) => {
        setApiError(error);
        errorToast('Failed to delete observation, please try again');
      });
  };

  return (
    <div>
      {apiError && (
        <div className="pa3">
          <ApiErrorBanner error={apiError} />
        </div>
      )}
      {observations.map((o, i) => {
        const dateToDisplay = _isNotEmpty(o.updated_at) ? o.updated_at : o.created_at;
        const user = returnUserFromStudy(study, dateToDisplay.id);
        const userToShow = _isNotEmpty(user) && hasOwnProperty(user, 'name') ? user.name : dateToDisplay.email;

        return (
          <div className={`${i !== 0 ? 'bt b--moon-gray' : ''} flex pa3`} key={o.id}>
            {user && <UserAvatar user={user} tooltip={user.name ?? user.email} />}
            <div className="w-100 pl3">
              <div className="flex items-start pb3">
                <div className="w-100 pr2">
                  <span className="f7 lh-copy db">
                    <DateTimeRenderer value={o.observed_at} />
                  </span>
                  {_notNil(o.updated_at) && (
                    <span className="f7 lh-copy db">
                      Edited <DateTimeRenderer value={o.updated_at.datetime} /> by {userToShow}
                    </span>
                  )}
                  <h3
                    className="f6 link blue basier-reg lh-copy w-80 pt2"
                    onClick={() =>
                      openModal('VIEW_OBSERVATION', {
                        observation: o,
                      })
                    }
                  >
                    {o.title}
                  </h3>
                </div>
                {o.value && <ObservationScore type={o.type} score={o.value} options={o.options} />}
              </div>
              {o.text && (
                <div className="pa3 br3 br--top--left bg-near-white mb3 mw6 dib">
                  <p className="f6 ma0 line-copy">{o.text}</p>
                </div>
              )}
              {Auth.isWriteUserForStudy(study) && (
                <div>
                  <SelectDropDown title="Actions" className="f6 basier-reg pl0 stateless blue" alignMenu="left">
                    <ActionList
                      actions={[
                        {
                          action: () =>
                            openModal('EDIT_OBSERVATION', {
                              observation: o,
                              handleCallback,
                            }),
                          name: 'Edit',
                        },
                        {
                          action: () =>
                            openModal('CONFIRM_DELETE_OBSERVATION', {
                              onClick: (reason_for_change: string) => confirmDelete(o.id, reason_for_change),
                              closeModal,
                            }),
                          name: 'Delete',
                        },
                      ]}
                    />
                  </SelectDropDown>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ObservationsList;
