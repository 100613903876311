import { useCreateDataTableFromTemplateContext } from '@/components/Studies/Templates/CreateDataTable/CreateDataTableFromTemplateProvider.tsx';
import {
  generateDefaultValues,
  generateSourcePresetData,
  generateTargetPresetData,
  measurementLabel,
  MeasurementMapFormContext,
  MeasurementMapFormData,
  MeasurementOutputNode,
  useMapMeasurementsResolver,
} from '@/components/Studies/Templates/CreateDataTable/steps/MapMeasurements.utils.tsx';
import Button from '@/components/UI/Button';
import Header from '@/components/UI/Header';
import Select from '@/components/UI/Select';
import { _isNil, _notNil } from '@/littledash.ts';
import { PickRequired } from '@/model/Common.model.ts';
import { DataTableTemplateColumnApiId, TemplateColumnDataV1 } from '@/model/DataTable.model.ts';
import { FC, useCallback, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form@latest';
import { MeasurementMapping } from '@/components/Studies/Templates/CreateDataTable/CreateDataTableFromTemplate.model.tsx';

type MappedMeasurement = PickRequired<TemplateColumnDataV1, 'api_id' | 'exclude'> & {
  source_slug: string;
  target_slug: string;
  source_label: string;
  target_label: string;
};
export const MapMeasurements: FC = () => {
  const { onSubmit, onCancel, submitButtonText, cancelButtonText, study, state } =
    useCreateDataTableFromTemplateContext();
  const sourcePresetData = useMemo(
    () => generateSourcePresetData(state?.select_template?.template?.columns ?? []),
    [state?.select_template?.template?.columns]
  );
  const targetPresetData = useMemo(
    () => generateTargetPresetData(study?.settings?.calculations ?? []),
    [study?.settings?.calculations]
  );
  const [targetSlugUsedCount, updateTargetSlugUsedCount] = useState(new Map<string, number>());
  const defaultValues = useCallback(
    () =>
      generateDefaultValues(
        [...sourcePresetData.outputs.values(), ...sourcePresetData.inputs.values()],
        targetPresetData.outputFuse,
        targetPresetData.options,
        state.map_measurements.measurementMapping
      ),
    []
  );

  const resolver = useMapMeasurementsResolver(
    useCallback(
      (value: Map<string, number>) => {
        updateTargetSlugUsedCount((prevState) => {
          if (value.size !== prevState.size) {
            return value;
          }
          for (const [slug, count] of value) {
            if (prevState.get(slug) !== count) {
              return value;
            }
          }
          return prevState;
        });
      },
      [updateTargetSlugUsedCount]
    )
  );

  const formMethods = useForm<MeasurementMapFormData, MeasurementMapFormContext>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver,
    context: {
      targetOptions: targetPresetData.options,
      sourceOutputs: sourcePresetData.outputs,
      sourceInputs: sourcePresetData.inputs,
    },
    defaultValues: defaultValues,
  });

  const handleOutputUpdate = useCallback(
    (node: MeasurementOutputNode, previousValue: string | null, value: string | null) => {
      if (previousValue !== value && node.children.size > 0) {
        if (_isNil(value)) {
          // Clear out inputs
          node.children.forEach((inputNode) => {
            formMethods.setValue(inputNode.slug, null, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
          });
        } else {
          // Update inputs with new suggestions
          node.children.forEach((inputNode) => {
            formMethods.setValue(
              inputNode.slug,
              targetPresetData.options
                .get(value ?? '')
                ?.fuse?.search(inputNode.search)
                ?.at(0)?.item?.slug ?? null,
              {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true,
              }
            );
          });
        }
      }
    },
    [formMethods, targetPresetData.options]
  );
  const handleSubmit: SubmitHandler<MeasurementMapFormData> = (formData) => {
    const labelMap: Map<string, string> = new Map(
      Array.from(targetPresetData.options.values()).flatMap<[string, string]>((targetParentNode) => [
        [targetParentNode.slug, targetParentNode.label],
        ...Array.from(targetParentNode.children.values()).map<[string, string]>((targetChildNode) => [
          targetChildNode.slug,
          targetChildNode.label,
        ]),
      ])
    );

    const data: MeasurementMapping = Object.entries(formData).reduce<
      Map<DataTableTemplateColumnApiId, MappedMeasurement>
    >((acc, [source_slug, target_slug]) => {
      if (_notNil(target_slug)) {
        sourcePresetData.slugToColumnIds.get(source_slug)?.forEach((columnId) => {
          const column = sourcePresetData.columns.get(columnId);

          if (_notNil(column)) {
            acc.set(columnId, {
              api_id: columnId,
              source_slug,
              source_label: measurementLabel(column.name, column.unit),
              target_slug,
              target_label: labelMap.get(target_slug) as string,
              exclude: false, //TODO
            });
          }
        });
      }
      return acc;
    }, new Map<DataTableTemplateColumnApiId, MappedMeasurement>());

    onSubmit({ step: 'map_measurements', mapMeasurementsData: { measurementMapping: data } });
  };
  return (
    <>
      <form
        onSubmit={formMethods.handleSubmit(handleSubmit)}
        data-test-component="MapMeasurements"
        data-test-element="form"
      >
        <div className="ph4 pv3">
          <div className="pb5">
            <Header mainHeaderText="Map measurements" />
          </div>
          <div className="flex">
            <label className="w-20 pb3 f6 dark-gray">Template measurements</label>
            <label className="w-80 dark-gray">Study measurements</label>
          </div>
          <div className="pt2 pb4">
            {Array.from(sourcePresetData.outputs.values()).map((outputNode) => (
              <div
                className="pb3 pt3 bt"
                key={outputNode.slug}
                data-test-element="output-container"
                data-test-key={outputNode.slug}
              >
                <div className="flex items-center">
                  <label className="w-20 mb0">{outputNode.label}</label>
                  <div className="flex w-80" data-test-element="output-target-slug">
                    <Controller
                      control={formMethods.control}
                      name={outputNode.slug}
                      render={({ field, fieldState }) => (
                        <>
                          <Select
                            name={outputNode.slug}
                            className="w-30 pr2"
                            value={targetPresetData.options.get(field.value ?? '') ?? null}
                            isMulti={false}
                            isSearchable={false}
                            isClearable={true}
                            options={targetPresetData.outputOptions}
                            isOptionDisabled={(option) =>
                              option.slug !== field.value && (targetSlugUsedCount.get(option.slug) ?? 0) > 0
                            }
                            onChange={(option) => {
                              field.onChange(option?.slug ?? null);
                              handleOutputUpdate(outputNode, field.value, option?.slug ?? null);
                            }}
                            onBlur={field.onBlur}
                          />
                          {fieldState.error && (
                            <p className="red mt2 f6" data-test-element="error-message">
                              {fieldState.error.message}
                            </p>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
                {outputNode.children.size === 0 ? null : (
                  <div className="pt1">
                    {Array.from(outputNode.children.values()).map((inputNode) => (
                      <div
                        key={inputNode.slug}
                        className="flex items-center mt2"
                        data-test-element="input-container"
                        data-test-key={inputNode.slug}
                      >
                        <label className="w-20 pl4">{inputNode.label}</label>
                        <div className="flex w-80" data-test-element="input-target-slug">
                          <Controller
                            control={formMethods.control}
                            name={inputNode.slug}
                            render={({ field, fieldState }) => {
                              const targetOutputSlug = formMethods.getValues(outputNode.slug) ?? '';
                              const value =
                                targetPresetData.options.get(targetOutputSlug)?.children?.get(field.value ?? '') ??
                                null;
                              const options = Array.from(
                                targetPresetData.options.get(targetOutputSlug)?.children?.values() ?? []
                              );
                              return (
                                <>
                                  <Select
                                    className="w-30 pr2"
                                    value={value}
                                    isMulti={false}
                                    isSearchable={false}
                                    isClearable={true}
                                    options={options}
                                    isOptionDisabled={(option) =>
                                      option.slug !== field.value && (targetSlugUsedCount.get(option.slug) ?? 0) > 0
                                    }
                                    onChange={(option) => {
                                      field.onChange(option?.slug ?? null);
                                    }}
                                    onBlur={field.onBlur}
                                  />
                                  {fieldState.error && (
                                    <p className="red mt2 f6" data-test-element="error-message">
                                      {fieldState.error.message}
                                    </p>
                                  )}
                                </>
                              );
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="pt3 b--moon-gray">
            <Button
              submit
              testKey="createDataTableFromTemplateStepFormSubmit"
              testId="map-measurements-submit"
              disabled={!formMethods.formState.isValid}
            >
              {submitButtonText}
            </Button>
            <Button plain className="ml2" onClick={onCancel} testId="select-template-cancel">
              {cancelButtonText}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};
