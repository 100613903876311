import ReasonForChange from '@/components/Shared/ReasonForChange';
import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import { DateTimePickerNative } from '@/components/UI/DateTimePickerNative/DateTimePickerNative';
import Radio from '@/components/UI/FormElements/Radio';
import { newLineText, preventNumberScroll } from '@/helpers';
import type { Observation } from '@/model/Observation.model';
import Http from '@/support/http';
import { api as apiRoutes } from '@/support/route';
import { modalAction } from '@/utils/modal';
import { ErrorMessage } from '@hookform/error-message';
import { FC, useState } from 'react';
import {
  Control,
  Controller,
  ControllerRenderProps,
  DeepMap,
  FieldError,
  FormProvider,
  useForm,
  //@ts-expect-error - old hook form - replace with `react-hook-form@latest
} from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { severityScale } from './ObservationForm';
import { featuresSelector } from '@/support/Selectors';
import { createSelector } from '@reduxjs/toolkit';
import { _isNotEmpty } from '@/littledash.ts';

interface SeverityOptionsProps {
  errors: DeepMap<
    {
      observation: {
        value: string;
      };
    },
    FieldError
  >;
  control: Control;
}

const SeverityOptions: FC<SeverityOptionsProps> = ({ errors, control }) => (
  <div className="ph4 mb4">
    <Controller
      name="observation.value"
      control={control}
      render={({ onChange, value }: ControllerRenderProps) => (
        <div className="mt3">
          <label htmlFor="severityValue">Select a severity</label>
          {severityScale.map((severity) => (
            <Radio
              key={severity}
              checked={value === severity}
              onChange={onChange}
              id={severity}
              name="severityValue"
              label={severity}
              value={severity}
              className="mb2"
            />
          ))}
        </div>
      )}
    />
    <ErrorMessage
      errors={errors}
      name="observation.value"
      render={({ message }) => <p className="f6 red db pt2">{message}</p>}
    />
  </div>
);

interface NumericScaleProps extends SeverityOptionsProps {
  observation: Observation;
}

const NumericScale: FC<NumericScaleProps> = ({ observation, errors, control }) => (
  <div className="ph4 mb4">
    <Controller
      name="observation.value"
      control={control}
      rules={{
        required: 'Severity score cannot be blank',
        validate: {
          numericScaleValidation: (value: string) =>
            Number(value) < Number(observation.options.min) || Number(value) > Number(observation.options.max)
              ? `Severity score must be a number and between ${observation.options.min}-${observation.options.max}`
              : true,
        },
      }}
      render={({ onChange, value }: ControllerRenderProps) => (
        <div className="mt3">
          <label htmlFor="numericScaleValue">
            Severity rating {observation.options && `(${observation.options.min}-${observation.options.max})`}
          </label>
          <input
            type="number"
            onWheel={preventNumberScroll}
            name="numericScaleValue"
            className={`mb0 ${errors.observation?.value ? 'input__error' : ''}`}
            style={{ marginBottom: 0, width: '125px' }}
            min={observation.options && observation.options.min}
            max={observation.options && observation.options.max}
            value={value}
            onChange={onChange}
          />
        </div>
      )}
    />
    <ErrorMessage
      errors={errors}
      name="observation.value"
      render={({ message }) => <p className="f6 red db pt2">{message}</p>}
    />
  </div>
);

interface EditObservationProps {
  observation: Observation;
  handleCallback: () => void;
}

interface EditObservationSubmitFormProps {
  observed_at: string;
  observation: Observation;
  reason_for_change?: string;
}

const featureSelector = createSelector([featuresSelector], (features) => ({
  reasonForChange: features?.reason_for_change ?? false,
}));

export const EditObservation: FC<EditObservationProps> = ({ observation, handleCallback }) => {
  const [submissionError, setSubmissionError] = useState<Error>();
  const dispatch = useDispatch();
  const { closeModal } = modalAction(dispatch);
  const observationId = observation.id;

  const { reasonForChange } = useSelector(featureSelector);

  const formMethods = useForm({
    defaultValues: {
      observed_at: observation.observed_at,
      observation,
      reason_for_change: '',
    },
  });

  const {
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;

  const content: Record<string, JSX.Element> = {
    none: <></>,
    numeric: <NumericScale observation={observation} errors={errors} control={control} />,
    severity: <SeverityOptions errors={errors} control={control} />,
  };

  const onSubmit = async ({ observation, observed_at, reason_for_change }: EditObservationSubmitFormProps) => {
    try {
      const payload = { ...observation, observed_at, reason_for_change };
      await Http.patch(apiRoutes('observations.update', { id: observationId }), payload);
      handleCallback();
      closeModal();
    } catch (error) {
      setSubmissionError(error as Error);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="center mv3 pv4 bg-white br2 shadow-4" style={{ maxWidth: '480px' }}>
          <div className="mb3 ph4">
            <h3 className="normal lh-title near-black basier-med f4">{observation.title}</h3>
            <p className="lh-copy f5">
              <DateTimeRenderer value={observation.observed_at} />
            </p>
            {submissionError && (
              <Banner critical dismiss={false} className="mw6 mv3">
                <h3 className="f5 normal lh-title pb2">There was an error with your submission</h3>
                <p className="f6 pb3 lh-copy">
                  An error has occurred when submitting the form, please try again later. If this keeps occurring please
                  contact support.
                </p>
                <Button outline critical url={'mailto:support@benchling.com'}>
                  Contact support
                </Button>
              </Banner>
            )}
          </div>
          <div className="ph4 pt2">
            <Controller
              name="observed_at"
              control={control}
              render={({ onChange, value }: ControllerRenderProps) => (
                <>
                  <label htmlFor="observed_at">Date observed</label>
                  <DateTimePickerNative value={value} onChange={onChange} />
                </>
              )}
            />
          </div>
          {_isNotEmpty(observation.grading_info) && (
            <div className="ph4">
              <label htmlFor="text">{newLineText(observation.grading_info)}</label>
            </div>
          )}
          {content[observation.type]}
          <div className="ph4">
            <Controller
              name="observation.text"
              control={control}
              render={({ onChange, value }: ControllerRenderProps) => (
                <div className="mt3">
                  <label htmlFor="text">Additional comments</label>
                  <textarea
                    maxLength={5000}
                    style={{ width: '29em' }}
                    onChange={onChange}
                    name="text"
                    cols={30}
                    rows={10}
                    value={value}
                  />
                </div>
              )}
            />
          </div>
          {reasonForChange && (
            <div className="ph4">
              <ReasonForChange />
            </div>
          )}
          <div className="ph4 pt3">
            <Button disabled={isSubmitting} onClick={handleSubmit(onSubmit)}>
              {isSubmitting ? 'Saving...' : 'Save'}
            </Button>
            <Button className="ml3" plain onClick={closeModal}>
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default EditObservation;
