import { Study } from '@/model/Study.model.ts';
import {
  DataTableCreateFromTemplateRequestV1,
  DataTableTemplateColumnType,
  DataTableTemplateV1,
  TemplateColumnDataV1,
} from '@/model/DataTable.model.ts';
import { ISODateTime } from '@/model/Common.model.ts';
import { format, formatISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { DateUtils } from '@/utils/Date.utils.ts';
import {
  CreateDataTableFromTemplateFormState,
  ReferenceDateColumnGroup,
  ReferenceDateFormState,
  SelectAnimalsFormState,
  SelectTemplateFormState,
  TemplateColumn,
} from '@/components/Studies/Templates/CreateDataTable/CreateDataTableFromTemplate.model.tsx';
import { GlossaryApiId } from '@/model/Metadata.model.ts';
import { _notNil } from '@/littledash.ts';
import { Reducer } from 'react';

export type CreateDataTableFromTemplateFormActions =
  | {
      type: 'initialize-form';
      select_template: CreateDataTableFromTemplateFormState['select_template'];
      map_measurements: CreateDataTableFromTemplateFormState['map_measurements'];
      confirm_dates: CreateDataTableFromTemplateFormState['confirm_dates'];
      select_animals: CreateDataTableFromTemplateFormState['select_animals'];
      generic_state: CreateDataTableFromTemplateFormState['generic_state'];
    }
  | { type: 'template-update'; data: SelectTemplateFormState }
  | {
      type: 'map-measurements-update';
      map_measurements: CreateDataTableFromTemplateFormState['map_measurements'];
    }
  | { type: 'confirm-dates-update'; data: ReferenceDateFormState }
  | { type: 'select-animals-update'; data: SelectAnimalsFormState };

export const reducer: Reducer<CreateDataTableFromTemplateFormState, CreateDataTableFromTemplateFormActions> = (
  prevState,
  action
) => {
  switch (action.type) {
    case 'initialize-form': {
      return {
        select_template: action.select_template,
        map_measurements: action.map_measurements,
        confirm_dates: action.confirm_dates,
        select_animals: action.select_animals,
        generic_state: action.generic_state,
      };
    }
    case 'template-update': {
      return { ...prevState, select_template: action.data };
    }
    case 'map-measurements-update': {
      return {
        ...prevState,
        map_measurements: action.map_measurements,
      };
    }
    case 'confirm-dates-update': {
      return { ...prevState, confirm_dates: action.data };
    }
    case 'select-animals-update': {
      return { ...prevState, select_animals: action.data };
    }
    default:
      return prevState;
  }
};

export const initializer = (): CreateDataTableFromTemplateFormState => ({
  select_template: {
    data_table_name: '',
    template: undefined,
  },
  map_measurements: {
    measurementMapping: new Map([]),
  },
  confirm_dates: {
    referenceDateColumnGroups: [],
    studyReferenceDate: '',
  },
  select_animals: {
    animals: [],
  },
  generic_state: {
    columns: [],
    mappingRequired: false,
    containsObservations: false,
  },
});

// Initializes util and form input data on template select submit
export const initializeFormData = (
  selectTemplateData: SelectTemplateFormState,
  study: Partial<Study>
): CreateDataTableFromTemplateFormState => {
  const templateColumns: TemplateColumn[] = formatTemplateColumns(selectTemplateData.template) ?? [];

  const mappingRequired = templateColumns?.some(
    (column) => column.type === 'measurement_input' || column.type === 'measurement_output'
  );
  const containsObservations = templateColumns?.some((column) => column.type === 'observation');

  const studyStartISO = formatISO(toZonedTime(`${study.started_on}T00:00:00`, study?.config?.timezone ?? ''));

  const referenceDateColumns =
    mappingRequired || containsObservations ? initializeReferenceDates(templateColumns, studyStartISO) : [];

  return {
    select_template: {
      data_table_name: selectTemplateData.data_table_name ?? '',
      template: selectTemplateData.template,
    },
    map_measurements: {
      measurementMapping: new Map([]),
    },
    confirm_dates: {
      referenceDateColumnGroups: referenceDateColumns,
      studyReferenceDate: format(studyStartISO, 'yyyy-MM-dd'),
    },
    select_animals: {
      animals: [],
    },
    generic_state: {
      columns: templateColumns ?? [],
      mappingRequired: mappingRequired,
      containsObservations: containsObservations,
    },
  };
};

export const formatTemplateColumns = (template?: DataTableTemplateV1): TemplateColumn[] => {
  const templateColumns = template?.columns?.map((column) => {
    const baseColumn = {
      api_id: column.api_id,
      exclude: false,
      mappedSlug: '',
      date: undefined,
      type: column.type,
    };

    switch (column.type) {
      case 'measurement_input':
        return {
          ...baseColumn,
          slug: column.slug ?? '',
          columnName: column.name + ' (' + column.unit + ')',
          measurementOutputApiId: column.measurement_output_api_id,
        };
      case 'measurement_output':
        return {
          ...baseColumn,
          slug: column.slug ?? '',
          unit: column.unit,
          dateOffset: column.reference_date_offset ?? null,
          columnName: column.name + ' (' + column.unit + ')',
        };
      case 'observation':
        return {
          ...baseColumn,
          dateOffset: column.reference_date_offset ?? null,
          columnName: column.glossary.title,
          observationType: column.observation_type,
        };
      default:
        return {
          ...baseColumn,
          columnName: column.name,
        };
    }
  });

  return _notNil(templateColumns) ? templateColumns : [];
};

// Initializes the ReferenceDateColumnGroups for date assignment inputs
export const initializeReferenceDates = (
  columns: TemplateColumn[],
  studyStartISO: ISODateTime
): ReferenceDateColumnGroup[] => {
  const studyStartDate = format(studyStartISO, 'yyyy-MM-dd');
  return columns.reduce<ReferenceDateColumnGroup[]>((acc, column) => {
    // Check if the column type is relevant
    if (!['measurement_output', 'observation'].includes(column.type)) {
      return acc; // Skip irrelevant columns
    }

    const referenceDate = {
      columnApiId: column.api_id,
      glossaryApiId: (column.glossaryApiId ?? '') as GlossaryApiId,
      referenceDateOffset: column.dateOffset ?? '',
      date: DateUtils.addOffsetToISODate(studyStartDate, column.dateOffset ?? ''),
    };

    const groupInfo = {
      mappedColumnName: undefined,
      type: column.type === 'measurement_output' ? column.type : column.observationType,
      columnName: column.columnName,
    };

    // Find an existing group by columnName and type
    const existingGroup = acc.find(
      (group) => group.columnName === groupInfo.columnName && group.type === groupInfo.type
    );

    if (existingGroup) {
      // Add the referenceDate to the group's array
      existingGroup.referenceDateColumns.push(referenceDate);
    } else {
      // Create a new group and add it to the accumulator
      acc.push({
        columnName: groupInfo.columnName ?? '',
        type: groupInfo.type as DataTableTemplateColumnType,
        referenceDateColumns: [referenceDate],
      });
    }

    return acc;
  }, []);
};

export const createFormPayload = (
  stepFormData: CreateDataTableFromTemplateFormState
): DataTableCreateFromTemplateRequestV1 => {
  const payloadColumns: TemplateColumnDataV1[] = stepFormData.generic_state.columns.map((column) => {
    const referenceColumnGroup = stepFormData.confirm_dates.referenceDateColumnGroups.find((refColumnGroup) => {
      return refColumnGroup.columnName === column.columnName;
    });
    return {
      api_id: column.api_id,
      slug: stepFormData.map_measurements.measurementMapping.get(column.api_id)?.target_slug ?? '',
      date:
        referenceColumnGroup?.referenceDateColumns?.find((refColumn) => refColumn.columnApiId === column.api_id)
          ?.date ?? '',
      exclude: false,
    };
  });

  return {
    name: stepFormData.select_template.data_table_name,
    columns: payloadColumns,
    rows: stepFormData.select_animals.animals,
  };
};
