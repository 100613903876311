import { useForm, Controller, FormProvider } from 'react-hook-form@latest';
import Button from '@/components/UI/Button';
import { FormField } from '@/components/UI/FormFields/Latest';
import { TemplateLookup } from '@/components/Studies/Templates/CreateDataTable/TemplateLookup.tsx';
import Header from '@/components/UI/Header';
import { getRegisterConfig } from '@/components/UI/FormFields/FormField.utils.ts';
import { useCreateDataTableFromTemplateContext } from '@/components/Studies/Templates/CreateDataTable/CreateDataTableFromTemplateProvider.tsx';
import { SelectTemplateFormState } from '@/components/Studies/Templates/CreateDataTable/CreateDataTableFromTemplate.model.tsx';
import { FC } from 'react';

export const SelectTemplate: FC = () => {
  const { onSubmit, onCancel, submitButtonText, cancelButtonText, study, state } =
    useCreateDataTableFromTemplateContext();

  const formMethods = useForm<SelectTemplateFormState>({
    defaultValues: {
      data_table_name: state.select_template.data_table_name,
      template: state.select_template.template,
    },
    mode: 'onChange',
  });

  const handleTemplateSubmit = (data: SelectTemplateFormState) => {
    onSubmit({
      step: 'select_template',
      templateData: { data_table_name: data.data_table_name, template: data.template },
    });
  };

  return (
    <>
      <div className="ph4 pv3">
        <div>
          <Header mainHeaderText="Create using template" />
        </div>
        <FormProvider {...formMethods}>
          <form
            onSubmit={formMethods.handleSubmit(handleTemplateSubmit)}
            data-test-component="SelectTemplate"
            data-test-element="form"
          >
            <div className="pt4">
              <div
                className="w-30"
                data-test-component="SelectTemplate"
                data-test-element="select-template-title-container"
              >
                <FormField name="data_table_name" label="Name">
                  <input
                    type="text"
                    data-test-component="Text"
                    data-test-element="input"
                    style={{ marginBottom: 0 }}
                    className={formMethods.formState.errors?.['data_table_name'] ? 'input__error' : ''}
                    {...formMethods.register('data_table_name', getRegisterConfig({ required: true, maxLength: 250 }))}
                    placeholder={'Data Table Name'}
                    data-testid={'data-table-name-input'}
                  />
                </FormField>
              </div>
              <div
                className="w-30 pt3"
                data-test-component="Select-Template"
                data-test-element="select-template-lookup"
              >
                <FormField name="template" label="Template">
                  <Controller
                    name="template"
                    control={formMethods.control}
                    rules={{
                      validate: (value) => (value && Object.keys(value).length > 0) || 'Template cannot be empty',
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TemplateLookup value={value} onChange={onChange} onBlur={onBlur} study={study} />
                    )}
                  />
                </FormField>
              </div>
            </div>
            <div className="pt5 b--moon-gray">
              <Button
                submit
                testKey="createDataTableFromTemplateStepFormSubmit"
                testId="select-template-submit"
                disabled={!formMethods.formState.isValid}
              >
                {submitButtonText}
              </Button>
              <Button plain className="ml2" onClick={onCancel} testId="select-template-cancel">
                {cancelButtonText}
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </>
  );
};
