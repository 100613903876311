// @ts-nocheck: converted from JS

import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import Filters from '@/components/UI/Filters';
import Icon from '@/components/UI/Icon';
import Label from '@/components/UI/Label';
import Link from '@/components/UI/Link';
import SearchFilterBar from '@/components/UI/SearchFilterBar';
import TableFilterMenu from '@/components/UI/SelectDropDown/Menus/TableFilterMenu';
import Table from '@/components/UI/Table';
import Pagination from '@/components/UI/Table/Pagination';
import { filterDataByFilters, filterDataBySearch } from '@/components/UI/Table/Table.utils.ts';
import UserAvatar from '@/components/UI/UserAvatar';
import { FilterTypes as filterType } from '@/constants/FilterTypes';
import { returnUserFromStudy } from '@/helpers';
import { _isEmpty, _isNotBlank, _isNotEmpty, _notNil } from '@/littledash';
import { web as webRoute } from '@/support/route';
import { sortDescByProperty } from '@/utils/array.ts';
import { DateUtils } from '@/utils/Date.utils';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import './TaskTable.scss';
import { LabelType } from '@/components/UI/Label/Label.tsx';

const status = [
  {
    value: 'incomplete',
    name: 'Incomplete',
  },
  {
    value: 'in_progress',
    name: 'In progress',
  },
  {
    value: 'complete',
    name: 'Completed',
  },
];

const filterOptions = [
  {
    value: 'status',
    name: 'Status',
    operations: [
      {
        name: filterType.eq,
        value: filterType.eq,
        options: status,
      },
      {
        name: filterType.ne,
        value: filterType.ne,
        options: status,
      },
    ],
  },
];

/**
 *
 * @param {('incomplete'|'in_progress'|'complete')} status
 * @returns {{label: string, class: string}}
 */
const getTrafficLight = (status) => {
  switch (status) {
    case 'complete': {
      return { class: 'green', label: 'Complete' };
    }
    case 'in_progress': {
      return { class: 'yellow', label: 'In progress' };
    }
    case 'incomplete': {
      return { class: 'red', label: 'Incomplete' };
    }
    default: {
      return { class: 'red', label: 'Incomplete' };
    }
  }
};

const TaskTable = ({ tasks, updateTask, deleteTask }) => {
  const [settings, setSettings] = useState({
    rows: 25,
    page: 0,
  });
  const [searchQuery, setQuery] = useState('');
  const [filterMatch, setFilterMatch] = useState(true);
  const [filters, setFilters] = useState([]);

  const dispatch = useDispatch();

  const data = useMemo(() => {
    let result = [...(tasks ?? [])];

    if (_isNotEmpty(filters)) {
      result = filterDataByFilters(result.sort(sortDescByProperty('id')), filters, filterMatch, filterOptions);
    }
    if (_isNotBlank(searchQuery)) {
      result = filterDataBySearch(result, searchQuery);
    }
    return result;
  }, [tasks, filters, searchQuery, filterMatch]);

  const onUpdateFilters = (filters, filterMatch) => {
    setFilters(filters);
    setFilterMatch(filterMatch);
    if (!_isEmpty(filters) && settings.page !== 0) {
      const currentSettings = { ...settings };
      currentSettings.page = 0;
      setSettings(currentSettings);
    }
  };

  const openModal = (modal, props) => {
    dispatch({
      type: 'OPEN_MODAL',
      modal,
      props,
    });
  };

  const closeModal = () => {
    dispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const Filter = () => (
    <TableFilterMenu
      filterOptions={filterOptions}
      filters={filters}
      onUpdateFilters={onUpdateFilters}
      filterMatch={filterMatch}
    />
  );

  const columns = [
    {
      accessor: 'description',
      id: 'task',
      Header: 'Task',
      Cell: ({ row: { original } }) => (
        <div className="flex flex-wrap items-center">
          {original.task_template_id !== null && (
            <span
              data-tooltip-id="global-tooltip-id"
              data-tooltip-content="Generated by a template"
              className="mr3 dib gray relative"
              style={{ top: '2px' }}
            >
              <Icon icon="tray" width="15" height="15" viewBox="0 0 43 32" />
            </span>
          )}
          <a
            className={'link blue'}
            onClick={() =>
              openModal('VIEW_TASK', {
                task: original,
                updateTask: updateTask,
                openModal: openModal,
                deleteTask: deleteTask,
                closeModal,
              })
            }
          >
            {original.description}
          </a>
        </div>
      ),
    },
    {
      accessor: 'status',
      id: 'status',
      Header: 'Status',
      Cell: ({ row: { original } }) => {
        const trafficLight = getTrafficLight(original.status);
        return (
          <div style={{ minWidth: '150px' }} className={`f6 pa2 br2 text-black ${trafficLight.class}`}>
            {trafficLight.label}
          </div>
        );
      },
    },
    {
      accessor: 'last_updated_by.name',
      id: 'assigned',
      Header: 'Assigned',
      Cell: ({ row: { original } }) => {
        const user = returnUserFromStudy(original.study, original.last_updated_by.id);
        if (user) {
          return <UserAvatar user={user} tooltip={user.name ?? user.email} />;
        }
        return false;
      },
    },
    {
      accessor: 'last_updated_at',
      id: 'updatedAt',
      Header: 'Updated at',
      Cell: ({
        row: {
          original: { last_updated_at: lastUpdated },
        },
      }) => <DateTimeRenderer value={lastUpdated} defaultResponse="-" />,
    },
    {
      id: 'study',
      Header: 'Study',
      Cell: ({
        row: {
          original: { study },
        },
      }) => (
        <span>
          <Link className="link blue" style={{ whiteSpace: 'normal' }} to={webRoute('studies.show', study.id)}>
            {study.name}
          </Link>
          {_notNil(study?.archived_at) ? (
            <Label
              text="Completed"
              tooltip={`This study was completed on ${DateUtils.renderDateTime(study.archived_at)}`}
              type={LabelType.Info}
              className="ml2"
            />
          ) : _notNil(study?.canceled_at) ? (
            <Label
              text="Cancelled"
              tooltip={`This study was cancelled on ${DateUtils.renderDateTime(study.canceled_at)}`}
              type={LabelType.Info}
              className="ml2"
            />
          ) : undefined}
        </span>
      ),
    },
  ];

  const TablePagination = (props) => <Pagination {...props} settings={settings} updateSettings={setSettings} />;

  return (
    <div className="ui-card">
      <div className="pa3">
        <SearchFilterBar
          FilterMenu={Filter}
          searchPlaceholderText="Search by task"
          handleSearchInput={setQuery}
          searchQuery={searchQuery}
        />
        {!_isEmpty(filters) && <Filters filters={filters} updateFilters={onUpdateFilters} filterMatch={filterMatch} />}
      </div>
      <div className="ui__table ui__tasks-table">
        <Table
          data={data}
          columns={columns}
          noDataText="No tasks for this day"
          defaultPageSize={settings.rows}
          page={settings.page}
          minRows={data.length > 1 ? 0 : 3}
          pageSize={settings.rows}
          resizable={false}
          PaginationComponent={TablePagination}
        />
      </div>
    </div>
  );
};
export default TaskTable;
