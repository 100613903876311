import { _isEmptyString, _isEmpty, _notNil, _isNotEmpty } from '@/littledash.ts';
import {
  DataTableTemplateColumnApiId,
  DataTableTemplateColumnV1,
  DataTableTemplateMeasurementColumnV1,
  DataTableTemplateMeasurementInputColumnV1,
  DataTableTemplateMeasurementOutputColumnV1,
} from '@/model/DataTable.model.ts';
import { PresetCalculation } from '@/model/PresetCalculation.model';
import Fuse from 'fuse.js';
import { useCallback } from 'react';
import { FieldErrors, Resolver } from 'react-hook-form@latest';
import { MeasurementMapping } from '@/components/Studies/Templates/CreateDataTable/CreateDataTableFromTemplate.model.tsx';

export type MeasurementInputNode = {
  type: 'input';
  slug: string;
  name: string;
  unit: string;
  label: string;
  search: string;
  parent: MeasurementOutputNode;
};
export type MeasurementOutputNode = {
  type: 'output';
  slug: string;
  name: string;
  unit: string;
  label: string;
  search: string;
  children: Map<string, MeasurementInputNode>;
};
export type MeasurementNode = MeasurementOutputNode | MeasurementInputNode;
export type MeasurementFuse = { slug: string; name: string };
export type MeasurementInputOption = {
  type: 'input';
  label: string;
  value: string;
  slug: string;
  name: string;
  unit: string;
  parent: MeasurementOutputOption;
};
export type MeasurementOutputOption = {
  type: 'output';
  label: string;
  value: string;
  slug: string;
  name: string;
  unit: string;
  fuse?: Fuse<MeasurementFuse>;
  children: Map<string, MeasurementInputOption>;
};

export type SourcePresetData = {
  columns: Map<DataTableTemplateColumnApiId, DataTableTemplateMeasurementColumnV1>;
  outputs: Map<string, MeasurementOutputNode>;
  inputs: Map<string, MeasurementInputNode>;
  slugToColumnIds: Map<string, Set<DataTableTemplateColumnApiId>>;
};
export type TargetPresetData = {
  options: Map<string, MeasurementOutputOption>;
  outputOptions: Array<MeasurementOutputOption>;
  outputFuse: Fuse<MeasurementFuse>;
};
export type MeasurementMapFormData = Record<string, string | null>;
export type MeasurementMapFormContext = {
  targetOptions: TargetPresetData['options'];
  sourceOutputs: Map<string, MeasurementOutputNode>;
  sourceInputs: Map<string, MeasurementInputNode>;
};

const measurementFuseSearchName = (slug: string, name: string, unit: string | null | undefined) =>
  _isEmptyString(unit ?? '') ? `${slug} - ${name}` : `${slug} - ${name} (${unit})`;
export const measurementLabel = (name: string, unit: string | null | undefined): string =>
  _isEmptyString(unit ?? '') ? name : `${name} (${unit})`;

export const generateTargetPresetData = (calculations: Array<PresetCalculation>): TargetPresetData => {
  const data = calculations.reduce(
    (acc, calculation) => {
      const children = new Set<string>();
      const inputNames: Array<MeasurementFuse> = [];
      const option: MeasurementOutputOption = {
        type: 'output',
        slug: calculation.id,
        value: calculation.id,
        name: calculation.name,
        unit: calculation.unit ?? '',
        label: measurementLabel(calculation.name, calculation.unit),
        children: new Map<string, MeasurementInputOption>(),
      };
      calculation.measurements
        ?.filter((m) => m.id !== calculation.id)
        ?.forEach((input) => {
          const child: MeasurementInputOption = {
            type: 'input',
            slug: input.id,
            name: input.name,
            unit: input.unit ?? '',
            label: measurementLabel(input.name, input.unit),
            value: input.id,
            parent: option,
          };
          option.children.set(input.id, child);
          children.add(input.id);
          inputNames.push({ slug: input.id, name: measurementFuseSearchName(input.id, input.name, input.unit) });
        });
      acc.outputNames.push({
        slug: calculation.id,
        name: measurementFuseSearchName(calculation.id, calculation.name, calculation.unit),
      });
      if (inputNames.length > 0) {
        option.fuse = new Fuse(inputNames, {
          threshold: 0.25,
          isCaseSensitive: true,
          includeScore: true,
          ignoreLocation: true,
          shouldSort: true,
          keys: [{ name: ['name'] }],
        });
      }
      acc.options.set(calculation.id, option);
      return acc;
    },
    {
      options: new Map<string, MeasurementOutputOption>(),
      labels: new Map<string, string>(),
      outputNames: [] as Array<MeasurementFuse>,
    }
  );
  return {
    options: data.options,
    outputOptions: Array.from(data.options.values()),
    outputFuse: new Fuse(data.outputNames, {
      threshold: 0.25,
      isCaseSensitive: true,
      includeScore: true,
      ignoreLocation: true,
      shouldSort: true,
      keys: [{ name: ['name'] }],
    }),
  };
};
export const generateSourcePresetData = (columns: Array<DataTableTemplateColumnV1>): SourcePresetData => {
  const data = columns.reduce(
    (acc, column) => {
      if (column.type === 'measurement_output' || column.type === 'measurement_input') {
        acc.slugToColumnIds.set(
          column.slug,
          acc.slugToColumnIds.has(column.slug)
            ? (acc.slugToColumnIds.get(column.slug)?.add(column.api_id) as Set<DataTableTemplateColumnApiId>)
            : new Set([column.api_id])
        );
        acc.columns.set(column.api_id, column);
      }
      return acc;
    },
    {
      slugToColumnIds: new Map<string, Set<DataTableTemplateColumnApiId>>(),
      columns: new Map<
        DataTableTemplateColumnApiId,
        DataTableTemplateMeasurementInputColumnV1 | DataTableTemplateMeasurementOutputColumnV1
      >(),
    }
  );

  const { outputs, inputs } = Array.from(data.columns.values())
    .sort((a, b) => (a.type === 'measurement_output' ? -1 : 1))
    .reduce(
      (acc, column) => {
        if (column.type === 'measurement_output' && !acc.outputs.has(column.slug)) {
          acc.outputs.set(column.slug, {
            type: 'output',
            slug: column.slug,
            name: column.name,
            unit: column.unit as string,
            label: measurementLabel(column.name, column.unit),
            search: measurementFuseSearchName(column.slug, column.name, column.unit),
            children: new Map<string, MeasurementInputNode>(),
          });
          return acc;
        }
        if (column.type === 'measurement_input' && !acc.inputs.has(column.slug)) {
          const parentSlug = data.columns.get(column.measurement_output_api_id)?.slug as string;
          const parent = acc.outputs.get(parentSlug) as MeasurementOutputNode;
          const input: MeasurementInputNode = {
            type: 'input',
            slug: column.slug,
            name: column.name,
            unit: column.unit ?? '',
            label: measurementLabel(column.name, column.unit),
            search: measurementFuseSearchName(column.slug, column.name, column.unit),
            parent,
          };
          parent.children.set(column.slug, input);
          acc.inputs.set(column.slug, input);
          return acc;
        }
        return acc;
      },
      {
        outputs: new Map<string, MeasurementOutputNode>(),
        inputs: new Map<string, MeasurementInputNode>(),
      }
    );

  return { ...data, outputs, inputs };
};

export const generateDefaultValues = async (
  sourceNodes: Array<MeasurementNode>,
  outputFuse: Fuse<MeasurementFuse>,
  options: Map<string, MeasurementOutputOption>,
  mappedMeasurements: MeasurementMapping
): Promise<Record<string, string | null>> => {
  if (mappedMeasurements.size > 0) {
    const values: Record<string, string | null> = {};

    mappedMeasurements.forEach((node) => {
      values[node.source_slug] = node.target_slug ?? null;
    });
    return values;
  }
  return sourceNodes.reduce<Record<string, string | null>>((acc, node) => {
    if (node.type === 'output') {
      return { ...acc, [node.slug]: outputFuse.search(node.slug)?.at(0)?.item?.slug ?? null };
    }
    if (node.type === 'input') {
      const parentTargetSlug = acc?.[node.parent.slug];
      if (_notNil(parentTargetSlug)) {
        return {
          ...acc,
          [node.slug]: options.get(parentTargetSlug)?.fuse?.search(node.slug)?.at(0)?.item?.slug ?? null,
        };
      }
    }
    return acc;
  }, {});
};

export const useMapMeasurementsResolver = (
  slugCountUpdate: (value: Map<string, number>) => void
): Resolver<MeasurementMapFormData, MeasurementMapFormContext> => {
  return useCallback(
    (values, context, options) => {
      const slugCounts = Object.values(values).reduce(
        (acc, slug) => (_notNil(slug) ? acc.set(slug, (acc.get(slug) ?? 0) + 1) : acc),
        new Map<string, number>()
      );
      const errors: FieldErrors<MeasurementMapFormData> = {};
      context?.sourceOutputs?.forEach((outputNode) => {
        if (_isEmptyString(values?.[outputNode.slug] ?? '')) {
          errors[outputNode.slug] = { type: 'required', message: `Measurement mapping missing` };
        } else if ((slugCounts.get(values?.[outputNode.slug] ?? '') ?? 0) > 1) {
          errors[outputNode.slug] = { type: 'non-unique', message: `Measurement mapping non-unique` };
        }
        const sourceInputCount = outputNode.children.size;
        const targetNode = context?.targetOptions.get(values?.[outputNode.slug] ?? '');
        if (_notNil(targetNode) && sourceInputCount !== targetNode.children.size) {
          errors[outputNode.slug] = {
            type: 'input-mismatch',
            message: `Source must have the same number of inputs as the target '${sourceInputCount} → ${targetNode.children.size}'`,
          };
        }
        outputNode.children.forEach((inputNode) => {
          if (_isEmptyString(values?.[inputNode.slug] ?? '')) {
            errors[inputNode.slug] = { type: 'required', message: `Measurement variable mapping missing` };
          } else if ((slugCounts.get(values?.[inputNode.slug] ?? '') ?? 0) > 1) {
            errors[inputNode.slug] = { type: 'non-unique', message: `Measurement variable mapping non-unique` };
          }
        });
      });

      slugCountUpdate(slugCounts);
      return Object.keys(errors).length === 0 ? { values, errors: {} } : { values: {}, errors };
    },
    [slugCountUpdate]
  );
};
