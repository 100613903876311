import { Analysis } from '@/components/Studies/Groups/Analysis/Analysis';
import type { GraphsDispatch, GraphsState } from '@/components/Studies/Groups/Graph.model';
import { DailyPlot, LineChartErrorBars, SpiderPlot, SurvivalCurve } from '@/components/Studies/Groups/Graphs';
import GroupsTables from '@/components/Studies/Groups/GroupsTables';
import Toolbar from '@/components/Studies/Groups/Toolbar';
import type { Study } from '@/model/Study.model';
import type { FC } from 'react';

interface GroupsPanelsProps {
  study: Study;
  state: GraphsState;
  dispatch: GraphsDispatch;
  fetchMetrics: () => Promise<void>;
}

export const GroupsPanels: FC<GroupsPanelsProps> = ({ study, state, dispatch, fetchMetrics }) => {
  switch (state.activePanel) {
    case 'grouped': {
      return (
        <>
          <Toolbar state={state} dispatch={dispatch} calculations={study.settings.calculations} />
          <LineChartErrorBars
            state={state}
            id={study.id}
            studyName={study.name}
            errorDeviation={study.error_deviation}
            xAxis={study.settings.graph_settings ?? 'tracking_date'}
          />
          <GroupsTables metrics={state.metrics} study={study} fetchMetrics={fetchMetrics} />
        </>
      );
    }
    case 'spider': {
      return (
        <>
          <Toolbar state={state} dispatch={dispatch} calculations={study.settings.calculations} />
          <SpiderPlot state={state} id={study.id} studyName={study.name} />
          <GroupsTables metrics={state.metrics} study={study} fetchMetrics={fetchMetrics} />
        </>
      );
    }
    case 'daily': {
      return (
        <>
          <Toolbar state={state} dispatch={dispatch} calculations={study.settings.calculations} />
          <DailyPlot state={state} id={study.id} studyName={study.name} errorDeviation={study.error_deviation} />
          <GroupsTables metrics={state.metrics} study={study} fetchMetrics={fetchMetrics} />
        </>
      );
    }
    case 'survival': {
      return (
        <>
          <Toolbar state={state} dispatch={dispatch} calculations={study.settings.calculations} />
          <SurvivalCurve
            studyName={study.name}
            graphSettings={study.settings?.graph_settings ?? 'tracking_date'}
            state={state}
          />
          <GroupsTables metrics={state.metrics} study={study} fetchMetrics={fetchMetrics} />
        </>
      );
    }
    case 'analysis': {
      return <Analysis studyApiId={study.api_id} preset={study.settings} />;
    }
  }
  return null;
};
