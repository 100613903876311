import { _notNil } from '@/littledash';
import Fuse from 'fuse.js';
import type { IDField, ImportRowMatch } from '../../Importer.utils';

export const initialMatches = (names: Array<string>, fields: Array<IDField>): Record<string, ImportRowMatch> => {
  const fieldsFuse = new Fuse(fields, {
    threshold: 0.25,
    isCaseSensitive: true,
    includeScore: true,
    ignoreLocation: true,
    shouldSort: true,
    keys: [{ name: ['name'] }, { name: ['key'] }],
  });
  return names.reduce<Record<string, ImportRowMatch>>((acc, name) => {
    const match = fieldsFuse.search(name).at(0);
    return {
      ...acc,
      [name]: _notNil(match)
        ? {
            name: match.item.key,
            displayName: match.item.name,
            score: match.score,
          }
        : {},
    };
  }, {});
};
