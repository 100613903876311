// @ts-nocheck: converted from JS

import KeywordSearch from '@/components/UI/KeywordSearch';
import SelectDropDown from '@/components/UI/SelectDropDown';
import TagSearch from '@/components/UI/TagSearch';
import React from 'react';

interface SearchFilterBarProps {
  ShowMenu?: unknown;
  filterMenuDisabled?: unknown;
  FilterMenu?: () => React.ReactNode;
  handleSearchInput?: React.Dispatch<React.SetStateAction<string>>;
  searchQuery?: string;
  searchPlaceholderText?: string;
  searchWithTags?: unknown;
  style?: unknown;
  className?: unknown;
  autoFocus?: boolean;
  disabled?: boolean;
  searchBarRef?: React.Ref<HTMLInputElement>;
  canSearch?: unknown;
  filterMenuHidden?: unknown;
  title?: unknown;
  searchBy?: unknown;
  searchByOptions?: unknown;
  setSearchBy?: unknown;
  hideFilter?: boolean;
  hideSearch?: boolean;
  autoComplete?: boolean;
}

const SearchFilterBar: React.FC<SearchFilterBarProps> = ({
  ShowMenu,
  filterMenuDisabled,
  FilterMenu,
  handleSearchInput,
  searchQuery,
  searchPlaceholderText,
  searchWithTags,
  style,
  className,
  autoFocus = false,
  disabled,
  searchBarRef,
  canSearch = true,
  filterMenuHidden = false,
  title,
  searchBy,
  searchByOptions,
  setSearchBy,
  hideFilter,
  hideSearch,
  autoComplete,
}) => {
  const dropDownClasses = 'dib plain f6 mr2';

  return (
    <div style={style} className={`flex flex-grow-1 ${className}`}>
      {!!(ShowMenu || FilterMenu) && (
        <div className="ui__searchFilterBarActions flex">
          {ShowMenu && (
            <SelectDropDown className={`${dropDownClasses}`} title="Show" alignMenu="left">
              {ShowMenu()}
            </SelectDropDown>
          )}
          {!hideFilter && FilterMenu && !filterMenuHidden && (
            <SelectDropDown
              className={`${dropDownClasses}`}
              title="Filter"
              alignMenu="left"
              disabled={filterMenuDisabled}
              testId="filter-dropdown"
            >
              {FilterMenu()}
            </SelectDropDown>
          )}
        </div>
      )}
      {canSearch &&
        !hideSearch &&
        (searchWithTags ? (
          <TagSearch
            className="w-100"
            handleSearchInput={handleSearchInput}
            searchQuery={searchQuery}
            searchPlaceholderText={searchPlaceholderText}
          />
        ) : (
          <KeywordSearch
            className="w-100 input-reset"
            style={{ marginBottom: 0 }}
            handleSearchInput={handleSearchInput}
            searchQuery={searchQuery}
            searchPlaceholderText={searchPlaceholderText}
            autoFocus={autoFocus}
            disabled={disabled}
            searchBarRef={searchBarRef}
            searchBy={searchBy}
            searchByOptions={searchByOptions}
            setSearchBy={setSearchBy}
            autoComplete={autoComplete}
          />
        ))}
      {title && (
        <span className="pl3 f6 black" style={{ lineHeight: '2.4rem' }}>
          {title}
        </span>
      )}
    </div>
  );
};

export default SearchFilterBar;
