import React, { FocusEventHandler } from 'react';
import './Switch.scss';

interface SwitchProps {
  value: boolean;
  onChange: (value: SwitchProps['value']) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  className?: string;
  disabled?: boolean;
  testId?: string;
}

const Switch: React.FC<SwitchProps> = ({ value, onChange, onBlur, className, disabled, testId }) => (
  <label
    className={`ui__switch ${className}  ${disabled && 'ui__disabled'} mb0`}
    data-test-component="Switch"
    data-test-element="label"
    data-testid={testId}
  >
    <input
      type="checkbox"
      checked={value}
      onChange={() => onChange(!value)}
      onBlur={onBlur}
      data-test-element="checkbox"
    />
    <span className="ui__switch__slider" />
  </label>
);
export default Switch;
