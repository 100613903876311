import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal.tsx';
import { FC } from 'react';

interface DataTableTemplatePublishProps {
  closeModal: () => void;
  publish: (data: { locked: boolean }) => Promise<void>;
}

export const DataTableTemplatePublish: FC<DataTableTemplatePublishProps> = ({ publish, closeModal }) => {
  // const [locked, setLocked] = useState(false);
  return (
    <ModalContainer size="narrow">
      <ModalHeader title="Publish template" closeModal={closeModal} className="pa3 bb b--moon-gray bg-white" />
      <div className="pa3">
        <div className="pb2 near-black">
          Publishing this template will make it accessible to all team members. This cannot be reversed.
        </div>

        {/*<div className="flex justify-between items-center">*/}
        {/*  <label className="mb0 f6">Lock data tables created from template</label>*/}
        {/*  <Switch value={locked} onChange={setLocked} />*/}
        {/*</div>*/}
      </div>
      <ModalActions
        submitBtnText="Publish"
        onSubmit={() => publish({ locked: false })}
        onCancel={closeModal}
        className="pa3 bt b--moon-gray"
      />
    </ModalContainer>
  );
};
