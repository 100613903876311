import { _notNil } from '@/littledash.ts';
import { RequiredNonNil } from '@/model/Common.model.ts';
import cn from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';
import { parse as parseDuration, serialize as serializeDuration } from 'tinyduration';
import style from './Duration.module.scss';

type DurationType = {
  years?: number;
  months?: number;
  weeks?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
};

const clampValues: Pick<RequiredNonNil<DurationType>, 'minutes' | 'hours' | 'days'> = {
  days: 4,
  hours: 3,
  minutes: 2,
} as const;
type Clamp = keyof typeof clampValues;

interface DurationProps {
  value: string;
  disabled?: boolean;
  error?: boolean;
  clamp?: Clamp;
  onChange: (value: string) => void;
  onBlur?: () => void;
}

const toValue = (value: number | undefined, max: number): number | undefined =>
  value !== undefined && value >= 0 ? Math.min(value, max) : undefined;

const normalizeDuration = (duration: DurationType, clamp: Clamp): DurationType => {
  const result: DurationType = { ...duration };
  const clampValue = clampValues[clamp];

  if (_notNil(result.seconds)) {
    result.minutes = (result.minutes ?? 0) + Math.floor(result.seconds / 60);
    result.seconds = result.seconds % 60;
  }

  if (_notNil(result.minutes) && clampValue > clampValues.minutes) {
    result.hours = (result.hours ?? 0) + Math.floor(result.minutes / 60);
    result.minutes = result.minutes % 60;
  }

  if (_notNil(result.hours) && clampValue > clampValues.hours) {
    result.days = (result.days ?? 0) + Math.floor(result.hours / 24);
    result.hours = result.hours % 24;
  }
  return result;
};
export const normalize = (duration: DurationType) => serializeDuration(normalizeDuration(duration, 'days'));

export const Duration: FC<DurationProps> = ({ value, disabled, error, clamp = 'days', onChange, onBlur }) => {
  const [duration, updateDuration] = useState<DurationType>({});
  useEffect(() => {
    try {
      updateDuration(parseDuration(value));
    } catch (e) {
      updateDuration({});
    }
  }, [value]);
  const handleChange = (value: DurationType) => onChange(serializeDuration(value));
  const handleBlur = () => {
    const normalizedDuration = normalizeDuration(duration, clamp);
    const normalizedValue = serializeDuration(normalizedDuration);
    if (value !== normalizedValue) {
      onChange(normalizedValue);
      updateDuration(normalizedDuration);
    }
    onBlur?.();
  };
  const clampValue = clampValues[clamp];
  const maxValues = useMemo(
    () => ({
      days: 999,
      hours: clamp === 'hours' ? 23 : 999,
      minutes: clamp === 'minutes' ? 59 : 999,
    }),
    [clamp]
  );

  return (
    <div
      className={cn(style.durationContainer, { [style.error]: error })}
      data-test-component="Duration"
      data-test-element="container"
    >
      {clampValue >= clampValues.days ? (
        <div className={cn(style.component, style.days)}>
          <span className={style.unit}>days</span>
          <input
            type="number"
            data-test-element="days-input"
            min="0"
            max={maxValues.days}
            step="1"
            value={duration.days ?? 0}
            disabled={disabled}
            placeholder="0"
            onChange={(event) =>
              handleChange({
                ...duration,
                days: toValue(event.target.valueAsNumber, maxValues.days),
              })
            }
            onBlur={handleBlur}
          />
        </div>
      ) : null}
      {clampValue >= clampValues.hours ? (
        <div className={cn(style.component, style.hours)}>
          <span className={style.unit}>hours</span>
          <input
            type="number"
            data-test-element="hours-input"
            min="0"
            max={maxValues.hours}
            step="1"
            value={duration.hours ?? 0}
            disabled={disabled}
            placeholder="0"
            onChange={(event) =>
              handleChange({
                ...duration,
                hours: toValue(event.target.valueAsNumber, maxValues.hours),
              })
            }
            onBlur={handleBlur}
          />
        </div>
      ) : null}
      <div className={cn(style.component, style.minutes)}>
        <span className={style.unit}>minutes</span>
        <input
          type="number"
          data-test-element="minutes-input"
          min="0"
          max={maxValues.minutes}
          step="1"
          value={duration.minutes ?? 0}
          disabled={disabled}
          placeholder="0"
          onChange={(event) =>
            handleChange({
              ...duration,
              minutes: toValue(event.target.valueAsNumber, maxValues.minutes),
            })
          }
          onBlur={handleBlur}
        />
      </div>
    </div>
  );
};
