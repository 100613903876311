import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import type { GridData } from '@/components/UI/InlineTable';
import InlineTable from '@/components/UI/InlineTable';
import MetadataButton from '@/components/UI/MetadataButton/MetadataButton';
import Notes from '@/components/UI/Notes';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import UserAvatar from '@/components/UI/UserAvatar';
import { formatNumber, returnUserFromStudy } from '@/helpers';
import { _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { ID, ISODateTime } from '@/model/Common.model';
import type { DosageV1 } from '@/model/Dose.model';
import type { Study } from '@/model/Study.model';
import * as Auth from '@/support/auth';
import { useRequest } from '@/support/Hooks/request';
import { modalAction } from '@/utils/modal';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { roundToNearestMinutes } from 'date-fns';
import { DateRenderFormat } from '@/utils/Date.utils';

interface DosagesProps {
  study: Study;
  subject: Animal;
  handleCallback: (text: string) => void;
  dosage: DosageV1;
  index: string | number;
}

const formatDoseValuesToInlineTableData = (dose: DosageV1): GridData => {
  const data: GridData = [];
  if (_notNil(dose?.volume?.value)) {
    data.push([
      'Volume',
      `${formatNumber(dose.volume.display_value, true, 3)} ${dose.volume.display_unit ?? dose.volume.unit}`,
    ]);
  }
  if (_notNil(dose?.dose_volume?.value)) {
    data.push([
      'Dose volume',
      `${formatNumber(dose.dose_volume.display_value, true, 3)} ${dose.dose_volume.display_unit ?? dose.dose_volume.unit}`,
    ]);
  }
  if (_notNil(dose?.stock?.value)) {
    data.push([
      'Stock concentration',
      `${formatNumber(dose.stock.display_value, true, 3)} ${dose.stock.display_unit ?? dose.stock.unit}`,
    ]);
  }
  if (_notNil(dose?.dose?.value)) {
    data.push([
      'Dose',
      `${formatNumber(dose.dose.display_value, true, 3)} ${dose.dose.display_unit ?? dose.dose.unit}`,
    ]);
  }
  if (_notNil(dose?.weight_at_dosing)) {
    data.push(['Weight at dosing', `${formatNumber(dose?.weight_at_dosing)} g`]);
  }
  return data;
};
const DoseRecorded: FC<{ dosed_at: ISODateTime; created_at: ISODateTime }> = ({ dosed_at, created_at }) => {
  if (roundToNearestMinutes(dosed_at).getTime() === roundToNearestMinutes(created_at).getTime()) {
    return (
      <span className="f7 lh-copy db">
        Dosed on <DateTimeRenderer value={dosed_at} />
      </span>
    );
  }
  return (
    <span className="f7 lh-copy db">
      <span>
        Dosed on <DateTimeRenderer value={dosed_at} />
      </span>
      <span className="ml1 f8 ">
        (Recorded <DateTimeRenderer value={created_at} format={DateRenderFormat.Date} />)
      </span>
    </span>
  );
};

const Dosages: FC<DosagesProps> = ({ study, subject, handleCallback, dosage, index }) => {
  const administeredBy = returnUserFromStudy(study, dosage.user_id);
  const treatmentName = dosage?.treatment?.treatment_type?.name;

  return (
    <div
      key={dosage.id}
      className={`${index !== 0 ? 'bt b--moon-gray' : ''}`}
      data-testid={`${dosage?.treatment?.id}-dose-result`}
    >
      <div className="flex justify-apart pa3">
        {_notNil(administeredBy) && (
          <UserAvatar user={administeredBy} tooltip={administeredBy.name ?? administeredBy.email} />
        )}
        <div className="flex justify-between w-100">
          <div className="pl2">
            {treatmentName && <h3 className="f5 normal basier-reg lh-copy">{treatmentName}</h3>}
            <DoseRecorded dosed_at={dosage.dosed_at} created_at={dosage.created_at} />
          </div>
        </div>
        <MetadataButton entity={dosage?.treatment ?? {}} entityName="treatmentMeta" tooltip="Treatment metadata" />
      </div>
      {_notNil(dosage.notes) && <Notes note={dosage.notes ?? ''} className="ma3" />}
      <div className="pa3 mw6">
        <InlineTable data={formatDoseValuesToInlineTableData(dosage)} />
      </div>
      <div className="pa3">
        <DosingActions
          animalId={subject?.id}
          dosage={dosage}
          isWriteUser={Auth.isWriteUserForStudy(study)}
          onDelete={handleCallback}
        />
      </div>
    </div>
  );
};

interface DosingActionsProps {
  animalId: ID;
  isWriteUser: boolean;
  dosage: DosageV1;
  onDelete: (text: string) => void;
}

export const DosingActions: FC<DosingActionsProps> = ({ animalId, dosage, isWriteUser = false, onDelete }) => {
  const { openModal, closeModal } = modalAction(useDispatch());
  const { sendRequest: deleteDose } = useRequest({
    route: 'dosing.destroy',
    method: 'delete',
    params: { animalId, doseId: dosage.id ?? '' },
  });
  const handleDeleteDose = async (reasonForDelete?: string): Promise<void> => {
    await deleteDose({ reason_for_change: reasonForDelete });
    toast.success('Deleted dose');
    onDelete('Deleted dose');
  };

  return (
    <SelectDropDown
      title="Actions"
      className="f6 basier-reg pl0 stateless blue"
      alignMenu="left"
      testId={`${dosage?.treatment?.id}-dose-actions`}
    >
      <ActionList
        actions={[
          {
            action: () =>
              openModal('CONFIRM_DELETE_DOSAGE', {
                onClick: handleDeleteDose,
                closeModal,
              }),
            name: 'Delete',
            disabled: !isWriteUser,
          },
        ]}
        testPrefix={`${dosage?.treatment?.id?.toString() ?? ''}__`}
      />
    </SelectDropDown>
  );
};

export default Dosages;
