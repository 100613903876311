import Lookup from '@/components/UI/Lookup';
import { _isEmpty, _isNil } from '@/littledash';
import { notAborted, useAbortController } from '@/support/Hooks/fetch/useAbortController';
import Http from '@/support/http';
import { FC, useState } from 'react';
import { DataTableTemplateApiId, DataTableTemplateInfoV1 } from '@/model/DataTable.model.ts';
import { Study } from '@/model/Study.model.ts';
import { RouteService } from '@/support/RouteService.ts';
import { ExceptionHandler } from '@/utils/ExceptionHandler.ts';
import InVivoError from '@/model/InVivoError.ts';
import useMountedState from '@/support/Hooks/fetch/useMountedState.ts';
import { useApiHook } from '@/support/Hooks/api/useApiHook.ts';

interface DataTableTemplateLookupProps {
  value: DataTableTemplateInfoV1 | undefined;
  onChange: (template?: DataTableTemplateInfoV1) => void;
  onBlur: () => void;
  study: Study;
}

export interface OptionType {
  label: string;
  value: DataTableTemplateApiId;
}

export const TemplateLookup: FC<DataTableTemplateLookupProps> = ({ value, onChange, onBlur, study }) => {
  const isMounted = useMountedState();
  const { newAbortController } = useAbortController();
  const [isLoading, setLoading] = useState(false);

  const { invoke: invokeGetDataTableTemplate } = useApiHook({
    endpoint: 'GET /api/v1/datatables/templates/{datatableTemplateApiId}',
    path: { datatableTemplateApiId: 'dtt_empty' },
    invokeOnInit: false,
  });

  const loadOptions = async ({ inputValue, page }: { inputValue: string; page: string }) => {
    if (!isMounted()) {
      return;
    }

    try {
      setLoading(true);

      const {
        data: { data, meta },
      } = await Http.get(
        RouteService.api({
          endpoint: 'GET /api/v1/datatables/templates',
          path: undefined,
          query: { page, ...(inputValue?.length > 0 ? { query: inputValue, ['query_by[]']: 'name' } : {}) },
        }).url.href,
        {
          signal: newAbortController().signal,
        }
      );
      if (isMounted()) {
        const { current_page: currentPage, last_page: lastPage } = meta;
        const hasMore = currentPage < lastPage;
        const options: Array<DataTableTemplateInfoV1> = data.map(
          (item: { name: string; api_id: DataTableTemplateApiId }) => ({
            label: item.name,
            value: item.api_id,
          })
        );
        return {
          options,
          hasMore,
        };
      }
      return {};
    } catch (error) {
      if (isMounted() && notAborted(error)) {
        ExceptionHandler.captureException(
          new InVivoError('Could not load data table templates', {
            cause: error,
            slug: 'data-table-templates-load',
          })
        );
        setLoading(false);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleTemplateSelect = async (value: OptionType) => {
    try {
      const response = await invokeGetDataTableTemplate({
        path: { datatableTemplateApiId: value.value },
      });
      if (response?.type === 'success' && response.body) {
        const fetchedData = response.body;
        onChange(fetchedData);
      }
    } catch (err) {
      const ivError = new InVivoError('Could not get data table template', {
        slug: 'get-data-table-template',
        cause: err,
      });
      ExceptionHandler.captureException(ivError);
    }
  };

  return (
    <Lookup
      name="select-template-input"
      defaultValue={_isNil(value) ? null : { label: value.name, value }}
      loadOptions={loadOptions}
      handleSelect={handleTemplateSelect}
      onBlur={onBlur}
      additional={{ page: 1, perPage: 10 }}
      isLoading={isLoading}
      placeholder="Select template..."
      noOptionsMessage={({ inputValue = '' }) =>
        isLoading ? 'Loading...' : _isEmpty(inputValue) ? 'No Templates' : `No Templates match ${inputValue}`
      }
    />
  );
};
