import { FC, useMemo } from 'react';
import { DateUtils, RenderDateOptions, RenderDateTimeOptions } from '@/utils/Date.utils';
import type { ISODate, ISODateTime, Nullable } from '@/model/Common.model';
import { _notNil } from '@/littledash.ts';
import { formatDistanceToNowStrict } from 'date-fns';

export const DateRenderer: FC<{ value: Nullable<ISODate> } & RenderDateOptions> = ({ value, ...options }) => (
  <>{DateUtils.renderDate(value, options)}</>
);
export const DateTimeRenderer: FC<{ value: Nullable<ISODateTime> } & RenderDateTimeOptions> = ({
  value,
  ...options
}) => <>{DateUtils.renderDateTime(value, options)}</>;

export const DistanceToNow: FC<{ value: Nullable<ISODateTime> }> = ({ value }) => {
  return useMemo(() => {
    if (_notNil(value)) {
      return formatDistanceToNowStrict(value, { addSuffix: true });
    }
    return null;
  }, [value]);
};
