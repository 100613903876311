import NoDataCard from '@/components/NoDataCard';
import { classificationTypes, generateTreatmentColumns } from '@/components/Studies/Treatments/Treatment.utils';
import Button from '@/components/UI/Button';
import APITable from '@/components/UI/Table/Reusable/APITable';
import type { UseAPITableProps, UseTableProps } from '@/components/UI/Table/Reusable/APITable/APITable.model';
import { updateColumns, updateSettings } from '@/components/UI/Table/Reusable/Cache.utils';
import type { Column } from '@/components/UI/Table/TableComponent.model';
import { successToast } from '@/helpers';
import { _isEmpty, _isNotEmpty, _notNil } from '@/littledash';
import type { ID } from '@/model/Common.model';
import InVivoError from '@/model/InVivoError.ts';
import type { MetadataField } from '@/model/Metadata.model';
import { State } from '@/model/State.model.ts';
import type { Study } from '@/model/Study.model';
import type { ClassificationType, Treatment, TreatmentApiId } from '@/model/Treatment.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import * as Auth from '@/support/auth';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import useMountedState from '@/support/Hooks/fetch/useMountedState';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { RouteService } from '@/support/RouteService';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface TreatmentsTableProps {
  study?: Study;
  onError?: (error: Error) => void;
  handleAddOrUpdateTreatmentsClick: HandleTreatmentsClick;
  metadata?: Array<MetadataField>;
  groups?: Array<TreatmentGroup>;
  type: ClassificationType;
  queryParams?: Record<string, string>;
}

type HandleTreatmentsClick = (event: React.MouseEvent<Element, MouseEvent>, treatment?: Treatment) => Promise<boolean>;

export const TreatmentsTable: React.FC<TreatmentsTableProps> = ({
  study,
  handleAddOrUpdateTreatmentsClick,
  onError,
  groups,
  metadata,
  type,
  queryParams,
}) => {
  const studyId = study?.id;
  const studyApiId = study?.api_id;
  const [isWriteUserForStudy, setIsWriteUserForStudy] = useState<boolean>(false);
  const [columns, setColumns] = useState<Array<Column<Treatment>>>([]);
  const [duplicates, setDuplicates] = useState<Set<TreatmentApiId>>(new Set());
  const dispatch = useDispatch();
  const isMounted = useMountedState();

  const groupsEmpty = _isEmpty(groups);

  const settings = useSelector((state: State) => state.ui.settings);
  const typePlural = classificationTypes[type].plural;

  const { invoke: duplicateTreatmentRequest } = useApiHook({
    endpoint: 'POST /api/v1/studies/{studyApiId}/treatments/duplicate',
    invokeOnInit: false,
    options: { onError: { toast: false, capture: false, throw: true } },
  });

  const updatedSettings = useMemo(() => {
    if (_isNotEmpty(columns)) {
      // Ensure any derived columns (e.g: from measurements) are listed in the settings.
      return updateSettings('treatments', columns, settings);
    }
    return settings;
  }, [columns]);

  useEffect(() => {
    if (_isNotEmpty(columns)) {
      const updatedColumns = updateColumns('treatments', columns, settings);
      setColumns(updatedColumns);
    }
  }, [settings?.tables]);

  useEffect(() => {
    if (study) {
      const writeUser = Auth.isWriteUserForStudy(study);
      setIsWriteUserForStudy(writeUser || Auth.isAuthor(study) || Auth.isOwner(study));
      setColumns(
        generateTreatmentColumns(
          writeUser,
          duplicates,
          settings.tables.treatments.columns,
          isMounted,
          handleAddOrUpdateTreatmentsClick,
          metadata ?? [],
          type
        )
      );
    }
  }, [study, duplicates]);

  if (studyId == null || studyApiId == null) {
    return null;
  }

  const handleDelete = async (treatments: Array<ID>) => {
    try {
      await Http.delete(
        apiRoute('studies.treatments', {
          studyId,
        }),
        {
          data: {
            treatments: Object.values(treatments),
          },
        }
      );
      successToast(`Successfully deleted ${treatments.length > 1 ? typePlural : classificationTypes[type].key}`);
    } catch (error) {
      if (_notNil(onError)) {
        onError(error as Error);
      }
      ExceptionHandler.captureException(
        new InVivoError('Could not delete study treatments', {
          cause: error,
          slug: 'treatments-delete',
        })
      );
    }
  };

  const handleDuplicate = async (treatments: Array<TreatmentApiId>) => {
    try {
      const result = await duplicateTreatmentRequest({ path: { studyApiId }, body: { ids: treatments } });
      if (result?.type === 'success') {
        successToast(`Successfully created ${treatments.length > 1 ? typePlural : classificationTypes[type].key}`);
        setDuplicates(new Set([...(duplicates ?? []), ...(result?.body?.ids ?? [])]));
      }
    } catch (error) {
      if (_notNil(onError)) {
        onError(error as Error);
      }
      ExceptionHandler.captureException(
        new InVivoError('Could not duplicate treatment', {
          cause: error,
          slug: 'treatment-clone',
        })
      );
    }
  };

  const bulkActions = ({
    useTableProps: {
      selectedFlatRows,
      apiTable: { setUpdating, fetchTableData },
    },
  }: UseTableProps<Treatment>) => {
    const selectedTreatmentsInUse = selectedFlatRows.some(({ original: { in_use } }) => in_use);
    return [
      {
        name: 'Duplicate',
        key: 'duplicate_treatments',
        disabled: !isWriteUserForStudy,
        action: () => {
          setUpdating(true);
          const treatmentIDs = selectedFlatRows.map(({ original: { api_id } }) => api_id);
          handleDuplicate(treatmentIDs)
            .then(() => fetchTableData())
            .catch(() => setUpdating(false));
        },
      },
      {
        name: 'Delete',
        key: 'delete_treatments',
        className: 'red',
        disabled: !isWriteUserForStudy || selectedTreatmentsInUse,
        tooltip: selectedTreatmentsInUse && 'One or more of the selected treatments are in use',
        action: () => {
          const treatmentIDs = selectedFlatRows.map(({ original: { id } }) => id);
          dispatch({
            type: 'OPEN_MODAL',
            modal: 'CONFIRM_DELETE_TREATMENTS',
            props: {
              onClick: () => {
                setUpdating(true);
                handleDelete(treatmentIDs)
                  .then(() => fetchTableData())
                  .catch(() => setUpdating(false));
              },
              length: treatmentIDs.length,
            },
          });
        },
      },
    ];
  };

  const isComplete = _notNil(study?.archived_at);

  const disabled = !isWriteUserForStudy || (groupsEmpty && type != 'disease_induction') || isComplete;

  const AsideComponent: React.FC<UseTableProps<Treatment>> | null = !(
    handleAddOrUpdateTreatmentsClick instanceof Function
  )
    ? null
    : ({
        useTableProps: {
          apiTable: { setUpdating, fetchTableData },
        },
      }) => (
        <Button
          disabled={disabled}
          tooltip={groupsEmpty && type != 'disease_induction' ? 'Please add at least one group' : ''}
          testId="add-new-treatment"
          className="ml2"
          onClick={(e) => {
            setUpdating(true);
            handleAddOrUpdateTreatmentsClick(e)
              .then((reload) => {
                if (reload) {
                  return fetchTableData();
                }
                setUpdating(false);
              })
              .catch(() => setUpdating(false));
          }}
        >
          Add New
        </Button>
      );

  const NoDataComponent: React.FC<UseAPITableProps<Treatment>> = ({ apiTable: { setUpdating, fetchTableData } }) => (
    <NoDataCard
      title={`This study has no ${typePlural}`}
      text={
        groupsEmpty && type != 'disease_induction'
          ? `Please add at least one group to enable adding ${typePlural}`
          : `You can add and view ${typePlural} for this study here.`
      }
      onLinkClick={
        groupsEmpty && type != 'disease_induction'
          ? undefined
          : (e) => {
              if (!disabled) {
                setUpdating(true);
                handleAddOrUpdateTreatmentsClick(e)
                  .then((reload) => {
                    if (reload) {
                      return fetchTableData();
                    }
                    setUpdating(false);
                  })
                  .catch(() => setUpdating(false));
              }
            }
      }
      btnTxt={isWriteUserForStudy && `Add a ${classificationTypes[type].title}`}
      openModal
      dark
    />
  );

  return (
    <APITable<Treatment>
      NoDataComponent={NoDataComponent}
      AsideComponent={AsideComponent}
      bulkActions={isWriteUserForStudy && !isComplete ? bulkActions : undefined}
      apiInfo={{
        type: 'internalApi',
        route: RouteService.api({
          endpoint: 'GET /api/v1/studies/{studyApiId}/treatments',
          path: { studyApiId },
        }).url,
      }}
      testIdPrefix="treatments"
      settings={updatedSettings}
      reduxTableName="treatments"
      includeParam="study_groups,metadata"
      searchPlaceholderText={`Search by ${classificationTypes[type].key} name`}
      columns={columns.filter((col) => col.Header)}
      defaultSortBy={{ id: 'treatment_name', desc: false }}
      queryParams={queryParams}
    />
  );
};
