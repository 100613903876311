import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { FC } from 'react';
import Banner from '@/components/UI/Banner';
import { useForm, Controller } from 'react-hook-form@latest';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import { ID } from '@/model/Common.model.ts';
import { useHistory } from 'react-router-dom';
import { RouteService } from '@/support/RouteService.ts';

interface PresetMappingConfirmationModalProps {
  presetMappingPayload: unknown;
  duplicateCageNames: boolean;
  targetStudyId: ID;
  closeModal: () => void;
}

interface FormData {
  presetMappingPayload: unknown;
  duplicateCageNames: boolean;
}

export const PresetMappingConfirmationModal: FC<PresetMappingConfirmationModalProps> = ({
  presetMappingPayload,
  duplicateCageNames,
  targetStudyId,
  closeModal,
}) => {
  const { handleSubmit, control } = useForm({
    defaultValues: {
      duplicateCageNames: duplicateCageNames ?? false,
      presetMappingPayload: presetMappingPayload,
    },
  });

  const history = useHistory();

  const submit = (data: FormData) => {
    // TODO: Implement API post when in place
    if (data.duplicateCageNames) {
      closeModal();
      history.push(
        RouteService.web({
          route: 'studies.cages',
          path: { id: targetStudyId },
        }).route
      );
    }
  };

  return (
    <ModalContainer size="narrow" className="bg-white">
      <ModalHeader title="Are you sure?" className="pa3 bb b--moon-gray w-100" />
      <div className="pa3 mv3 near-black">
        <Banner info dismiss={false} className="mw6 mb3">
          <h3 className="normal lh-title f5 pb2" data-testid="metadata-settings__empty-banner--title">
            There will be duplicate page names in the recipient study. <span className="fw5">Update the names</span> in
            the new study to ensure uniqueness.
          </h3>
        </Banner>
        <p>
          Measurements, samples and observations will remain intact.{' '}
          <span className="fw5">All groups, doses, alerts or tracking dates will be permanently removed.</span>{' '}
          <span className="red">This cannot be undone.</span>
        </p>
        <form onSubmit={handleSubmit(submit)}>
          <div className="mt3">
            <Controller
              name="duplicateCageNames"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Checkbox label="Take me to the new study" checked={value} onChange={onChange} />
              )}
            />
          </div>
        </form>
      </div>
      <ModalActions
        className="pa3 bt b--moon-gray"
        onSubmit={handleSubmit(submit)}
        onCancel={closeModal}
        submitBtnText="Confirm"
        cancelBtnText="Cancel"
      />
    </ModalContainer>
  );
};
