import Header from '@/components/UI/Header';
import { LabelProps } from '@/components/UI/Label/Label';
import { LabelType } from '@/components/UI/Label/Label.tsx';
import SubHeader from '@/components/UI/SubHeader';
import { _isNil } from '@/littledash';
import type { Study } from '@/model/Study.model';
import { web as webRoute } from '@/support/route';
import { DateUtils } from '@/utils/Date.utils';
import StudyUsers from './Users';

interface StudyheaderProps {
  study: Study;
}

const Studyheader: React.FC<StudyheaderProps> = ({ study }) => {
  if (_isNil(study)) {
    return null;
  }
  const headerLabel: LabelProps | undefined = study.archived_at
    ? {
        text: 'Completed',
        tooltip: `This study was completed on ${DateUtils.renderDateTime(study.archived_at, { defaultResponse: '-' })}`,
        type: LabelType.Info,
      }
    : study.canceled_at
      ? {
          text: 'Cancelled',
          tooltip: `This study was cancelled on ${DateUtils.renderDateTime(study.canceled_at, { defaultResponse: '-' })}`,
          type: LabelType.Info,
        }
      : undefined;
  return (
    <>
      <SubHeader linkToText="Studies" link={webRoute('dashboard')} />
      <div className="ph4">
        <Header
          mainHeaderText={study.name}
          subHeaderText={study.code}
          asideComponent={() => <StudyUsers study={study} />}
          label={headerLabel}
        />
      </div>
    </>
  );
};

export default Studyheader;
