import { FormulaDisplay } from '@/components/Modals/EditDataTableFormulaColumn/FormulaDisplay';
import { DateRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import Tooltip from '@/components/UI/Tooltip';
import { capitalise, defaultPromiseErrorHandler, errorToast, formatNumber, successToast } from '@/helpers';
import { _isNil, _isNotEmpty, _notNil, uuid } from '@/littledash';
import { DataTablePkDoseColumnFillRequestV1 } from '@/model/DataTable.model.ts';
import { DateInputUtils, DateUtils } from '@/utils/Date.utils';
import { modalAction } from '@/utils/modal';
import { formatDuration } from 'date-fns';
import { FC, ReactElement, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { RiArrowDropDownFill, RiLock2Fill, RiTimerLine } from 'react-icons/ri';
import { TbMathFunction, TbRuler, TbScale } from 'react-icons/tb';
import { useDispatch } from 'react-redux';
import { parse as parseDuration } from 'tinyduration';
import { Dropdown, DropdownMenuDivider, DropdownMenuItem } from '../../Dropdown';
import type {
  DataTableCellSelectionChangeEvent,
  DataTableColumn,
  DataTableColumnAddRequest,
  DataTableColumnMoveRequest,
  DataTableColumnType,
  DataTableColumnUpdateRequest,
  DataTableFormula,
  DataTableNumberColumn,
  DataTableService,
  DataTableStateChangeEvent,
  DataTableType,
} from '../DataTable.model';
import { DataTableColumnUpdateForm, DataTableEvent } from '../DataTable.model';
import styles from '../DataTable.module.scss';
import { formulaVariablesFromColumnIds } from '../DataTable.util';

interface DataTableColumnHeaderProps {
  columnIndex: number;
  dataTableService: DataTableService;
  positionOffset: { x: number; y: number };
}

interface DataTableColumnTypeSymbolProps {
  type: DataTableColumnType;
  locked: boolean;
  formula: DataTableFormula | undefined;
  dataTableService: DataTableService;
}

interface DataTableTruncateWrapperProps {
  children: ReactNode;
  tooltip: string;
}

export const DataTableColumnHeader = ({
  columnIndex,
  dataTableService,
  positionOffset,
}: DataTableColumnHeaderProps) => {
  const [columnUpdate, triggerColumnUpdate] = useState('');
  const [rowCount, updateRowCount] = useState(dataTableService.rows.length);
  const column = useMemo(
    () => dataTableService.columnByIndex(columnIndex),
    [columnIndex, dataTableService, columnUpdate]
  );
  const [columnSelected, setColumnSelected] = useState(false);
  const { openModal, closeModal } = modalAction(useDispatch());

  useEffect(() => {
    if (_notNil(dataTableService) && _notNil(columnIndex)) {
      const destroy = new AbortController();
      setColumnSelected(dataTableService.columnSelected(columnIndex));
      dataTableService.subscribe(
        DataTableEvent.TableStateChange,
        (event: DataTableStateChangeEvent) => {
          if (event.detail.type.has('rows')) {
            updateRowCount(dataTableService.rows.length);
          }
          if (event.detail.type.has('columns') && _notNil(column) && event.detail.ids.has(column?.id)) {
            triggerColumnUpdate(uuid());
          }
        },
        destroy.signal
      );
      dataTableService.subscribe(
        DataTableEvent.CellSelectionChange,
        (event: DataTableCellSelectionChangeEvent) => {
          setColumnSelected((previous) => {
            const current = event.detail.selection.containsColumn(columnIndex);
            return previous === current ? previous : current;
          });
        },
        destroy.signal
      );
      return () => destroy.abort('teardown');
    }
  }, [column, columnIndex, dataTableService]);

  const { cellWidth, columnHeight } = dataTableService.dimensions;
  const handleMoveColumn = () => {
    openModal('MOVE_DATATABLE_COLUMN', {
      column,
      tableType: dataTableService.type,
      columns: dataTableService.columns,
      onSave: (update: DataTableColumnMoveRequest) => {
        dataTableService
          .moveColumn(update)
          .then(() => successToast('Column moved Successfully'))
          .then(() => dataTableService.validate())
          .then(() => dataTableService.validateColumn(update.column_id))
          .catch(defaultPromiseErrorHandler);
      },
    });
  };
  const handleFill = async () => {
    if (column?.type === 'timestampBaseline') {
      openModal('DATATABLE_COLUMN_FILL', {
        column,
        onFill: async (value: DataTablePkDoseColumnFillRequestV1) =>
          dataTableService.fillColumn(column.id, value).then(closeModal),
        onCancel: () => {
          closeModal();
        },
      });
    }
  };
  const handleColumEdit = async () => {
    if (column?.type === 'formula') {
      openModal('EDIT_DATATABLE_FORMULA_COLUMN', {
        formData: {
          name: column.name,
          unit: column.unit,
          formula: await formulaVariablesFromColumnIds(column.formula?.value ?? '', dataTableService.columns, {
            ignoreInvalidVariables: true,
          }),
        },
        columnId: column.id,
        columns: dataTableService.columns,
        onSubmit: (formData: DataTableColumnAddRequest) => {
          dataTableService
            .updateColumn({ id: column.id, name: formData.name, unit: formData.unit, formula: formData.formula })
            .then(() => successToast('Column updated'))
            .catch(() => errorToast('Could not update column'));
        },
      });
    } else if (column?.type === 'timestampBaseline') {
      const from = DateInputUtils.toLocalDateTime(column.from, { includeSeconds: false });
      openModal('EDIT_DATATABLE_TIMESTAMP_BASELINE_COLUMN', {
        formData: {
          from,
          name: column.name,
          interval: column.pk?.interval,
          interval_time_unit: column?.pk?.interval_time_unit,
          window: column.pk?.window ?? 0,
        },
        readOnly: true,
        columnId: column.id,
        columns: dataTableService.columns,
        onSubmit: (formData: DataTableColumnAddRequest) => {
          dataTableService
            .updateColumn({
              id: column.id,
              name: formData.name,
            })
            .then(() => successToast('Column updated'))
            .catch(() => errorToast('Could not update column'));
        },
      });
    } else if (column?.type === 'timestampBaselineRelative') {
      openModal('EDIT_DATATABLE_TIMESTAMP_BASELINE_RELATIVE_COLUMN', {
        formData: {
          name: column.name,
          interval: column.pk?.interval,
          interval_time_unit: column?.pk?.interval_time_unit ?? null,
          timestamp_baseline_id: column.pk?.timestamp_baseline_id,
        },
        columnId: column.id,
        columns: dataTableService.columns,
        onSubmit: (formData: DataTableColumnAddRequest) => {
          dataTableService
            .updateColumn({
              id: column.id,
              name: formData.name,
              pk: {
                interval: formData?.pk?.interval,
                interval_time_unit: formData?.pk?.interval_time_unit,
                timestamp_baseline_id: formData?.pk?.timestamp_baseline_id,
              },
            })
            .then(() => successToast('Column updated'))
            .catch(() => errorToast('Could not update column'));
        },
      });
    } else if (column?.type === 'measurement') {
      openModal('EDIT_DATATABLE_MEASUREMENT_COLUMN', {
        formData: {
          referenceDate: column.measurement?.measured_at,
        },
        column,
        columns: dataTableService.columns,
        onSave: (columnUpdate: DataTableColumnUpdateRequest) => {
          dataTableService
            .updateColumn({ id: column.id, measurement: columnUpdate.measurement, name: columnUpdate.name })
            .then(() => successToast('Column updated'))
            .catch(() => errorToast('Could not update column'));
        },
      });
    } else if (column?.type === 'observation') {
      openModal('EDIT_DATATABLE_OBSERVATION_COLUMN', {
        formData: {
          referenceDate: column.observation?.observed_at,
        },
        column,
        columns: dataTableService.columns,
        onSave: (columnUpdate: DataTableColumnUpdateRequest) => {
          dataTableService
            .updateColumn({ id: column.id, observation: columnUpdate.observation, name: columnUpdate.name })
            .then(() => successToast('Column updated'))
            .catch(() => errorToast('Could not update column'));
        },
      });
    } else {
      openModal('EDIT_DATATABLE_COLUMN', {
        tableType: dataTableService.type,
        column,
        columns: dataTableService.columns,
        onSave: ({ type, ...columnUpdate }: DataTableColumnUpdateForm) =>
          dataTableService
            .updateColumn(columnUpdate)
            .then(() => successToast('Column updated'))
            .catch(() => errorToast('Could not update column')),
      });
    }
  };
  const handleColumDelete = () => {
    let textOverride: string | undefined = undefined;
    if (column?.type === 'timestampBaseline') {
      textOverride =
        'Deleting this Timestamp: PK Dose column will delete all Timestamp: PK Bleed columns related to it.';
    }
    openModal('CONFIRM_DELETE_DATA_TABLE_COLUMN', {
      textOverride,
      onClick: () => {
        const columnId = column?.id;
        if (_notNil(columnId)) {
          dataTableService
            .removeColumn(columnId)
            .then(() => {
              if (column?.type === 'timestampBaselineRelative' || column?.type === 'timestampBaseline') {
                dataTableService.validate();
              }
            })
            .then(() => successToast('Column removed'))
            .catch(defaultPromiseErrorHandler);
        }
      },
      deleteManyColumns:
        column?.type === 'measurement'
          ? () => {
              const columnList: Array<DataTableColumn> | null = dataTableService?.columnsByIds(column?.relations);
              if (_isNotEmpty(columnList)) {
                return (
                  <div>
                    <span>This column and its dependeant columns will be permanently deleted:</span>
                    <ul className="overflow-scroll mt3 ba b--gray" style={{ maxHeight: '150px' }}>
                      {columnList?.map((object, index) => (
                        <li className="bg-light-gray pv2 pl2 tl ba b--moon-gray lh-title f6 near-black" key={index}>{`${
                          object.name
                        } (${DateUtils.renderDate(object.reference_date, {
                          defaultResponse: '',
                        })})`}</li>
                      ))}
                    </ul>
                  </div>
                );
              }
              return null;
            }
          : null,
    });
  };
  const handleColumnLock = (locked: boolean) => {
    const id = column?.id;
    if (_notNil(id)) {
      dataTableService
        .updateColumn({ id, locked })
        .then(() => successToast(`Column ${locked ? 'locked' : 'unlocked'}`))
        .catch(() => errorToast(`Could not ${locked ? 'lock' : 'unlock'} column`));
    }
  };
  return (
    <div
      className={styles['data-table-column-header-container']}
      data-test-component="DataTableColumnHeader"
      data-test-element="container"
      key={column?.id}
      onClick={() => dataTableService.selectColumn(columnIndex)}
      style={{
        position: 'absolute',
        top: 0,
        left: positionOffset.x,
        width: cellWidth,
        height: columnHeight,
      }}
    >
      <div
        className={`${styles['data-table-column-header']} ${
          columnSelected ? styles['selected'] : ''
        } flex justify-between items-center`}
      >
        <div className={`${styles['data-table-column-type']} `}>
          <DataTableColumnTypeSymbol
            type={column?.type ?? 'number'}
            locked={column?.locked ?? false}
            formula={column?.type === 'formula' ? column?.formula : undefined}
            dataTableService={dataTableService}
          />

          <DataTaleColumnTitle tableType={dataTableService.type} column={column} />
        </div>
        <Dropdown
          disabled={dataTableService.readonly}
          renderMenu={() => (
            <span data-test-component="DataTableColumnHeader" data-test-element="dropdown-menu">
              <DropdownMenuItem
                onClick={handleMoveColumn}
                disabled={
                  (column?.locked ?? false) || (dataTableService.type === 'time' && _isNotEmpty(dataTableService.unit))
                }
              >
                <span>Move column</span>
              </DropdownMenuItem>
              {!['observation', 'measurement'].includes(column?.type ?? '') &&
                !(dataTableService.type === 'time' && _notNil(dataTableService.unit)) && (
                  <DropdownMenuItem disabled={column?.locked ?? false} onClick={() => handleColumEdit()}>
                    <span>Edit</span>
                  </DropdownMenuItem>
                )}
              {column?.type === 'timestampBaseline' ? (
                <DropdownMenuItem disabled={(column?.locked ?? false) || rowCount === 0} onClick={() => handleFill()}>
                  <span>Fill</span>
                </DropdownMenuItem>
              ) : null}
              <ColumLockMenuItem
                locked={column?.locked ?? false}
                read_only={column?.read_only ?? false}
                onClick={handleColumnLock}
              />
              <DropdownMenuDivider />
              <DropdownMenuItem onClick={handleColumDelete} disabled={column?.locked ?? false}>
                <span className="red">Delete</span>
              </DropdownMenuItem>
            </span>
          )}
        >
          <div
            className={`${styles['data-table-column-header-dropdown']} ${
              dataTableService.readonly ? 'ui__disabled' : ''
            }`}
            data-test-element="dropdown-toggle"
          >
            <RiArrowDropDownFill size={22} />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

const CONTENT_MAX_WIDTH = 85;
const DataTableTruncateWrapper: FC<DataTableTruncateWrapperProps> = ({ children, tooltip }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const width = useRef<number>(0);
  useEffect(() => {
    if (ref.current) {
      width.current = ref?.current?.clientWidth ?? 0;
    }
  }, [ref]);

  return (
    <div ref={ref}>
      {(width?.current ?? 0) >= CONTENT_MAX_WIDTH ? (
        <Tooltip render={tooltip}>
          <span className={'data-table-truncate-wrapper'}>{children}</span>
        </Tooltip>
      ) : (
        <span className={'data-table-truncate-wrapper'}>{children}</span>
      )}
    </div>
  );
};

const typeToIconMap: Record<DataTableColumnType, string | ReactElement> = {
  number: '#',
  text: '☰',
  timestamp: <RiTimerLine size={12} />,
  timestampBaseline: <RiTimerLine size={12} />,
  timestampBaselineRelative: <RiTimerLine size={12} />,
  formula: <TbMathFunction size={12} />,
  measurement: <TbRuler size={12} />,
  observation: <TbScale size={12} />,
};

const renderColumnTypeSymbolTooltip = (
  type: DataTableColumnType,
  columns: readonly DataTableColumn[],
  formula?: DataTableFormula
) => {
  switch (type) {
    case 'formula':
      return FormulaDisplay({ formula, columns });
    case 'timestampBaseline':
      return 'Timestamp: PK Dose';
    case 'timestampBaselineRelative':
      return 'Timestamp: PK Bleed';
    default:
      return capitalise(type);
  }
};

const DataTableColumnTypeSymbol = ({ type, formula, dataTableService, locked }: DataTableColumnTypeSymbolProps) => (
  <Tooltip render={renderColumnTypeSymbolTooltip(type, dataTableService.columns, formula)}>
    <span className={`flex ${styles['data-table-column-type-symbol-container']}`} style={{ position: 'relative' }}>
      <div className={styles['data-table-column-type-symbol']}>{typeToIconMap[type]}</div>
      {locked && <RiLock2Fill size={12} fill={'#ff8d02'} className={styles['data-table-column-type-lock-symbol']} />}
    </span>
  </Tooltip>
);

interface DataTaleColumnTitleProps {
  tableType: DataTableType | 'unknown';
  column: DataTableColumn | undefined;
}

const DataTaleColumnTitle: FC<DataTaleColumnTitleProps> = ({ tableType, column }) => {
  switch (tableType) {
    case 'time':
      return <DataTableTimeColumnTitle name={column?.name} />;
    case 'numeric':
      return <DataTableNumericColumnTitle column={column as DataTableNumberColumn} />;
    case 'custom':
      return <DataTableCustomColumnTitle column={column} />;
    default:
      return null;
  }
};

const DataTableTimeColumnTitle: FC<{ name: string | undefined }> = ({ name }) => {
  const title = useMemo(() => {
    if (_isNil(name)) {
      return null;
    }
    try {
      const duration = parseDuration(name);
      if (Object.values(duration).every((v) => v === 0)) {
        return '0';
      }
      return (duration.negative === true ? '- ' : '') + formatDuration(duration, { zero: false });
    } catch (err) {
      // Intentionally empty
    }
    return null;
  }, [name]);
  return (
    <div className={styles['data-table-column-text-container']}>{title ? <ColumnName name={String(title)} /> : ''}</div>
  );
};

const DataTableNumericColumnTitle: FC<{ column?: DataTableNumberColumn }> = ({ column }) => {
  const name = column?.name;
  const unit = column?.unit ?? '';
  if (_isNil(name)) {
    return null;
  }
  return (
    <div className={styles['data-table-column-text-container']}>
      <ColumnName name={String(formatNumber(name))} />
      <ColumnUnit unit={unit} />
    </div>
  );
};

const DataTableCustomColumnTitle: FC<Pick<DataTaleColumnTitleProps, 'column'>> = ({ column }) => {
  if (_isNil(column)) {
    return null;
  }
  switch (column.type) {
    case 'timestamp':
    case 'timestampBaseline':
    case 'timestampBaselineRelative':
    case 'text':
      return (
        <div className={styles['data-table-column-text-container']}>
          <ColumnName name={column.name} />
        </div>
      );
    case 'formula':
    case 'number': {
      const unit = column.unit?.trim() ?? '';
      return (
        <div className={styles['data-table-column-text-container']}>
          <ColumnName name={column.name} />
          {_isNotEmpty(unit) && <ColumnUnit unit={unit} />}
        </div>
      );
    }
    case 'measurement':
    case 'observation':
      return (
        <div className={styles['data-table-column-text-container']}>
          <ColumnDate date={column?.reference_date ?? ''} />
          <div className="flex flex-row">
            <ColumnName name={column.name} unit={column.type === 'measurement' ? column.unit : undefined} />
          </div>
        </div>
      );
    default:
      return null;
  }
};

const ColumnName: FC<{ name: string; unit?: string }> = ({ name, unit }) => {
  return (
    <DataTableTruncateWrapper tooltip={name}>
      <div className={styles['data-table-column-name']}>
        {name}
        {_notNil(unit) && (
          <span className={styles['data-table-column-unit']} style={{ marginLeft: '5px' }}>
            ({unit.trim()})
          </span>
        )}
      </div>
    </DataTableTruncateWrapper>
  );
};

const ColumnUnit: FC<{ unit: string }> = ({ unit }) => {
  return (
    <DataTableTruncateWrapper tooltip={unit}>
      <div className={styles['data-table-column-unit']}>({String(unit).trim()})</div>
    </DataTableTruncateWrapper>
  );
};

const ColumnDate: FC<{ date: string }> = ({ date }) => (
  <div className={`fw4 ${styles['data-table-column-date']}`}>
    <DateRenderer value={date} defaultResponse="" />
  </div>
);

const ColumLockMenuItem: FC<
  {
    onClick: (locked: boolean) => void;
  } & Required<Pick<DataTableColumn, 'locked' | 'read_only'>>
> = ({ locked, read_only, onClick }) => {
  if (read_only && !locked) {
    return null;
  }
  if (locked) {
    return (
      <DropdownMenuItem onClick={() => onClick(false)}>
        <span>Unlock column</span>
      </DropdownMenuItem>
    );
  }
  return (
    <DropdownMenuItem onClick={() => onClick(true)}>
      <span>Lock column</span>
    </DropdownMenuItem>
  );
};
