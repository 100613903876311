import type { TreatmentFormField, TreatmentFormUnit } from '@/components/Modals/AddTreatmentForm/TreatmentForm.model';
import { NumberInput as Number } from '@/components/UI/FormFields/Number';
import Icon from '@/components/UI/Icon';
import Select from '@/components/UI/Select';
import { _isNotEmpty } from '@/littledash';
import { decimalPlacesValidator, nilOrGreaterThan, valueRequired } from '@/validators';
import { ErrorMessage } from '@hookform/error-message';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Control, Controller, ControllerRenderProps, FieldError, FieldErrors } from 'react-hook-form';
import { components, DropdownIndicatorProps, SingleValueProps } from 'react-select';
import Checkbox from '../Checkbox';
import './ControlledInputWithUnits.scss';

const DropdownIndicator = (count: number) => (props: DropdownIndicatorProps<TreatmentFormUnit>) =>
  count > 1 ? (
    <components.DropdownIndicator {...props}>
      <Icon icon="down_triangle" width="8" height="8" className="right-1 blue" style={{ pointerEvents: 'none' }} />
    </components.DropdownIndicator>
  ) : null;

interface ControlledInputWithUnitsProps {
  id: string;
  field: TreatmentFormField;
  disabled: boolean;
  hasError: boolean;
  classes: string;
  units: Array<TreatmentFormUnit>;
  control: Control;
  errors: FieldErrors<FieldError>;
  selectRequired?: boolean;
  unitsDisabled?: boolean;
  tooltip?: string;
}

const ControlledInputWithUnits = ({
  id,
  field,
  disabled = false,
  hasError = false,
  classes,
  units,
  control,
  errors,
  selectRequired,
  unitsDisabled,
  tooltip,
}: ControlledInputWithUnitsProps) => (
  <div>
    <div className={`flex relative ${classes}`}>
      <Controller
        control={control}
        rules={{
          required: field.required ? `${field.label} is required` : undefined,
          validate: {
            valueRequired: valueRequired(field.label, field.required),
            decimalPlacesValidator: decimalPlacesValidator(field.label),
            greaterThan: nilOrGreaterThan(field.label, 0, field.required),
          },
        }}
        name={`${id}.default_value`}
        render={({ value, onChange }: ControllerRenderProps) => (
          <Number
            value={value}
            onChange={onChange}
            disabled={disabled}
            style={{ paddingRight: '3.5rem' }}
            className={hasError ? 'input__error' : ''}
          />
        )}
      />

      <div className={`'pointer unitSelection ' ${!selectRequired ? 'unitWidth' : 'customUnitWidth'}`}>
        <Controller
          name={`${id}.unit`}
          control={control}
          rules={{
            required: 'Unit is required',
          }}
          render={({ value, onChange }: ControllerRenderProps) => (
            <Select
              value={value}
              options={units}
              onChange={onChange}
              getOptionLabel={(option: TreatmentFormUnit) => option.name}
              getOptionValue={(option: TreatmentFormUnit) => option.id.toString()}
              customStyles={{
                indicatorsContainer: () => ({
                  border: 'none',
                  padding: 0,
                }),
                singleValue: (base: SingleValueProps) => ({
                  ...base,
                  color: unitsDisabled ? '#d3d3d3' : '#0647a6',
                  fontSize: '80%',
                  textAlign: 'end',
                }),
              }}
              className="w-100 mb3"
              components={{ DropdownIndicator: (props: any) => DropdownIndicator(units.length)(props) }}
              isSearchable={false}
              disabled={unitsDisabled}
              isMulti={false}
            />
          )}
        />
      </div>
    </div>
    {selectRequired && (
      <div className="pb3 flex justify-between" data-test-element="requied-checkbox-container" data-test-key={id}>
        <Controller
          name={`${id}.required`}
          control={control}
          render={({ value, onChange }: ControllerRenderProps) => (
            <Checkbox label="Required" checked={Boolean(value)} onChange={(e) => onChange(e.target.checked)} />
          )}
        />
        {_isNotEmpty(tooltip) && (
          <span data-tooltip-id="global-tooltip-id">
            <Icon className="mid-gray hover-blue" icon="info" width="24" height="24" tooltip={tooltip} />
          </span>
        )}
      </div>
    )}
    <div className="flex flex-column">
      <ErrorMessage
        name={`${id}.default_value`}
        errors={errors}
        render={({ message }) => <small className="red mb3">{message}</small>}
      />
      <ErrorMessage
        name={`${id}.unit`}
        errors={errors}
        render={({ message }) => <small className="red mb3">{message}</small>}
      />
    </div>
  </div>
);
export default ControlledInputWithUnits;
