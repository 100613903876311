import type { components } from '@/generated/internal-api/openapi-types';
import type { AnimalAlertV1 } from '@/model/Alert.model';
import type { AnimalApiId } from '@/model/Animal.model';
import type { ID, ISODateTime } from '@/model/Common.model';
import type { DataTableColumnFillRequestV1, DataTableV1 } from '@/model/DataTable.model';
import type { StudyApiId } from '@/model/Study.model';
import type { AxiosInstance } from 'axios';
import type { Observable } from 'rxjs';

export type DataTableApiId = components['schemas']['DataTableApiId.schema'];
export type DataTableColumnApiId = components['schemas']['DataTableColumnApiId.schema'];
export type DataTableRowApiId = components['schemas']['DataTableRowApiId.schema'];
export type DataTableColumnIndex = number;
export type DataTableRowIndex = number;

export type DataTableType = components['schemas']['DataTableType.schema'];
export type DataTableTimeUnit = 'hours' | 'minutes' | 'seconds';
export type DataTableCellReference = `[${DataTableColumnApiId},${DataTableRowApiId}]`;

export type DataTableWarnings = DataTableErrors;
export type DataTableErrors = Array<{
  ref: DataTableCellReference;
  coordinate?: DataTableCellIdCoordinate;
  status?: DataTableCellStatus;
}>;
export type DataTableDirection = 'UP' | 'DOWN' | 'LEFT' | 'RIGHT';

export enum Axis {
  x = 'x',
  y = 'y',
}

export interface DataTableAxis<X = number> {
  [Axis.x]: X;
  [Axis.y]: X;
}

export interface DataTableCellSelectionOptions {
  append?: boolean;
  scrollTo?: boolean;
  activate?: boolean;
  source?: DataTableCellSelectionChangeEventDetail['source'];
}

export interface DataTableCellsUpdateOptions {
  snapshot?: 'normal' | 'undo' | 'redo' | 'none';
  windowClear?: {
    startColumnId: string;
    startRowId: string;
    endColumnId: string;
    endRowId: string;
  };
}

export interface DataTableMoveSelectionOptions {
  append?: boolean;
}

export interface DataTableUpdateDisplayedRowHeadersOptions {
  persist?: boolean;
}

export interface DataTableDimensions {
  cellWidth: number;
  cellHeight: number;
  columnHeight: number;
}

export interface DataTableApiServiceProps {
  studyId: StudyApiId;
  dataTableId: DataTableApiId;
  axios: AxiosInstance;
}

export type DataTable = components['schemas']['DataTableV1.schema'];

export interface DataTableApiServiceOperation<Source, Output> {
  (source: Observable<Source>): Observable<Output>;
}

export interface DataTableSettingsUpdate {
  display_columns: Array<DataTableRowHeaderId>;
}

export interface DataTableUpsertDetail {
  cells: Array<DataTableCellUpsert>;
  alerts: Array<DataTableAlert>;
  invalidCells: Array<DataTableCellUpsertError>;
  networkErrors: Array<DataTableCellUpsert>;
  effects: Array<DataTableColumnApiId>;
}

export interface DataTableApiService {
  loadTable(context: DataTableService): DataTableApiServiceOperation<any, DataTableV1>;

  loadCells(
    context: DataTableService
  ): DataTableApiServiceOperation<
    DataTableCellSelection<DataTableColumnApiId, DataTableRowApiId> & { include_alerts?: boolean },
    Array<DataTableApiLoadCellResponse>
  >;

  updateCells(
    context: DataTableService
  ): DataTableApiServiceOperation<Array<DataTableCellUpsert>, DataTableCellUpsertResponse>;

  validate(
    context: DataTableService
  ): DataTableApiServiceOperation<DataTableWindowClearRequest, DataTableValidateResponse>;

  clearWindow(
    context: DataTableService
  ): DataTableApiServiceOperation<DataTableWindowClearRequest, DataTableWindowClearResponse>;

  updateTableSettings(context: DataTableService): DataTableApiServiceOperation<DataTableSettingsUpdate, void>;

  moveColumn(
    context: DataTableService
  ): DataTableApiServiceOperation<DataTableColumnMoveRequest, DataTableMoveColumnResponse>;

  addColumn(context: DataTableService): DataTableApiServiceOperation<DataTableColumnAddRequest, Array<DataTableColumn>>;

  updateColumn(context: DataTableService): DataTableApiServiceOperation<DataTableColumnUpdateRequest, DataTableColumn>;

  removeColumn(
    context: DataTableService
  ): DataTableApiServiceOperation<DataTableColumnApiId, DataTableColumnRemoveResponse>;

  addRow(context: DataTableService): DataTableApiServiceOperation<DataTableRowAddRequest, Array<DataTableRow>>;

  removeRow(context: DataTableService): DataTableApiServiceOperation<DataTableRowApiId, void>;

  fillColumn(
    context: DataTableService
  ): DataTableApiServiceOperation<
    { column_id: DataTableColumnApiId; request: DataTableColumnFillRequestV1 },
    DataTableCellUpsertResponse
  >;
}

export interface DataTableService {
  get name(): Readonly<string | undefined>;

  get tableId(): Readonly<DataTableApiId | undefined>;

  get studyApiId(): Readonly<StudyApiId>;

  get type(): Readonly<DataTableType | 'unknown'>;

  get unit(): Readonly<string | null | undefined>;

  get readonly(): Readonly<boolean>;

  get columns(): Readonly<Array<DataTableColumn>>;

  get rows(): Readonly<Array<DataTableRow>>;

  get columnCount(): number;

  get rowCount(): number;

  get dimensions(): Readonly<DataTableDimensions>;

  get selection(): Readonly<DataTableSelection | undefined>;

  get working(): Readonly<boolean>;

  get workflowService(): Readonly<DataTableWorkflowService>;

  get featureFlags(): Readonly<DataTableFeatureFlags>;

  initialise(): Promise<DataTableService>;

  hasColumn(columnId: DataTableColumnApiId): boolean;

  toIndexCoordinate(cellCoordinate: DataTableCellIdCoordinate): DataTableCellIndexCoordinate | undefined;

  toIndexColumn(columnId: DataTableColumnApiId): DataTableColumnIndex | undefined;

  toIndexRow(rowId: DataTableRowApiId): DataTableRowIndex | undefined;

  columnByIndex(index: number | undefined): DataTableColumn | undefined;

  columnById(columnId: DataTableColumnApiId | undefined): DataTableColumn | undefined;

  columnsByIds(columnIds: Array<DataTableColumnApiId> | undefined): Array<DataTableColumn>;

  rowByIndex(index: number | undefined): DataTableRow | undefined;

  fromIndexCoordinate(cellCoordinate: DataTableCellIndexCoordinate): DataTableCellIdCoordinate | undefined;

  cellSelected(cellCoordinate: DataTableCellIndexCoordinate): boolean;

  columnSelected(column: number): boolean;

  rowSelected(row: number): boolean;

  cellState(cellCoordinate: DataTableCellIdCoordinate | undefined): DataTableCellState;

  cellStatus(cellCoordinate: DataTableCellIdCoordinate | undefined): DataTableCellStatus;

  rowHasError(row: DataTableRowApiId | undefined): boolean;

  rowHeader(id: DataTableRowApiId | undefined): DataTableRow | undefined;

  cellData(cellCoordinate: DataTableCellIdCoordinate | undefined): DataTableCellDataCache | undefined;

  cellAlerts(cellCoordinate: DataTableCellIdCoordinate | undefined): DataTableCellAlertCache | undefined;

  handleChunkCellUpsert(
    chunkSize: number,
    validCells: Array<DataTableCellUpsert>
  ): Array<Promise<DataTableUpsertDetail>>;

  updateCells(
    cells: Array<DataTableCellUpsert>,
    options?: DataTableCellsUpdateOptions
  ): Promise<DataTableCellUpdateEventDetail>;

  validate(): Promise<void>;

  validateColumn(...columns: Array<DataTableColumnApiId>): void;

  selectCell(cellCoordinate: DataTableCellIndexCoordinate, options?: DataTableCellSelectionOptions): void;

  moveSelection(direction: DataTableDirection, options?: DataTableMoveSelectionOptions): void;

  selectRow(rowIndex: number): void;

  selectColumn(columnIndex: number): void;

  clearSelection(): Promise<void>;

  hasSelectedCells(): boolean;

  subscribe<Event extends DataTableEvent>(event: Event, listener: DataTableListener[Event], signal?: AbortSignal): void;

  unsubscribe<Event extends DataTableEvent>(event: Event, listener: DataTableListener[Event]): void;

  destroy(): void;

  undo(): Promise<DataTableCellUpdateEventDetail>;

  redo(): Promise<DataTableCellUpdateEventDetail>;

  tableValid(): boolean;

  cellStatusOverview(): Record<DataTableCellStatusType, number>;

  undoEnabled(): boolean;

  redoEnabled(): boolean;

  rowHeaders(): Array<Omit<DataTableRowHeader, 'displayed'>>;

  displayedRowHeaderTitles(): Array<string>;

  rowHeaderWidth(): number;

  displayedRowHeaderIds(): Array<DataTableRowHeaderId>;

  updateDisplayedRowHeaders(
    displayedHeaderIds: Set<DataTableRowHeaderId>,
    options?: DataTableUpdateDisplayedRowHeadersOptions
  ): Promise<void>;

  moveColumn(columnAddRequest: DataTableColumnMoveRequest): Promise<DataTableMoveColumnResponse>;

  addColumn(columnAddRequest: DataTableColumnAddRequest): Promise<Array<DataTableColumn>>;

  removeColumn(columnId: DataTableColumnApiId): Promise<void>;

  addRow(rowAddRequest: DataTableRowAddRequest): Promise<Array<DataTableRow>>;

  removeRow(rowId: DataTableRowApiId): Promise<void>;

  updateColumn(columnUpdate: DataTableColumnUpdateRequest): Promise<DataTableColumn>;

  fillColumn(columnId: DataTableColumnApiId, fillData: DataTableColumnFillRequestV1): Promise<void>;

  windowScrollChange(rect: DataTableRect): void;

  featureFlagEnabled(featureFlag: keyof DataTableFeatureFlags): boolean;
}

export interface DataTableRange {
  startIndex: number;
  endIndex: number;
}

export interface DataTableRect {
  get top(): number;

  get bottom(): number;

  get left(): number;

  get right(): number;

  get width(): number;

  get height(): number;

  get area(): number;

  contains(value: DataTableRect): boolean;
}

export interface DataTableRectForceLoad extends DataTableRect {
  get force(): boolean;
}

export interface DataTableScrollService {
  get cellWidth(): Readonly<number>;

  get cellHeight(): Readonly<number>;

  get columnHeight(): Readonly<number>;

  get windowWidth(): Readonly<number>;

  get windowHeight(): Readonly<number>;

  get windowScrollLeft(): Readonly<number>;

  get windowScrollTop(): Readonly<number>;

  get viewportBlocks(): Readonly<{ x: number; y: number }>;

  get scrollBlocks(): Readonly<{ x: DataTableRange; y: DataTableRange }>;

  get scrollPercent(): Readonly<{ x: number; y: number }>;

  get totalBlocks(): Readonly<{ x: number; y: number }>;

  get dataTableTotalWidth(): Readonly<number>;

  get dataTableTotalHeight(): Readonly<number>;

  get xAxisScrollingEnabled(): Readonly<boolean>;

  get yAxisScrollingEnabled(): Readonly<boolean>;

  calculateDataTableMove(block: number, direction: DataTableDirection): { x: number; y: number };

  moveDataTableWindowToIndexXY(cellIndexCoordinate: DataTableCellIndexCoordinate): { x: number; y: number };

  moveDataTableWindow(direction: DataTableDirection, blocks: number, options?: Partial<DataTableScrollOptions>): void;

  moveDataTableToBlock(axis: Axis, blocks: number): void;

  moveDataTableWindowBySelection(
    dataTableSelection: Readonly<DataTableSelection>,
    options?: Partial<DataTableScrollOptions>
  ): void;

  moveDataTableWindowToIndex(
    cellIndexCoordinate: DataTableCellIndexCoordinate,
    options?: Partial<DataTableScrollOptions>
  ): void;

  scrollDataTableWindowX(deltaX: number): void;

  scrollDataTableWindowY(deltaY: number): void;

  isScrollingLeft(deltaX: number): boolean;

  isScrollingRight(deltaX: number): boolean;

  isScrollingUp(deltaY: number): boolean;

  isScrollingDown(deltaY: number): boolean;

  subscribe<Event extends DataTableScrollEvent>(
    event: Event,
    listener: DataTableScrollListener[Event],
    signal?: AbortSignal
  ): void;

  unsubscribe<Event extends DataTableScrollEvent>(event: Event, listener: DataTableScrollListener[Event]): void;

  onDragScrollBar(isDragged: boolean, axis: Axis): void;

  destroy(): void;
}

export interface DataTableSelection {
  get from(): Readonly<DataTableCellIndexCoordinate>;

  get to(): Readonly<DataTableCellIndexCoordinate>;

  get topLeft(): Readonly<DataTableCellIndexCoordinate>;

  get bottomRight(): Readonly<DataTableCellIndexCoordinate>;

  get rect(): Readonly<DataTableRect>;

  contains(coordinate: DataTableCellIndexCoordinate): boolean;

  containsColumn(column: number): boolean;

  containsRow(row: number): boolean;
}

export interface DataTableCellSelection<Column = unknown, Row = unknown> {
  from: DataTableCellCoordinate<Column, Row>;
  to: DataTableCellCoordinate<Column, Row>;
}

export interface DataTableRowHeader {
  id: DataTableRowHeaderId;
  title: string;
  displayed: boolean;
}

export interface DataTableFeatureFlags {
  observation?: boolean;
  templates?: boolean;
}

export interface DataTableServiceProps {
  apiService: DataTableApiService;
  readonly: boolean;
  rowHeaders: Array<DataTableRowHeader>;
  dimensions: DataTableDimensions;
  studyApiId: StudyApiId;
  featureFlags?: DataTableFeatureFlags;
}

export type DataTableColumnType =
  | 'number'
  | 'text'
  | 'timestamp'
  | 'timestampBaseline'
  | 'timestampBaselineRelative'
  | 'formula'
  | 'measurement'
  | 'observation';

export interface DataTableColumnAddRequest {
  type: DataTableColumnType | null;
  name?: string | null;
  unit?: string | null;
  measurement?: Partial<DataTableMeasurement> & Pick<DataTableMeasurement, 'measured_at'>;
  observation?: Partial<DataTableObservation> & Pick<DataTableObservation, 'observed_at'>;
  formula?: DataTableFormula;
  pk?: {
    interval?: number | null;
    interval_time_unit?: string | null;
    window?: number;
    from?: string | null;
    timestamp_baseline_id?: DataTableColumnApiId | null;
  };
}

export interface DataTableColumnUpdateForm extends DataTableColumnAddRequest {
  id: DataTableColumnApiId;
  locked?: boolean;
}

export type DataTableColumnUpdateRequest = Omit<DataTableColumnUpdateForm, 'type'>;

export interface DataTableColumnMoveRequest {
  column_id: DataTableColumnApiId;
  target_column_id: number;
  position?: string;
}

export interface DataTableRowAddRequest {
  animals: Array<AnimalApiId>;
}

export interface DataTableFormulaPlaceholder {
  type: 'column';
  value: DataTableColumnApiId;
}

export interface DataTableFormula {
  value: string;
  placeholders: Record<DataTableColumnApiId, DataTableFormulaPlaceholder>;
}

export interface DataTableMeasurement {
  metric: string;
  measured_at: string;
  is_calculated: boolean;
}

export interface DataTableObservation {
  glossary_id: string;
  display: 'comments' | 'score';
  observed_at: string;
}

export interface DataTableWindowClearRequest {
  startColumnId: string;
  startRowId: string;
  endColumnId: string;
  endRowId: string;
}

export interface DataTableValidateRequest {
  startRowId: string;
  endColumnId: string;
  endRowId: string;
}

interface DataTableBaseColumn<Type extends DataTableColumnType> {
  type: Type;
  id: DataTableColumnApiId;
  name: string;
  locked?: boolean;
  read_only?: boolean;
  reference_date?: string;
  formula?: DataTableFormula;
  measurement?: DataTableMeasurement;
  observation?: DataTableObservation;
  pk?: DataTableColumnPk;
}

export type DataTableTextColumn = DataTableBaseColumn<'text'>;

export type DataTableTimestampColumn = DataTableBaseColumn<'timestamp'>;

export interface DataTableNumberColumn extends DataTableBaseColumn<'number'> {
  unit?: string;
}

export interface DataTableMeasurementColumn extends DataTableBaseColumn<'measurement'> {
  measurement: DataTableMeasurement;
  unit?: string;
  relations: Array<DataTableColumnApiId>;
}

export interface DataTableFormulaColumn extends DataTableBaseColumn<'formula'> {
  formula: DataTableFormula;
  unit?: string;
}

export interface DataTableTimestampBaselineColumn extends DataTableBaseColumn<'timestampBaseline'> {
  from?: string;
  interval?: number;
  interval_time_unit: string;
  window?: number;
  timestamp_baseline_id?: string;
}

export interface DataTableTimestampBaselineRelativeColumn extends DataTableBaseColumn<'timestampBaselineRelative'> {
  from?: string;
  interval?: number;
  intervalTimeUnit: string;
  window?: number;
  timestampBaselineApiId?: string;
}

export interface DataTableObservationColumn extends DataTableBaseColumn<'observation'> {
  observation: DataTableObservation;
}

export interface DataTableColumnPk {
  from?: string;
  interval?: number;
  interval_time_unit: string;
  window?: number;
  timestamp_baseline_id?: string;
}

export type DataTableColumn =
  | DataTableTextColumn
  | DataTableTimestampColumn
  | DataTableNumberColumn
  | DataTableFormulaColumn
  | DataTableMeasurementColumn
  | DataTableObservationColumn
  | DataTableTimestampBaselineColumn
  | DataTableTimestampBaselineRelativeColumn;

export type DataTableRow = components['schemas']['DataTableRowV1.schema'];

export interface DataTableCellData {
  value: string | null;
}

export interface DataTableCellDataCache extends DataTableCellData {
  updated_at: ISODateTime | null;
}

export interface DataTableCellAlertCache extends DataTableCellIdCoordinate {
  alerts: Map<ID, AnimalAlertV1>;
}

export interface DataTableCellCoordinate<Column = unknown, Row = unknown> {
  column: Column;
  row: Row;
}

export const enum DataTableCellSelectionState {
  highlighted = 'highlighted',
  originSelected = 'originSelected',
  notSelected = 'notSelected',
}

export type DataTableCellIdCoordinate = DataTableCellCoordinate<DataTableColumnApiId, DataTableRowApiId>;
export type DataTableCellIndexCoordinate = DataTableCellCoordinate<number, number>;
export type DataTableCellState = 'loading' | 'saving' | 'valid' | 'invalid' | 'notSaved' | 'unknown' | 'warning';

export interface DataTableScrollOptions {
  behaviour: ScrollBehavior;
}

export type DataTableCellUpsert = { column_id: DataTableColumnApiId; row_id: DataTableRowApiId } & DataTableCellData;
export type DataTableCellUpsertError = DataTableCellUpsert & { error: string };

export interface DataTableAlert {
  column_id: DataTableColumnApiId;
  row_id: DataTableRowApiId;
  alerts: Array<AnimalAlertV1>;
}

export interface DataTableApiLoadCellResponse extends DataTableAlert {
  cell: DataTableCellData;
  created_at: ISODateTime;
  updated_at: ISODateTime;
}

export type DataTableCellUpsertResponse =
  | { success: true; effects?: Array<DataTableColumnApiId>; data_table_alerts?: Array<DataTableAlert> }
  | {
      success: false;
      errors: Array<DataTableCellUpsertError>;
      effects?: Array<DataTableColumnApiId>;
      data_table_alerts?: Array<DataTableAlert>;
    };

export type DataTableValidateResponse =
  | { success: true; errors: Array<DataTableCellUpsertError> }
  | { success: false; errors: Array<DataTableCellUpsertError> };

export type DataTableMoveColumnResponse = {
  id: DataTableColumnApiId;
  columns: DataTableColumn[];
  rows: DataTableRow[];
  name: string;
  type: string;
};

export type DataTableWindowClearResponse = { success: true; effects: Array<DataTableColumnApiId> };

export interface DataTableColumnRemoveResponse {
  deleted: boolean;
  effects: Array<DataTableColumnApiId>;
  dependencies: Array<DataTableColumnApiId>;
}

export type DataTableColumnUpdateEventDetail = DataTableColumn;
export type DataTableCellUpdateEventDetail = Record<DataTableCellReference, DataTableCellDataCache>;

export interface DataTableCellAlertUpdateEventDetail {
  cellAlerts: Record<DataTableCellReference, DataTableCellAlertCache>;
  trigger: 'cell-update' | 'cell-load';
}

export interface DataTableCellSelectionChangeEventDetail {
  source: 'api' | 'user';
  selection: Readonly<DataTableSelection>;
  scrollTo: boolean;
  activate: boolean;
  content?: string;
  options?: { direction?: DataTableDirection };
}

interface DataTableUndoRedoStateChangeEventDetail {
  redoEnabled: boolean;
  undoEnabled: boolean;
}

export const enum DataTableCellStatusType {
  OK,
  ValidationError,
  NetworkError,
  ValidationWarning,
  AsyncValidationWarning,
}

export interface DataTableCellOkStatus {
  type: DataTableCellStatusType.OK;
}

export interface DataTableCellValidationErrorStatus {
  type: DataTableCellStatusType.ValidationError;
  error: string;
}

export interface DataTableCellValidationWarningStatus {
  type: DataTableCellStatusType.ValidationWarning;
  error: string;
}

export interface DataTableCellNetworkErrorStatus {
  type: DataTableCellStatusType.NetworkError;
}

export interface DataTableCellAsyncValidationWarningStatus {
  type: DataTableCellStatusType.AsyncValidationWarning;
  error: string;
}

export type DataTableCellStatus =
  | DataTableCellOkStatus
  | DataTableCellValidationErrorStatus
  | DataTableCellValidationWarningStatus
  | DataTableCellAsyncValidationWarningStatus
  | DataTableCellNetworkErrorStatus;

export interface DataTableCellStateChangeEventDetail {
  invalidColumns: Set<DataTableColumnApiId>;
  invalidRows: Set<DataTableRowApiId>;
  invalidCells: Set<DataTableCellReference>;
  overview: Record<DataTableCellStatusType, number>;
}

export interface DataTableRowHeaderStateChangeEventDetail {
  displayedRowHeaderIds: Set<DataTableRowHeaderId>;
  rowHeaderWidth: number;
}

export type DataTableStateChangeType = keyof DataTable | '*';

export interface DataTableStateChangeEventDetail {
  type: Readonly<Set<DataTableStateChangeType>>;
  ids: Readonly<Set<DataTableColumnApiId | DataTableRowApiId>>;
}

export interface DataTableSavingEventDetail {
  saving: boolean;
}

export interface DataTableHighVolumeUpsertDetail {
  totalChunkedRequests: number;
  pending: DataTableCellUpsert[][];
  resolved: DataTableCellUpsert[][];
  rejected: DataTableCellUpsert[][];
}

export interface DataTableScrollDragScrollBarDetail {
  isDragged: boolean;
  axis: Axis;
}

export interface DataTableWorkflowActivatedDetail {
  active: boolean;
}

export type DataTableColumnUpdateEvent = CustomEvent<DataTableColumnUpdateEventDetail>;
export type DataTableCellUpdateEvent = CustomEvent<DataTableCellUpdateEventDetail>;
export type DataTableCellAlertUpdateEvent = CustomEvent<DataTableCellAlertUpdateEventDetail>;
export type DataTableCellSelectionChangeEvent = CustomEvent<DataTableCellSelectionChangeEventDetail>;
export type DataTableUndoRedoStateChangeEvent = CustomEvent<DataTableUndoRedoStateChangeEventDetail>;
export type DataTableCellStateChangeEvent = CustomEvent<DataTableCellStateChangeEventDetail>;
export type DataTableRowHeaderStateChangeEvent = CustomEvent<DataTableRowHeaderStateChangeEventDetail>;
export type DataTableStateChangeEvent = CustomEvent<DataTableStateChangeEventDetail>;
export type DataTableSavingEvent = CustomEvent<DataTableSavingEventDetail>;
export type DataTablePasteLimitExceededEvent = CustomEvent<void>;
export type DataTableHighVolumeUpsertEvent = CustomEvent<DataTableHighVolumeUpsertDetail>;
export type DataTableWorkflowEvent = CustomEvent<DataTableWorkflowAction>;
export type DataTableWorkflowActivatedEvent = CustomEvent<DataTableWorkflowActivatedDetail>;

export type DataTableScrollDragScrollBarEvent = CustomEvent<DataTableScrollDragScrollBarDetail>;

export const enum DataTableEvent {
  ColumnUpdate = 'ColumnUpdate',
  CellUpdate = 'CellUpdate',
  CellAlertUpdate = 'CellAlertUpdate',
  CellSelectionChange = 'CellSelectionChange',
  UndoRedoStateChange = 'UndoRedoStateChange',
  CellStateChange = 'CellStateChange',
  TableStateChange = 'TableStateChange',
  RowHeaderStateChange = 'RowHeaderStateChange',
  TableSaving = 'TableSaving',
  PasteLimitExceeded = 'PasteLimitExceeded',
  HighVolumeUpsert = 'HighVolumeUpsert',
  MoveColumn = 'MoveColumn',
  WorkflowActionEnter = 'WorkflowActionEnter',
  WorkflowActionExit = 'WorkflowActionExit',
  WorkflowActivated = 'WorkflowActivated',
  ClearValidation = 'ClearValidation',
}

export const enum DataTableScrollEvent {
  DragScrollBar = 'DragScrollBar',
}

export type DataTableListener = {
  [DataTableEvent.ColumnUpdate]: (event: DataTableColumnUpdateEvent) => void;
  [DataTableEvent.CellUpdate]: (event: DataTableCellUpdateEvent) => void;
  [DataTableEvent.CellAlertUpdate]: (event: DataTableCellAlertUpdateEvent) => void;
  [DataTableEvent.CellSelectionChange]: (event: DataTableCellSelectionChangeEvent) => void;
  [DataTableEvent.UndoRedoStateChange]: (event: DataTableUndoRedoStateChangeEvent) => void;
  [DataTableEvent.CellStateChange]: (event: DataTableCellStateChangeEvent) => void;
  [DataTableEvent.RowHeaderStateChange]: (event: DataTableRowHeaderStateChangeEvent) => void;
  [DataTableEvent.TableStateChange]: (event: DataTableStateChangeEvent) => void;
  [DataTableEvent.TableSaving]: (event: DataTableSavingEvent) => void;
  [DataTableEvent.PasteLimitExceeded]: (event: DataTablePasteLimitExceededEvent) => void;
  [DataTableEvent.HighVolumeUpsert]: (event: DataTableHighVolumeUpsertEvent) => void;
  [DataTableEvent.MoveColumn]: () => void;
  [DataTableEvent.WorkflowActionEnter]: (event: DataTableWorkflowEvent) => void;
  [DataTableEvent.WorkflowActionExit]: (event: DataTableWorkflowEvent) => void;
  [DataTableEvent.WorkflowActivated]: (event: DataTableWorkflowActivatedEvent) => void;
  [DataTableEvent.ClearValidation]: () => void;
};

export type DataTableScrollListener = {
  [DataTableScrollEvent.DragScrollBar]: (event: any) => void;
};

export type DataTableValidatorResponse = { valid: true } | { valid: false; error: string };

export interface DataTableValidatorFunction {
  (value: string | null): DataTableValidatorResponse;
}

export interface DataTableCellComponentProps {
  coordinate: DataTableCellIndexCoordinate;
  dataTableService: DataTableService;
  positionOffset: { x: number; y: number };
}

export const enum DataTableRowHeaderId {
  animalCage = 'animalCage',
  animalDonor = 'animalDonor',
  animalEar = 'animalEar',
  animalName = 'animalName',
  animalStudyGroup = 'animalStudyGroup',
  animalTag = 'animalTag',
  animalTail = 'animalTail',
}

export enum TimeUnit {
  hours = 'H',
  minutes = 'M',
  seconds = 'S',
}

interface DataTableWorkflowActionBase<Type extends string, Source extends string, Destination extends string> {
  type: Type;
  source: Source;
  destination: Destination;
}

export type DataTableWorkflowAction =
  | DataTableWorkflowActionBase<'OpenSearch', 'search', DataTableColumnApiId>
  | DataTableWorkflowActionBase<'NextAnimal', 'next-animal', DataTableColumnApiId>
  | DataTableWorkflowActionBase<'End', DataTableColumnApiId, 'end'>
  | DataTableWorkflowActionBase<'SelectColumn', DataTableColumnApiId, DataTableColumnApiId>;

export type DataTableWorkflowActionSource = DataTableWorkflowAction['source'];

export interface LoadWorkflowOptions {
  persist?: boolean;
}

export type DataTableWorkflow = Array<DataTableWorkflowAction>;

export interface WorkflowActionsExitOptions {
  targetRowIndex?: number;
}

export interface DataTableWorkflowService {
  get workflowActive(): Readonly<boolean>;

  get workflow(): Readonly<DataTableWorkflow>;

  loadWorkflow(workflow: DataTableWorkflow, options?: LoadWorkflowOptions): void;

  activateWorkflow(active: boolean): void;

  clearWorkflow(): void;

  workflowValid(workflow: DataTableWorkflow): boolean;

  workflowActionEnter(source: DataTableWorkflowActionSource | undefined): boolean;

  workflowActionExit(source: DataTableWorkflowActionSource | undefined, options?: WorkflowActionsExitOptions): boolean;

  workflowLoaded(): boolean;

  sourceInWorkflow(source: DataTableWorkflowActionSource): boolean;
}
