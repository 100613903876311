import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import type { DraftFormData } from '@/model/Draft.model';
import type React from 'react';
import { useEffect } from 'react';
import { GroupAndTreatmentConfiguration } from './GroupAndTreatmentConfiguration';
import { GroupCreation } from './GroupCreation';
import { TreatmentCreation } from './TreatmentCreation';
import { createSelector } from '@reduxjs/toolkit';
import { State } from '@/model/State.model';
import { useSelector } from 'react-redux';

interface GroupsAndTreatmentsCreationProps {
  state: { study: DraftFormData };
  dispatch: React.Dispatch<unknown>;
}

const selectDiseaseInduction = createSelector(
  (state: State) => state.team?.features?.disease_induction ?? false,
  (disease_induction) => ({ disease_induction })
);

export const GroupsAndTreatmentsCreation: React.FC<GroupsAndTreatmentsCreationProps> = ({
  state: { study },
  dispatch,
}) => {
  const { disease_induction } = useSelector(selectDiseaseInduction);

  useEffect(() => {
    dispatch({ type: 'stepReady' });
  }, []);

  return (
    <div data-testid="groups-and-treatments__container">
      <Tabs>
        <div className="bb b--moon-gray">
          <Tab className="f5 f4-l">Groups</Tab>
          {disease_induction && <Tab className="f5 f4-l">Diseases</Tab>}
          <Tab className="f5 f4-l">Treatments</Tab>
          <Tab className="f5 f4-l">Configuration</Tab>
        </div>
        <Panel>
          <GroupCreation study={study} dispatch={dispatch} />
        </Panel>
        {disease_induction && (
          <Panel>
            <TreatmentCreation study={study} classificationType="disease_induction" dispatch={dispatch} />
          </Panel>
        )}
        <Panel>
          <TreatmentCreation study={study} classificationType="treatment" dispatch={dispatch} />
        </Panel>
        <Panel>
          <GroupAndTreatmentConfiguration study={study} dispatch={dispatch} />
        </Panel>
      </Tabs>
    </div>
  );
};
