// @ts-nocheck: converted from JS

import { _isEmpty, _isNotEmpty } from '@/littledash';
import { getAfterSaveAction, getWeightPercentageChangeValue } from '@/components/Workflow/Show/WorkflowTemplate.utils';

export type MeasurementChild = {
  id: string;
  name: string;
  displayName: string;
  value: boolean;
};

export const assembleInitialWorkflow = (calculations = [], workflow, templateDefaults) => {
  const templateMeasurements = templateDefaults?.measurements?.measurements ?? [];
  const measurementChildren = calculations.reduce<MeasurementChild[]>((acc, { id, name, measurements }) => {
    if (_isNotEmpty(measurements)) {
      acc.push({
        id,
        name: id,
        displayName: name,
        value: _isNotEmpty(templateMeasurements) ? templateMeasurements.includes(id) : true,
      });
    }
    return acc;
  }, []);

  if (_isNotEmpty(workflow)) {
    return workflow;
  }

  return [
    {
      name: 'measurements',
      displayName: 'Measurements',
      value: _isNotEmpty(calculations),
      canReorder: true,
      children: orderCalculations(measurementChildren, templateMeasurements),
      disabled: _isEmpty(calculations),
      percentChange: {
        weight: getWeightPercentageChangeValue(templateDefaults?.measurements?.weight_percentage_change),
      },
      altIdToAssign: templateDefaults?.measurements?.assign_identifier ?? null,
      onSave: getAfterSaveAction(templateDefaults?.measurements?.after_save_action),
      cursorPosition: templateDefaults?.measurements?.cursor_position ?? null,
    },
    {
      name: 'samples',
      displayName: 'Samples',
      value: true,
      autoGenerateSampleIds: templateDefaults?.samples?.auto_generate_sample_id ?? true,
      preSelectedSampleTypeIds: templateDefaults?.samples?.samples ?? [],
      children: [],
      onSave: getAfterSaveAction(templateDefaults?.samples?.after_save_action),
      disabled: false,
    },
    {
      name: 'observations',
      displayName: 'Observations',
      preSelectedObservationTypeIds: templateDefaults?.observations?.observations ?? [],
      value: true,
      onSave: getAfterSaveAction(templateDefaults?.observations?.after_save_action),
      disabled: false,
    },
    {
      name: 'dosing',
      displayName: 'Dosing',
      value: true,
      children: templateDefaults?.dosing?.treatments ?? [],
      onSave: getAfterSaveAction(templateDefaults?.dosing?.after_save_action),
      disabled: false,
      takeWeight: templateDefaults?.dosing?.use_new_body_weight ?? false,
      skipDosing: templateDefaults?.dosing?.skip_dosing_for_unrequired_animals ?? false,
    },
  ];
};

export const assembleToggleOptions = (checks, activeCheck) =>
  checks?.reduce((acc, { value, name }) => {
    if (value && activeCheck !== name) {
      acc[name] = {
        value: `toggle-${name}`,
        name: `Toggle ${name}`,
      };
    }

    return acc;
  }, {});

export const orderCalculations = (calculations: MeasurementChild[], order: string[]) => {
  if (_isEmpty(order) || order.length < 2) {
    return calculations;
  }

  const orderMap = new Map(order.map((id, index) => [id, index]));
  const ordered: MeasurementChild[] = [];
  const unordered: MeasurementChild[] = [];

  calculations.forEach((calc) => {
    if (orderMap.has(calc.id)) {
      ordered.push(calc);
    } else {
      unordered.push(calc);
    }
  });

  // Sort based on the index of order;
  ordered.sort((a, b) => orderMap.get(a.id) - orderMap.get(b.id));

  // Return ordered first then whatever the other measurements are on the preset.
  return [...ordered, ...unordered];
};
