// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import Button from '@/components/UI/Button';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import ObservationScore from '@/components/UI/ObservationScore';
import SelectDropDown from '@/components/UI/SelectDropDown';
import ActionList from '@/components/UI/SelectDropDown/Menus/ActionList';
import Table from '@/components/UI/Table';
import UserAvatar from '@/components/UI/UserAvatar';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { useRequest } from '@/support/Hooks/request';
import { modalAction } from '@/utils/modal';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { _notNil } from '@/littledash';

const Observations = ({ hasWriteAccess = false }) => {
  const { subjectId } = useParams();
  const {
    entity: observations,
    entityLoading: observationsLoading,
    entityError: observationsError,
    fetchEntity: fetchObservations,
  } = useFetchEntity({
    entityType: 'animalObservations',
    params: { id: subjectId },
  });
  const dispatch = useDispatch();
  const { openModal } = modalAction(dispatch);
  const columns = [
    {
      id: 'observation',
      Header: 'Name',
      isVisible: true,
      sortBy: 'observation',
      accessor: 'title',
      Cell: ({ row: { original: observation } }) => (
        <a
          className="link blue lh-title db"
          onClick={() =>
            openModal('VIEW_OBSERVATION', {
              observation,
            })
          }
        >
          {observation.title}
        </a>
      ),
    },
    {
      id: 'score',
      Header: 'Score',
      accessor: 'value',
      isVisible: true,
      sortBy: 'score',
      width: 100,
      Cell: ({ row: { original: observation } }) =>
        observation.value && (
          <ObservationScore type={observation.type} score={observation.value} options={observation.options} />
        ),
    },
    {
      id: 'recorded',
      Header: 'Recorded',
      accessor: 'observed_at',
      sortBy: 'observed_at',
      isVisible: true,
      width: 100,
      Cell: ({
        row: {
          original: { observed_at: observedAt },
        },
      }) => <DateTimeRenderer value={observedAt} />,
    },
    {
      id: 'recorded_by',
      Header: 'User',
      isVisible: true,
      sortBy: 'created_at.email',
      accessor: 'created_at.email',
      width: 100,
      Cell: ({
        row: {
          original: { created_at: createdBy },
        },
      }) => <UserAvatar user={createdBy} tooltip={createdBy.name ?? createdBy.email} />,
    },
    {
      id: 'actions',
      lockVisibility: true,
      isVisible: hasWriteAccess,
      sortDisabled: true,
      accessor: 'actions',
      width: 75,
      className: 'ui__table--overflow-cell',
      Cell: ({ row: { original: observation } }) => {
        const { sendRequest: deleteObservations } = useRequest({
          route: 'observations.destroy',
          method: 'delete',
          params: { id: observation.id },
        });
        return (
          <div className="hover-near-black">
            <SelectDropDown
              title="More options"
              iconOnly
              className="hover-near-black"
              alignMenu="right"
              portalProps={{
                width: 120,
              }}
            >
              <ActionList
                actions={[
                  {
                    action: () =>
                      openModal('EDIT_OBSERVATION', {
                        observation,
                        handleCallback: fetchObservations,
                      }),
                    name: 'Edit',
                  },
                  {
                    action: () =>
                      openModal('CONFIRM_DELETE_OBSERVATION', {
                        onClick: async (reason_for_change?: string) => {
                          await deleteObservations(_notNil(reason_for_change) ? { reason_for_change } : {});
                          await fetchObservations();
                          toast.success('Deleted.');
                        },
                      }),
                    className: 'red',
                    name: 'Delete',
                  },
                ]}
              />
            </SelectDropDown>
          </div>
        );
      },
    },
  ];

  if (observationsLoading) {
    return <Loading />;
  }

  return (
    <div className="ui-card">
      <div className="pa3 bb b--moon-gray flex justify-between items-center">
        <h3 className="f5 lh-title">Observations</h3>
        <Button
          disabled={!hasWriteAccess}
          onClick={() =>
            openModal('SELECT_OBSERVATIONS', {
              animalId: subjectId,
              handleCallback: fetchObservations,
            })
          }
        >
          Add new
        </Button>
      </div>
      {observationsError ? (
        <ApiErrorBanner
          title="There was an error fetching observation data"
          text="An error has occurred when fetching observation data, please try again. If this keeps occurring please contact support."
          error={observationsError}
          className="ma4"
        />
      ) : (
        <Table
          columns={columns}
          data={observations}
          sortBy={[{ columnId: 'recorded', desc: false }]}
          className="tableOverflowVisible"
        />
      )}
    </div>
  );
};

export default Observations;
