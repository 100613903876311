import Loading from '@/components/Loading';
import {
  classificationTypes,
  TreatmentFromForm,
  treatmentMetadataFields,
  updateNumberToString,
} from '@/components/Studies/Treatments/Treatment.utils';
import Button from '@/components/UI/Button';
import ControlledInputWithUnits from '@/components/UI/FormElements/ControlledInputWithUnits';
import { MetadataFieldBuilderReturn } from '@/components/UI/FormFields/FormField.utils';
import FormRender from '@/components/UI/FormRender';
import Select from '@/components/UI/Select';
import type { SelectOption } from '@/components/UI/Select/Select';
import { formatNumber } from '@/helpers';
import { _isEmptyString, _isNotEmpty, _notNil, uuid } from '@/littledash';
import type { ID } from '@/model/Common.model';
import type { MetadataField } from '@/model/Metadata.model';
import type { ClassificationType, Treatment, TreatmentField, TreatmentMethod } from '@/model/Treatment.model';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { ErrorMessage } from '@hookform/error-message';
import { FC, useEffect, useState } from 'react'; //@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { Control, FormProvider, useController, useForm } from 'react-hook-form';
import { dosingMethodOptions, dosingMethods, getDefaults } from './AddTreatmentForm.utils';
import type { TreatmentForm, TreatmentFormField, TreatmentFormUnit } from './TreatmentForm.model';
import { TreatmentLookup } from './TreatmentLookup';
import { validMultiSelectValue } from '@/components/Studies/Create/Show.utils';
import { useSelector } from 'react-redux';
import { State } from '@/model/State.model';
import Radio from '@/components/UI/FormElements/Radio';

export interface AddTreatmentFormProps {
  treatment: Treatment;
  metadata: Array<MetadataField>;
  onSubmit: (treatment: Treatment, edit: boolean, treatmentId?: ID) => void;
  onCancel: () => void;
  classificationType: ClassificationType;
}

interface TreatmentExtended extends Treatment {
  meta: Record<string, string>;
}

export const handleTreatmentSubmit = async (
  treatmentData: Treatment & Record<string, TreatmentField>,
  metaFieldValues: MetadataFieldBuilderReturn[],
  treatment: Treatment,
  edit: boolean,
  onSubmit: (updatedTreatment: Treatment, edit: boolean, treatmentId?: ID) => void
) => {
  const dosingMethodValues = treatmentData[treatmentData.type];
  delete treatmentData[treatmentData.type];

  const multiSelectFields = metaFieldValues.reduce((acc, metaField) => {
    if (metaField.type === 'multi_select' || metaField.type === 'lookup_multi_select') {
      acc.add(metaField.slug);
    }
    return acc;
  }, new Set<string>());

  // If multi & lookup multi select fields have no values, remove them before creating treatment meta
  const meta = Object.entries(treatmentData?.meta ?? {}).reduce<Record<string, string>>((acc, [key, value]) => {
    if (multiSelectFields.has(key) && !validMultiSelectValue(value)) {
      return acc;
    }
    return { ...acc, [key]: value };
  }, {});

  const updatedTreatment: TreatmentExtended = { temp_id: treatment?.temp_id ?? uuid(), ...treatmentData, meta };

  if (_isNotEmpty(dosingMethodValues)) {
    updatedTreatment.fields = updateNumberToString([...Object.values(dosingMethodValues)]);
  }

  onSubmit(updatedTreatment, edit, treatment?.api_id);

  return updatedTreatment;
};

const AddTreatmentForm: FC<AddTreatmentFormProps> = ({
  treatment,
  metadata,
  onSubmit,
  onCancel,
  classificationType,
}) => {
  const diseaseInductionEnabled = useSelector((state: State) => state?.team?.features?.disease_induction ?? false);

  const defaultTreatmentMethod: TreatmentMethod = classificationType === 'disease_induction' ? 'custom' : 'volume';

  const [selectedTreatmentMethod, setSelectedTreatmentMethod] = useState<TreatmentMethod>(
    treatment?.type ?? defaultTreatmentMethod
  );

  const [selectedTreatmentClassification, setSelectedTreatmentClassification] = useState<ClassificationType>(
    treatment?.treatment_classification ?? classificationType
  );

  const metaFieldValues = treatmentMetadataFields(metadata, treatment);
  const treatmentClassification = classificationTypes[classificationType];

  const {
    entity: treatmentForm,
    entityLoading: treatmentFormLoading,
    entityUpdating: treatmentFormUpdating,
    fetchEntity: fetchTreatmentForm,
  } = useFetchEntity<TreatmentForm>({
    entityType: 'treatmentForm',
    params: { treatmentMethod: selectedTreatmentMethod },
  });

  const edit = _isNotEmpty(treatment);

  const formMethods = useForm<TreatmentFromForm>({
    mode: 'onChange',
  });

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { isSubmitting, errors },
  } = formMethods;
  const {
    field: { onBlur: treatmentOnBlur, onChange: treatmentOnChange, value: treatmentValue },
  } = useController({
    control,
    defaultValue: treatment?.treatment_type ?? null,
    name: 'treatment_type',
    rules: { required: `${treatmentClassification.key} is required` },
  });

  const {
    field: { onBlur: dosingMethodOnBlur, onChange: dosingMethodOnChange, value: dosingMethodValue },
  } = useController({
    control,
    name: 'type',
    defaultValue: treatment?.type ?? dosingMethodOptions[0].value,
    rules: { required: true },
  });

  const {
    field: { onChange: treatmentClassificationOnChange },
  } = useController({
    control,
    name: 'treatment_classification',
    defaultValue: treatment?.treatment_classification ?? 'treatment',
    rules: { required: 'Treatment Classification is required' },
  });

  const classificationTypeOptions = Object.entries(classificationTypes)
    .filter(([key]) => key !== 'disease_induction')
    .map(([key, value]) => ({
      value: key as ClassificationType,
      label: value.title,
      sublabel: value.sublabel,
    }));

  useEffect(() => {
    if (_isNotEmpty(selectedTreatmentMethod)) {
      dosingMethodOnChange(selectedTreatmentMethod);
      fetchTreatmentForm();
    }
  }, [selectedTreatmentMethod]);

  useEffect(() => {
    if (_isNotEmpty(selectedTreatmentClassification)) {
      treatmentClassificationOnChange(selectedTreatmentClassification);
    }
  }, [selectedTreatmentClassification]);

  useEffect(() => {
    if (!treatmentFormLoading && _notNil(treatmentForm)) {
      const formattedFields =
        treatment?.fields?.map((field) => {
          if (_notNil(field?.default_display_value)) {
            field.default_display_value = formatNumber(field.default_display_value);
          } else if (_notNil(field?.default_value)) {
            field.default_value = _isEmptyString(field.default_value ?? '') ? '' : formatNumber(field.default_value);
          }
          return field;
        }) ?? [];

      const formattedTreatments = {
        ...treatment,
        fields: formattedFields,
      };
      reset({
        ...getValues(['treatment_type', 'type', 'meta']),
        ...getDefaults(treatmentForm.name, treatmentForm.fields, selectedTreatmentMethod, formattedTreatments),
      });
    }
  }, [treatmentForm]);

  return !treatmentFormLoading && _notNil(treatmentForm) ? (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit((formData: Treatment & Record<string, TreatmentField>) =>
          handleTreatmentSubmit(formData, metaFieldValues, treatment, edit, onSubmit)
        )}
        className="center pb0 ui-card"
        style={{ maxWidth: '500px' }}
        noValidate
        data-test-component="AddTreatmentForm"
        data-test-element="form"
      >
        <div className="ph3 pt3">
          {diseaseInductionEnabled && classificationType !== 'disease_induction' && (
            <div className="mb3" data-test-element="treatment-classification-container">
              {classificationTypeOptions.map((o) => (
                <Radio
                  value={o.value}
                  id={o.value}
                  label={o.label}
                  key={o.label}
                  sublabel={o.sublabel}
                  disabled={treatment?.in_use}
                  checked={o.value === selectedTreatmentClassification}
                  onChange={(e) => setSelectedTreatmentClassification(e.target.value as ClassificationType)}
                  dataTestId={`${o.label}_radio_test`}
                />
              ))}
            </div>
          )}
          <div className="mb3" data-test-element="treatment-lookup-container">
            <label>Name (required)</label>
            <TreatmentLookup
              value={treatmentValue}
              onChange={treatmentOnChange}
              onBlur={treatmentOnBlur}
              classificationType={classificationType}
            />
            <ErrorMessage
              name="treatment_type"
              errors={errors}
              render={({ message }) => <small className="red mb3">{message}</small>}
            />
          </div>
          <div className="mb3" data-test-element="dosing-method-container">
            <label>Dosing Method</label>
            <Select
              defaultValue={dosingMethods?.[selectedTreatmentMethod]}
              disabled={treatment?.in_use}
              options={dosingMethodOptions}
              onChange={(newValue) => setSelectedTreatmentMethod((newValue as SelectOption<TreatmentMethod>).value)}
              onBlur={dosingMethodOnBlur}
              className="w-100"
              isMulti={false}
            />
          </div>
          <div className="justify-between flex" style={{ gap: 10 }} data-test-element="dosing-method-fields-container">
            {!treatmentFormUpdating && _notNil(treatmentForm.fields) ? (
              (treatmentForm?.fields ?? []).map((field) => (
                <DosingMethodField
                  key={`${treatmentForm?.name}.${field.name}`}
                  id={`${treatmentForm?.name}.${field.name}`}
                  dosingType={dosingMethodValue}
                  control={control}
                  field={field}
                  isSubmitting={isSubmitting}
                  unitsDisabled={treatment?.in_use}
                  errors={errors}
                  units={field.units.filter((unit) => unit.unit_type === field.name)}
                />
              ))
            ) : (
              <div className="flex align-center w-100 pt5-ns">
                <Loading txt="" loader />
              </div>
            )}
          </div>
          <div className="pb3" data-test-element="metadata-fields-container">
            <FormRender fields={metaFieldValues} />
          </div>
        </div>
        <div className="pa3 flex self-end bt b--moon-gray w-100">
          <Button
            submit
            loading={isSubmitting}
            loadingText={`${edit ? 'Editing' : 'Adding'} ${treatmentClassification.key}`}
            testId="add-or-update-treatment"
          >
            {edit ? 'Update' : 'Add'} {treatmentClassification.key}
          </Button>
          <Button plain className="ml3" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </form>
    </FormProvider>
  ) : (
    <></>
  );
};

interface DosingMethodFieldProps {
  id: string;
  control: Control<Treatment>;
  dosingType: TreatmentMethod;
  field: TreatmentFormField;
  errors: Record<string, unknown>;
  isSubmitting: boolean;
  units: Array<TreatmentFormUnit>;
  unitsDisabled?: boolean;
}

const DosingMethodField: FC<DosingMethodFieldProps> = ({
  id,
  control,
  dosingType,
  field,
  errors,
  isSubmitting,
  units,
  unitsDisabled,
}) => (
  <div
    className="w-50"
    data-test-component="DosingMethodField"
    data-test-element="container"
    data-test-key={field.name}
  >
    <label>
      {field.label} {field.required && '(required)'}
    </label>
    <fieldset className="w-100 relative flex justify-between">
      <ControlledInputWithUnits
        id={id}
        classes="w-100"
        field={field}
        selectRequired={dosingType === 'custom'}
        disabled={isSubmitting}
        hasError={!!errors?.[id]}
        units={units}
        control={control}
        errors={errors}
        unitsDisabled={unitsDisabled}
        tooltip={id === 'custom.volume' ? 'When marked as required a value must be entered at dosing' : undefined}
      />
    </fieldset>
  </div>
);

export default AddTreatmentForm;
